import 'isomorphic-fetch'

import Vue from 'vue'

import { VBTogglePlugin, ModalPlugin } from 'bootstrap-vue'

import { BButton } from 'bootstrap-vue/esm/components/button'
import { BLink } from 'bootstrap-vue/esm/components/link'
import { BFormInput } from 'bootstrap-vue/esm/components/form-input'
import { BCollapse } from 'bootstrap-vue/esm/components/collapse'
import { BPagination } from 'bootstrap-vue/esm/components/pagination'

import VueApollo from 'vue-apollo'
import VueMeta from 'vue-meta'
import Vuelidate from 'vuelidate'
import VueWindowSize from 'vue-window-size'
import { ApolloClient } from 'apollo-boost'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { InMemoryCache } from 'apollo-cache-inmemory'

import VueGtm from 'vue-gtm'

import App from './App.vue'
import Main from './layouts/Main.vue'

import router from './router'
import store from './store/index'
import i18n from './i18n'
import EventBus from './utils/events'
// import DisableLog from './utils/disableLog'
import DotEnv from './env'

const JSON5 = require('json5')

require('default-passive-events')

// disable console on production mode
// DisableLog(['production'])

// If we are in development mode
if (process.env.NODE_ENV === 'development') {
  // encrypt all file
  if (window.location.search === '?encrypt=development') {
    DotEnv.encrypt('development')
  } else if (window.location.search === '?encrypt=staging') {
    DotEnv.encrypt('staging')
  } else if (window.location.search === '?encrypt=production') {
    DotEnv.encrypt('production')
  }
  // decrypt all file
  if (window.location.search === '?uncrypt=development') {
    DotEnv.uncrypt('development')
  } else if (window.location.search === '?uncrypt=staging') {
    DotEnv.uncrypt('staging')
  } else if (window.location.search === '?uncrypt=production') {
    DotEnv.uncrypt('production')
  }
}

// Set the global DotEnv
global.env = DotEnv

/**
 * GTM management
 */
Vue.use(VueGtm, {
// eslint-disable-next-line max-len
  id: 'GTM-M66TSGS' // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
})

/**
 * Social shared
 */
const SocialSharing = require('vue-social-sharing')

Vue.use(SocialSharing)

/**
 * Vue Global
 */
Vue.prototype.$JSON5 = JSON5
Vue.config.productionTip = false

Vue.component('b-button', BButton)
Vue.component('b-link', BLink)
Vue.component('b-form-input', BFormInput)
Vue.component('b-collapse', BCollapse)
Vue.component('b-pagination', BPagination)

Vue.use(ModalPlugin)
Vue.use(VBTogglePlugin)

Vue.use(VueMeta)
Vue.use(VueWindowSize)
Vue.use(Vuelidate)

/**
 * Global Layouts
 */

store.SITE_ID = 9
Vue.component('MainLayout', Main)

/**
 * Apollo Config
 */
Vue.use(VueApollo)

/**
 * Request Interceptor
 */
const reqInterceptor = (uri, options) => {
  if (document.location.pathname.startsWith('/fr/preview') || document.location.pathname.startsWith('/en/preview')) {
    options.headers.Authorization = global.env.decrypt('VUE_APP_GRAPHQL_AUTH')
    return fetch(uri, options)
  }

  return fetch(uri, options)
}

/**
 * Response Interceptor
 */
const resInterceptor = new ApolloLink((operation, forward) => forward(operation).map((response) => {
  /**
   * Catch 404
   */
  if (response.data &&
    ((response.data.pageFr && response.data.pageFr.length === 0) ||
      (response.data.pageEn && response.data.pageEn.length === 0))) {
    // @TODO: V2 dynamique redirect to 404 (Don't remove todo)
    const page404 = (i18n.vm.locale === 'fr-CA') ? '/fr/404/' : '/en/404/'
    if (page404 !== window.location.pathname) {
      window.location.href = page404
      // console.log('redirect')
    }
  }

  return response
}))

/**
 * Error interceptor
 */
const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors) {
    // eslint-disable-next-line array-callback-return
    graphQLErrors.map(({ message, locations, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`) // eslint-disable-line
    })

    const data = (response.data) ? response.data : {}

    EventBus.$emit('graphql-error-data', { data })
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`) // eslint-disable-line
    const errorPage = (store.getters.getLang === 'fr-CA') ? '/fr/500/' : '/en/500/'

    router.replace({
      path: errorPage,
      query: {}
    })
  }
})

/**
 * config Http
 */
const httpLink = new HttpLink({
  uri: global.env.decrypt('VUE_APP_API_HOST'),
  headers: {
    'content-type': 'application/json'
  },
  fetch: reqInterceptor
})

/**
 * Create apollo Client
 */
const apolloClient = new ApolloClient({
  uri: global.env.decrypt('VUE_APP_API_HOST'),
  link: resInterceptor.concat(errorLink.concat(httpLink)),
  cache: new InMemoryCache(),
  connectToDevTools: true,
  fetchOptions: {
    mode: 'no-cors'
  }
})

/**
 * Create apollo provider for vue
 */
const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})

new Vue({
  router,
  store,
  apolloProvider,
  i18n,
  render: h => h(App)
}).$mount('#app')
