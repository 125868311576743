export default {
  methods: {
    /**
     * Check if is an external link
     * @param {String} link
     * @returns {Boolean}
     */
    isExternal (link) {
      return (link && (link.startsWith('http') || link.startsWith('https')))
    }
  }
}
