import uuidv4 from '../../utils/uuidv4'

export default {
  /**
   * Builder for contact form
   * @param {Object} data
   * @returns {Object}
   */
  build (data) {
    return {
      id: `${data.entity_id}_${uuidv4()}`,
      component: 'SectionContactForm',
      props: {
        customMail: data.recipient_email.trim(),
        contactTitle: (data.title) ? data.title : null
      }
    }
  }
}
