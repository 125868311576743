import uuidv4 from '../../utils/uuidv4'

export default {
  /**
   * Builder for flip book (PDF)
   * @param {Object} data
   * @returns {Object}
   */
  build (data) {
    return {
      id: `${data.entity_id}_${uuidv4()}`,
      component: 'FlipBook',
      props: {
        url: (data.flipbook_url) ? data.flipbook_url : ''
      }
    }
  }
}
