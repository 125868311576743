import Builder from '../../builder'
import hackQL from '../../utils/hackQL'

export default {
  methods: {
    /**
     * Redirect to 500 page
     * @returns {Void}
     */
    internal () {
      this.$router.replace({
        path: (this.$i18n.locale === 'fr-CA') ? '/fr/500/' : '/en/500/',
        query: {}
      })
    },
    /**
     * Redirect to 404 page
     * @returns {Void}
     */
    notFound () {
      // @TODO: V2 dynamique redirect to 404 (Don't remove todo)
      this.$router.replace({
        path: (this.$i18n.locale === 'fr-CA') ? '/fr/404/' : '/en/404/',
        query: {}
      })
    },
    /**
     * Set the products in the products page
     * @param {Array} categoriesProducts
     * @returns {Void}
     */
    setPageProductsInProducts (categoriesProducts) {
      const multiCols = []
      let cols = []
      let maxBlock = 0
      const pathInLang = (this.$i18n.locale === 'fr-CA') ? 1 : 0
      const baseUrl = `/${(this.$i18n.locale === 'fr-CA') ? 'fr' : 'en'}/${hackQL.getPath('product')[pathInLang]}`

      // loop on all elements
      categoriesProducts.forEach((component) => {
        // Add new column with the element
        cols.push({
          title: (component.name) ? component.name : '',
          description: (component.short_description) ? component.short_description : '',
          image: {
            file: {
              url: (component.image && component.image.file && component.image.file.url)
                ? component.image.file.url : null
            }
          },
          block_button: [{
            button_link: `${baseUrl}/${component.slug}?p=${this.$route.query.p}&h=products`,
            button_text: '',
            button_target: '_self'
          }]
        })

        maxBlock += 1

        // Max element per line
        if (maxBlock > 4) {
          multiCols.push(cols)
          maxBlock = 0
          cols = []
        }
      })

      // Add last line of elements
      multiCols.push(cols)

      // Fake a multicolumn (5 column max)
      const tabs = []
      multiCols.forEach((line, key) => {
        const data = {
          entity_id: `${key}_${Math.floor(Math.random() * 9999999999)}`,
          columns: line
        }
        tabs.push(data)
      })

      // Build block table product for products page
      this.structurePage.push({
        component: 'SectionTabs',
        props: {
          elements: Builder.BlockTable.build(tabs)
        }
      })
    },
    /**
     * Add brands in products or products categories page
     * @param {Array} brands
     * @returns {Void}
     */
    setPageProductsBrand (brands) {
      const blockTxtImgExclusive = []
      const blockTxtImgNotExclusive = []

      // Loop on all brands
      brands.forEach((brand) => {
        const pathInLang = (this.$i18n.locale === 'fr-CA') ? 1 : 0
        const baseUrl = `/${(this.$i18n.locale === 'fr-CA') ? 'fr' : 'en'}/${hackQL.getPath('brands')[pathInLang]}`

        // Format the brands data
        const data = {
          entity_id: brand.entity_id,
          title: brand.name,
          border_display: 'true',
          content: brand.short_description,
          template: '1:2',
          image: {
            file: {
              url: (brand.image && brand.image.file && brand.image.file.url) ? brand.image.file.url : ''
            }
          },
          block_button: [{
            button_link: `${baseUrl}/${brand.slug}`,
            button_text: this.$t('generalUi.learnMore'),
            button_target: '_self'
          }]
        }

        // if is feature brand
        if (brand.featured === 'true') {
          blockTxtImgExclusive.push(data)
        } else {
          blockTxtImgNotExclusive.push(data)
        }
      })

      // Build the brands on the page
      this.structurePage.push(Builder.FullBlock.build({
        entity_id: `custom_${Math.floor(Math.random() * 9999999999)}`,
        textOnly: this.$t('generalUi.exclusiveBrands'),
        isProductPage: true,
        border_display: 'true'

      }, this.$store.getters.getLang))

      blockTxtImgExclusive.forEach((block) => {
        this.structurePage.push(Builder.BlockTextAndImage.build(block, this.$store.getters.getLang))
      })

      // if we have not exclusive brand
      if (blockTxtImgNotExclusive.length > 0) {
        this.structurePage.push(Builder.FullBlock.build({
          entity_id: `custom_${Math.floor(Math.random() * 9999999999)}`,
          textOnly: this.$t('generalUi.notExclusiveBrands'),
          isProductPage: true
        }, this.$store.getters.getLang))

        blockTxtImgNotExclusive.forEach((block) => {
          // eslint-disable-next-line max-len
          this.structurePage.push(Builder.BlockTextAndImage.build(block, this.$store.getters.getLang))
        })
      }
    },
    /**
     * Add the childs categories for the ressources
     * @param {Array} childCategories
     * @returns {Void}
     */
    setPageChildCategoriesRessources (childCategories) {
      const elements = []

      // Build
      childCategories.forEach((component) => {
        elements.push(Builder.ArticleCategory.build(component, this))
      })

      this.structurePage.push({
        component: 'BlogNavCategories',
        props: {
          elements
        }
      })
    },
    /**
     * Add articles on the page
     * @param {Object} articles
     * @returns {Void}
     */
    setPageArticles (articles) {
      const servicesCards = []

      // loop on all articles
      articles.forEach((component) => {
        let supTitle = null
        // add category on sup title
        if (component.category && component.category.length > 0) {
          supTitle = `${component.category[0].name} | `
        }

        // add date on sup title
        if (component.display_date) {
          supTitle = (supTitle) || ''
          supTitle += component.display_date
        }

        // Set the data
        const data = {
          title: component.title,
          supTitle,
          date: component.display_date,
          description: component.summary,
          image: component.image,
          hasFullBorder: true,
          block_button: [{
            button_link: `${(this.$i18n.locale === 'fr-CA') ? '/fr' : '/en'}/articles/${component.slug}`,
            button_text: this.$t('generalUi.learnMore'),
            button_target: '_self'
          }]
        }

        servicesCards.push(data)
      })

      servicesCards.sort((a, b) => new Date(b.date) - new Date(a.date))

      this.structurePage.push(Builder.CustomNavigation.build({ navigation: [{ navigation_id: 'filter_ressources' }] }, this))

      this.structurePage.push(Builder.BlockMultiColumns.build({
        entity_id: `serv_${Math.floor(Math.random() * 9999999999)}`,
        columns: servicesCards
      }, 'SectionCategoryService', this.$store.getters.getLang))
    },
    /**
     * Add child categories in page
     * @param {Object} childCategories
     * @param {Object} item
     * @param {Boolean} addFilter
     * @returns {Void}
     */
    setPageChildCategories (childCategories, item, addFilter) {
      const multiCols = []
      let cols = []
      const servicesCards = []
      const tabs = []
      let maxBlock = 0

      let baseUrl = this.$route.path

      // if is the root path
      if (!addFilter) {
        baseUrl = `${(this.$i18n.locale === 'fr-CA') ? '/fr' : '/en'}/${hackQL.getPath('brands')[(this.$i18n.locale === 'fr-CA') ? 1 : 0]}`
      }

      // loop on all categories
      childCategories.forEach((component) => {
        // eslint-disable-next-line prefer-destructuring
        let slug = component.slug

        // if we add params on the url
        if (addFilter) {
          slug += `${(this.$route.query.p) ? `?p=${this.$route.query.p}.${component.entity_id}` : `?p=${component.entity_id}`}`
        }

        // prepare data
        const data = {
          title: (component.content_type === 'brand') ? '' : component.name,
          description: component.summary,
          image: component.image,
          hasFullBorder: true,
          block_button: [{
            button_link: `${baseUrl}/${slug}`,
            button_text: this.$t('generalUi.learnMore'),
            button_target: '_self'
          }]
        }

        cols.push(data)
        servicesCards.push(data)

        maxBlock += 1

        if (maxBlock > 4) {
          multiCols.push(cols)
          maxBlock = 0
          cols = []
        }
      })

      multiCols.push(cols)

      // if page products and have promotion card
      if (hackQL.is(this.$route.path, 'products') || (this.$route.query.content_type && this.$route.query.content_type === 'product_category')) {
        if (item.promotions) {
          const promotion = this.$JSON5.parse(item.promotions)[0]
          if (promotion) {
            cols.push({
              title: (promotion.title) ? promotion.title : '',
              colour_theme: 'black',
              block_button: (promotion.block_button) ? promotion.block_button : null,
              isPromotion: true
            })
          }
        }

        // Create data table
        multiCols.forEach((line, key) => {
          const data = {
            entity_id: `${key}_${Math.floor(Math.random() * 9999999999)}`,
            columns: line
          }

          tabs.push(data)
        })

        if (addFilter) {
          // Build brand filter
          this.structurePage.push({
            component: 'BrandFilter',
            props: {}
          })
        }

        // Build serction tabs
        this.structurePage.push({
          component: 'SectionTabs',
          props: {
            elements: Builder.BlockTable.build(tabs),
            customId: (addFilter) ? 'tab_product' : 'tab_brand'
          }
        })
      }

      // if page is services build multi columns
      // eslint-disable-next-line no-irregular-whitespace
      if (hackQL.is(this.$route.path, 'services') || (this.$route.query.content_type && this.$route.query.content_type === 'services_category')) {
        this.structurePage.push(Builder.BlockMultiColumns.build({
          entity_id: `serv_${Math.floor(Math.random() * 9999999999)}`,
          columns: servicesCards
        }, 'SectionCategoryService', this.$store.getters.getLang))
      }
    },
    /**
     * Set the components in the page
     * @param {Object} components
     * @param {Object} item
     * @returns {Void}
     */
    setPageComponents (components, item) {
      let fullChildPath = []

      components.forEach((component) => {
        switch (component.content_type) {
          case 'block_download':
            // eslint-disable-next-line max-len
            this.structurePage.push(Builder.BlockDownload.build(component, this, this.$store.getters.getLang))
            break
          case 'block_hero':
            // eslint-disable-next-line max-len
            this.structurePage.push(Builder.Hero.build(component, this, this.$store.getters.getLang))
            break
          case 'block_promotions':
            // eslint-disable-next-line max-len
            this.structurePage.push(Builder.Promotions.build(component, this.$store.getters.getLang))
            break
          case 'block_text_1_column':
            this.structurePage.push(Builder.FullBlock.build(component, this.$store.getters.getLang))
            break
          case 'block_multi_columns':
            // eslint-disable-next-line max-len
            this.structurePage.push(Builder.BlockMultiColumns.build(component, null, this.$store.getters.getLang))
            break
          case 'block_4_cards':
            // eslint-disable-next-line max-len
            this.structurePage.push(Builder.Block4Cards.build(component, null, this.$store.getters.getLang))
            break
          case 'block_accordion':
            // eslint-disable-next-line max-len
            this.structurePage.push(Builder.BlockAccordion.build(component, null, this.$store.getters.getLang))
            break
          case 'block_map':
            if (item.full_url && component.target_page && component.target_page.slug) {
              fullChildPath = []
              fullChildPath = this.$JSON5.parse(item.full_url)

              const childSlug = component.target_page.slug

              if (fullChildPath[0]) {
                fullChildPath[0][Object.keys(fullChildPath[0])[0]] = `${fullChildPath[0][Object.keys(fullChildPath[0])[0]]}/${childSlug}`
              }

              if (fullChildPath[1]) {
                fullChildPath[1][Object.keys(fullChildPath[1])[0]] = `${fullChildPath[1][Object.keys(fullChildPath[1])[0]]}/${childSlug}`
              }
            }

            if (component.template && component.template === 'services') {
              this.structurePage.push(Builder.Map.buildAdvancedSearch(component, this))
              this.structurePage.push(Builder.Map.buildMap(component, this, fullChildPath))
            }

            if (component.template && component.template === 'hours') {
              this.structurePage.push(Builder.Map.buildMapSingle(component))
            }
            break
          case 'box_cta':
            this.structurePage.push(Builder.BlockCta.build(component, this.$store.getters.getLang))
            break
          case 'block_text_and_image_two_columns':
            // eslint-disable-next-line max-len
            this.structurePage.push(Builder.BlockTextAndImage.build(component, this.$store.getters.getLang))
            break
          case 'box_custom_navigation':
            this.structurePage.push(Builder.CustomNavigation.build(component, this))
            break
          case 'block_contact_form':
            this.structurePage.push(Builder.BlockSectionForm.build(component))
            break
          case 'site_map':
            this.structurePage.push(Builder.SiteMap.build(component))
            break
          case 'block_flipbook':
            this.structurePage.push(Builder.FlipBook.build(component))
            break
          case 'box_timeline':
            // eslint-disable-next-line max-len
            this.structurePage.push(Builder.Timeline.build(component, null, this.$store.getters.getLang))
            break
          default:
            break
        }
      })
    },
    /**
     * Set all component in page
     * @returns {Void}
     */
    setPage () {
      // set component in the lang we want
      switch (this.$i18n.locale) {
        case 'fr-CA':
          this.page = this.pageFr
          break
        default:
          this.page = this.pageEn
      }

      // Set the metadata
      if (this.page[0] && this.page[0].meta_data) {
        this.setMeta(this.$JSON5.parse(this.page[0].meta_data))
      } else {
        this.setBasicMeta(this.page[0])
      }
    }
  }
}
