import uuidv4 from '../../utils/uuidv4'
import Common from './common'

export default {
  /**
   * Builder for the timeline
   * @param {Object} data
   * @param {String} lang
   * @returns {Object}
   */
  build (data, lang) {
    const props = []
    if (data.assets) {
      data.assets.forEach((assets) => {
        props.push({
          file: Common.Title.buildTitle(assets.file)
        })
      })
    }

    return {
      id: `${data.entity_id}_${uuidv4()}`,
      component: 'BlockDownload',
      props: {
        title: (data.title) ? Common.Title.buildTitle(data.title) : false,
        assets: props
      }
    }
  }
}
