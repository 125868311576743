<template>
  <section class="hero" :class="'hero__' + heroType + ' __' + heroTheme">
    <BackgroundVideo
      v-if="heroBkgVideo"
      :videoId="heroVideoId"
    />

    <figure
      v-if="heroBkg"
      class="hero__master-bkg __black-overlay"
      :style="`background-image:url('${ heroBkg }')`"
    ></figure>

    <em
      v-if="heroRotatedText"
      class="o-rotated-text o-rotated-text__anticlockwise">
      {{ heroRotatedText }}
    </em>

    <div>
      <div class="hero__wrapper row no-gutters align-items-stretch"
      :class="[
        vCentered ? 'align-center' : 'align-start',
        'justify-content-' + hAlignment,
        hAlignment === 'center' ? 'text-center' : '',
        hAlignment === 'end' ? 'text-right' : ''
      ]">
        <div class="panel col-12"
            :class="[
              'justify-content-' + hAlignment,
              hAlignment === 'center'|| hAlignment === 'end' ? 'col-lg-8 col-xl-5' : 'offset-lg-1',
              hAlignment === 'end' ? 'pull-lg-1' : '',
              heroImg ? 'col-lg-6' : 'col-lg-5'
            ]"
        >
          <div class="wrapper" :class="heroImg ? '__more-pr' : '' ">

            <h1 v-if="heroTitle" v-html="heroTitle" ></h1>
            <h2 v-if="heroSubtitle">{{ heroSubtitle }}</h2>
            <p v-if="heroContent" v-html="heroContent" ></p>

            <div>
              <slot />
            </div>

            <b-link
              v-if="heroCta && isExternal(heroCta.link)"
              :href="heroCta.link"
              :class="`btn ${(heroTheme === 'black') ? 'btn__inverted' : 'btn__white'}`"
              :target="heroCta.target ? heroCta.target : '_self'"
            >{{ heroCta.title }}</b-link>
            <b-link
              v-if="heroCta && !isExternal(heroCta.link)"
              :to="heroCta.link"
              :class="`btn ${(heroTheme === 'black') ? 'btn__inverted' : 'btn__white'}`"
              :target="heroCta.target ? heroCta.target : '_self'"
            >{{ heroCta.title }}</b-link>

            <algolia-search-hero v-if="globalSearch" :siteID='siteID' />

          </div>
        </div>

        <div v-if="heroImg || heroBannerType" class="hero__img panel col-12 col-lg">
          <figure class="">
            <img v-if="heroBannerType === 'uni_pro' " class="unipro o-object-fit" loading="lazy"  :src="uni_pro_bg" >
            <img v-else-if="heroBannerType === 'auto_select' " class="autoselect o-object-fit" loading="lazy"  :src="autoselect_bg" >
            <img v-else loading="lazy" :src="heroImg" class="o-object-fit" :alt="heroTitle" />
          </figure>
        </div>
      </div>
    </div>
    <a href="#" class="o-scroll-to o-scroll-to__hero"></a>
  </section>
</template>

<script>
import BackgroundVideo from '../common/BackgroundVideo.vue'
import AlgoliaSearchHero from '../common/AlgoliaSearchHero.vue'
import EventBus from '../../utils/events'
import linkMixing from '../../mixins/link'

export default {
  name: 'Hero',
  mixins: [linkMixing],
  components: {
    BackgroundVideo,
    AlgoliaSearchHero
  },
  props: {
    heroBkg: {
      type: String,
      default: null
    },
    hasGridBackground: {
      default: false
    },
    heroBadge: {
      type: String,
      default: null
    },
    heroRotatedText: {
      type: String,
      default: null
    },
    heroTitle: {
      type: String,
      default: null
    },
    heroSubtitle: {
      type: String,
      default: null
    },
    heroContent: {
      type: String,
      default: null
    },
    heroImg: {
      type: String,
      default: null
    },
    heroType: {
      type: String,
      default: 'large'
    },
    heroCta: {
      type: Object,
      default: null
    },
    vCentered: {
      type: Boolean,
      default: false
    },
    hAlignment: {
      type: String,
      default: 'start'
    },
    heroBkgVideo: {
      type: Boolean,
      default: false
    },
    heroTheme: {
      type: String,
      default: 'white'
    },
    heroVideoId: {
      type: String,
      default: ''
    },
    heroBannerType: {
      default: false
    },
    globalSearch: {
      default: false
    },
    siteID: {
      type: String,
      default: '1'
    }
  },
  data () {
    return {
      uni_pro_bg: require('../../assets/images/Uni-pro-shop-bumpertobumper.jpg'),
      autoselect_bg: require('../../assets/images/Auto-select-shop-bumpertobumper.jpg')
    }
  },
  methods: {
    /**
     * Display data on the hero (page shop/store detail)
     * @param {String} className
     * @returns {Void}
     */
    displayHeroData (className) {
      // get all elements by the classname
      const elements = document.querySelectorAll(`.${className}`)

      // Remove the current classname for display data
      elements.forEach((element) => {
        element.classList.remove(className)
      })
    }
  },
  mounted () {
    // Event when we want to display shop or store info in the hero
    EventBus.$on('display-in-hero', (params) => {
      if (params.value) {
        // display shop info
        this.displayHeroData('hero_shop')
      } else {
        // display store info
        this.displayHeroData('hero_store')
      }
    })
  }
}
</script>


<style lang="scss" scoped>
/* purgecss start ignore */
.hero {
  background-color: transparent;
  color: white;
  position: relative;
  border-bottom: 1px solid $grey-light;

  .grid-background {
    display: none;
    @include breakpoint('lg'){
      display: flex;
    }
  }

  &.__white {
    color: $white;
  }

  &.__black {
    color: $black;
  }

  &__master-bkg {
    @include o-bkg-cover;
    @include o-bkg-overlay(#ffffff, 0.5);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    &.__black-overlay{
      @include o-bkg-overlay(#000000, 0);
    }
  }

  &__img {
    figure{
      height: 100%;
      min-height: 22vh;
      width: 100%;

      @include breakpoint(sm) {
        min-height: 45vh
      }

      @include breakpoint(md) {
        min-height: 25vh
      }

      /* iPads (landscape) ----------- */
      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
        min-height: 45vh
      }

    }

    margin-top: 5rem;

    @include breakpoint(lg) {
      margin-top: 0;
    }
  }

  h1 {
    color: currentColor;
  }
  h2 {
    color: currentColor;
  }
  p{
    font-size: 2rem;
  }
  .btn{
    margin-top: 1.2rem;
  }

  &__large {

    & .hero__wrapper {
      padding:  6rem 3.5rem;
      @include breakpoint(lg) {
        min-height: 44rem;
        padding: inherit;
      }
      .panel{
        @include breakpoint(lg) {
          min-height: 44rem;
        }
      }
    }
  }

  &__smaller {
    @include breakpoint(xl) {
      padding-top: 5vh;
      padding-bottom: 5vh;
    }
  }
}

.panel {
  display: flex;
  align-items: center;
  position: relative;

  .wrapper{
    position: relative;
    z-index: 2;
    width: 100%;

    @include breakpoint(lg) {
      padding: 3.4rem;
    }

    &.__more-pr{
      @include breakpoint(lg) {
        padding-right: 8.33vw;
      }
    }
  }

  &.justify-content-end{
    .wrapper{
      @include breakpoint(lg) {
        padding-left: 8.3333333vw;
      }
    }
  }
}

.o-rotated-text{
  font-size: 1.1rem;
  color: $grey-light;
  position: absolute;
  left: 4.15vw;
  top: 50%;
  transform: translateX(-50%) rotate(-90deg);
  z-index: 2;
  font-style: normal;
  display: none;

  @include breakpoint('lg'){
    display: block;
  }
}

.align-right {
  margin-left: auto;
}
/* purgecss end ignore */
</style>
