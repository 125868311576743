<template>
  <section>
    <section class="custom-navigation">
      <nav class="custom-navigation__wrapper row no-gutters">
        <div class="spacer spacer__grey col-lg-1"></div>

        <div class="__nav-element col" v-if="!searchDisabled">
          <section class="search_input row no-gutters">
            <div class="col-md-6">
              <div class="input-group">
                <input
                    type="text"
                    v-model="q"
                    class="form-control"
                    :placeholder="$t('generalUi.searchArticle')"
                    @keyup.enter="doSearch()"
                />
                <div v-if="!display" class="input-group-append" @click="doSearch()">
                  <svg class="icon icon__md" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg"
                       viewBox="0 0 512 512">
                    <path fill="currentColor"
                          d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                          class=""></path>
                  </svg>
                </div>
                <div v-else class="input-group-append" @click="closeSearch()">
                  <svg key="1" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"
                       class="icon icon-cross">
                    <path fill="currentColor"
                          d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                          class=""></path>
                  </svg>
                </div>
              </div>
            </div>
          </section>
        </div>
      </nav>
    </section>

    <transition name="fade">
      <section v-if="q && display" class="search-results row no-gutters">
        <div class="col col-lg-7 offset-lg-2">


          <div v-if="searchResult.items">
            <h3>{{
                $t('generalUi.searchCount', {
                  count: (searchResult.queries) ? searchResult.queries.request[0].totalResults : 0,
                  query: q
                })
              }}</h3>
            <div class="search-results__item"
                 v-for="(post, i) in searchResult.items"
                 v-bind:post="post"
                 :key="i"
            >
              <a class="text-dark" :href="post.link">
                <em>{{ post.title }}</em>
              </a>
              <p>{{ post.snippet }}</p>

            </div>

            <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                per-page="10"
                aria-controls="my-table"
                @change="paginationChange"
            ></b-pagination>
          </div>
          <div v-else>
            <h3>{{ $t('generalUi.noResult') }}</h3>
          </div>

        </div>
      </section>
    </transition>
  </section>
</template>

<script>
import axios from 'axios'

export default {
  name: 'FilterRessources',
  data () {
    return {
      display: false,
      q: '',
      searchResult: {},
      currentPage: 1,
      searchDisabled: false,
      lang: this.$store.getters.getLang,

      searchEnKey: '0b0d46f884b99be93',
      searchFrKey: 'e6db0c7baa3888980'
    }
  },
  props: {
    elements: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    /**
     * Watcher when the query change
     * @returns {Void}
     */
    q () {
      this.debouncedDoSearch()
    },
    /**
     * Watcher when the search is open or not
     * @returns {Void}
     */
    display () {
      // if is close
      if (!this.display) {
        // reset the default page, query,..
        this.currentPage = 1
        this.q = ''
        this.searchResult = {}
      }
    }
  },
  computed: {
    /**
     * Get the page total for the pagination
     * @returns {Number}
     */
    rows () {
      return (this.searchResult.searchInformation)
        ? parseInt(this.searchResult.searchInformation.totalResults, 10) : 0
    }
  },
  methods: {
    /**
     * Add timer for start the search when the input search change
     * @param {Function} func
     * @param {Number} wait
     * @param {Boolean} immediate
     * @returns {Void}
     */
    debounce (func, wait, immediate) {
      let timeout
      // eslint-disable-next-line func-names
      return function () {
        // eslint-disable-next-line one-var
        const context = this,
          // eslint-disable-next-line prefer-rest-params
          args = arguments
        // eslint-disable-next-line func-names
        const later = function () {
          timeout = null
          if (!immediate) func.apply(context, args)
        }
        const callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
      }
    },
    /**
     * When the search is close
     * @returns {Void}
     */
    closeSearch () {
      // reset the display, query,...
      this.display = false
      this.q = ''
      this.searchResult = {}
      this.currentPage = 1
    },
    /**
     * Event when the pagination change
     * @param {Number} page
     * @returns {Void}
     */
    paginationChange (page) {
      this.currentPage = page
      this.doSearch()
    },
    /**
     * Start the request for search
     * @returns {Void}
     */
    doSearch () {
      // if the query is empty, stop here
      if (this.q.replace(/ /g, '') === '') return

      axios
        .get(`https://www.googleapis.com/customsearch/v1?key=${global.env.decrypt('VUE_APP_GOOGLE_MAP_API_KEY')}&cx=${this.lang === 'en-CA' ? this.searchEnKey : this.searchFrKey}&q=${this.q}&start=${(this.currentPage)}`)
        .then((response) => {
          this.searchResult = response.data
          this.display = true
        })
        .catch((error) => { // eslint-disable-next-line
            console.log(error)
        })
    }
  },
  created () {
    // Prepare the timer for search when user change the input
    this.debouncedDoSearch = this.debounce(this.doSearch, 200)

    // TODO : A supprimer pour le 20 janvier 2020
    // if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging') {
    //   this.searchDisabled = false
    // }
  }
}
</script>

<style lang="scss" scoped>
*::-ms-clear {
  display: none;
}

.custom-navigation {
  margin-top: -1px;
  border-top: 1px solid $grey-light;
  border-bottom: 1px solid $grey-light;

  .search_input {
    height: 100%;
  }
}

.spacer {
  &__grey {
    background-color: $menu-active;
  }

  display: none;

  @include breakpoint('lg') {
    display: block;
  }
}

.input-group {
  height: 100%;
  width: 100vw;

  @include breakpoint('lg') {
    max-width: 43rem;
  }

  .form-control {
    border-left: 1px solid $grey-light;
    @include breakpoint('lg') {
      border-left: 0;
    }
  }

  input {
    border: none;
    height: 100%;
  }

  input:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .input-group-append {
    width: 6rem;
    padding-left: 1rem;
    border-right: 1px solid $grey-light;

    .icon {
      height: 100%;
    }
  }
}

.custom-navigation__wrapper {
  height: 85px;
}
</style>
