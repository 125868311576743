export default {
  /**
   * Build the video background
   * @param {String|null} videoId
   * @returns {String|null}
   */
  buildVideo (videoId) {
    return (videoId) || null
  }
}
