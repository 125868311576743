export default {
  state: {
    fullUrl: []
  },

  mutations: {
    SET_FULL_URL: (state, { fullUrl }) => {
      state.fullUrl = fullUrl
    },
    SET_PAGE_TITLE: (state, { pageTitle }) => {
      state.pageTitle = pageTitle
    },
    SET_PAGE_LEADING_IMAGE: (state, { leadingImage }) => {
      if (leadingImage) {
        state.pageLeadingImage = leadingImage.file.url
      } else {
        state.pageLeadingImage = false
      }
    }
  },

  getters: {
    // eslint-disable-next-line
    getFullUrl: state => {
      return state.fullUrl
    },
    getPageTitle: state => state.pageTitle,
    getPageLeadingImage: state => state.pageLeadingImage
  },

  actions: {
    setFullUrl ({ commit }, { fullUrl }) {
      commit('SET_FULL_URL', { fullUrl })
    },
    setPageTitle ({ commit }, { pageTitle }) {
      commit('SET_PAGE_TITLE', { pageTitle })
    },
    setPageLeadingImage ({ commit }, { leadingImage }) {
      commit('SET_PAGE_LEADING_IMAGE', { leadingImage })
    }
  }
}

