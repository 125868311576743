module.exports = {
  VUE_APP_ENC: 'U2FsdGVkX1/CB1PmqNZY8IvZ9t0LZpfJMGHZIEDBWjY=',
  VUE_APP_API_HOST: 'U2FsdGVkX1+0kNjvPwg36O7ZbfUIX7R/jCmDFEJL49O3xFBq/RDD81kNYg93RAi0Jg15l5NgiaI0Tb0mRoX47w==',
  VUE_APP_GOOGLE_MAP_API_KEY: 'U2FsdGVkX19eEfuTqUagMnpGvxwc2DpKpJ+tc2Qwh9d3vR0vHHQ7neZtQS92b+3r3tqQifDh888wjFsk/r/UFg==',
  VUE_APP_GEOIP_API_KEY: 'U2FsdGVkX1+Qhy7TlMFSb3MGg5bY6Yw4vN/uP1O8gAI=',
  VUE_APP_S3D_CLOUDFRONT_ID: 'U2FsdGVkX1+u4NNhx06QtlEOiAk55g5xQLSkrurdCsA=',
  VUE_APP_S3D_BUCKET: 'U2FsdGVkX1+pVFCbVHPEJeH6ygsI+IUWbfKXm8SZRIF0cEnnpYDEg+thdrc3arFxdVBW4hyYCO/RZMt2LmMvRg==',
  VUE_APP_SENDGRID_API_KEY: 'U2FsdGVkX1/JDIeQp65M2oZ9846Dx+txlZmXeoegZFCXfuM30H0B4PJJSbSpPc37OxP/PQnR+uH05c6UDG9Q3aEaUTNHpfjpvbbrtxB1oQx4gxjvDF8J2sOy1ndu5y4r',
  VUE_APP_MAILCHIMP_API_KEY: 'U2FsdGVkX19HxNr0MQnd0G+LOBmkwvVZ9xNTX0jXYL569Ac/GXSiq/HHO4g4UcoSmOzydMBQkfV822YGKNtm9Q==',
  VUE_APP_GRAPHQL_AUTH: 'U2FsdGVkX1+3McOvWS7WDDeCwOV7oZ+fYlqLFqcw3evLrbGoU5qPSdfbVwValwKv'
}
