export default [
  {
    from: '/en/garage/calgary/fairmount-auto-service-1649',
    to: '/en/book-an-appointment/shop-details/fairmount-auto-service-7028-fairmount-drive-southeast-calgary'
  },
  {
    from: '/en/garage/calgary/fairmount-auto-service-1649',
    to: '/en/book-an-appointment/shop-details/fairmount-auto-service-7028-fairmount-drive-southeast-calgary'
  },
  {
    from: '/en/garage/calgary/oakridge-automotive-617',
    to: '/en/book-an-appointment/shop-details/oakridge-automotive-9405-24-street-southwest-calgary'
  },
  {
    from: '/en/garage/calgary/silver-springs-automotive-1181',
    to: '/en/book-an-appointment/shop-details/silver-springs-automotive-5858-silver-springs-boulevard-northwest-calgary'
  },
  {
    from: '/en/garage/calgary/montgomery-auto-service-1081',
    to: '/en/book-an-appointment/shop-details/montgomery-auto-service-4730-16-avenue-northwest-calgary'
  },
  {
    from: '/fr/garage/calgary/centre-street-auto-service-1066',
    to: '/en/book-an-appointment/shop-details/centre-street-auto-service-1005-centre-street-northwest-calgary'
  },
  {
    from: '/en/garage/calgary/willowridge-auto-service-1153',
    to: '/en/book-an-appointment/shop-details/willowridge-auto-service-9900-fairmount-drive-southeast-calgary'
  },
  {
    from: '/fr/switchLanguage/en/garage_city_fr/mount-pleasant-auto-service-5058',
    to: '/en/book-an-appointment/shop-details/mount-pleasant-auto-service-2615-4-street-northwest-calgary'
  }
]
