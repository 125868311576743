var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"simple-content container-fluid",class:[
    ("simple-content__" + _vm.type + " " + ((_vm.content === null) ? 'btn-only' : '')),
    _vm.isProductPage || _vm.cta.length > 1 ? 'title-only' : '',
    _vm.borderDisplay == 'none' ? 'no-border' : '',
    _vm.borderDisplay == 'full' ? 'borders' : '',
    _vm.borderDisplay == 'half' ? 'half-border' : '',
    _vm.bottomSpace ? 'bottom-space' : ''
  ]},[(_vm.anchor)?_c('a',{attrs:{"id":[("" + _vm.anchor)]}}):_vm._e(),_c('div',{staticClass:"row wrapper justify-content-center"},[_c('div',{staticClass:"col-lg-8",class:_vm.alignment === 'start' ? 'start':'text-center'},[(_vm.title)?_c('h2',{class:[_vm.alignment,("h2_" + _vm.font_size)],domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),(_vm.content)?_c('p',{class:_vm.font_size,domProps:{"innerHTML":_vm._s(_vm.content)}}):_vm._e(),(_vm.textOnly)?_c('p',{class:_vm.font_size,domProps:{"innerHTML":_vm._s(_vm.textOnly)}}):_vm._e(),(_vm.cta.length > 0)?_c('div',{staticClass:"cta_parent"},_vm._l((_vm.cta),function(btn,i){return _c('div',{key:i},[(btn && _vm.isExternal(btn.link))?_c('b-link',{staticClass:"btn",class:_vm.type === 'black' ? 'btn__inverted' : '',attrs:{"href":btn.link,"target":btn.target ? btn.target : '_self'}},[_vm._v(_vm._s(btn.title))]):_vm._e(),(btn && !_vm.isExternal(btn.link))?_c('b-link',{staticClass:"btn",class:_vm.type === 'black' ? 'btn__white' : '',attrs:{"to":btn.link,"target":btn.target ? btn.target : '_self'}},[_vm._v(_vm._s(btn.title))]):_vm._e()],1)}),0):_vm._e(),(_vm.icon)?_c('div',{staticClass:"icon_wrapper"},[_c('svg',{staticClass:"icon icon__sm",attrs:{"aria-hidden":"true","data-prefix":"fal","data-icon":"long-arrow-down","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 256 512"}},[_c('path',{attrs:{"fill":"currentColor","d":"M252.485 343.03l-7.07-7.071c-4.686-4.686-12.284-4.686-16.971 0L145 419.887V44c0-6.627-5.373-12-12-12h-10c-6.627 0-12 5.373-12 12v375.887l-83.444-83.928c-4.686-4.686-12.284-4.686-16.971 0l-7.07 7.071c-4.686 4.686-4.686 12.284 0 16.97l116 116.485c4.686 4.686 12.284 4.686 16.971 0l116-116.485c4.686-4.686 4.686-12.284-.001-16.97z"}})])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }