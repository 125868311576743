<template>
  <section class="section-cols-cta" :class="[
                                              type ? '' + type : '',
                                              prefooterType === '4cols' ? 'cols4' : ''
                                            ]">
    <div class="row no-gutters">
      <Cards
        v-for="(element, index) in elements"
        :key="index"
        :uKey="index"
        :iconCard="element.iconCard"
        :imageCard="element.image"
        :titleCard="element.title"
        :subTitleCard="element.subTitle"
        :contentCard="element.content"
        :ctaCard="element.cta"
        :type="element.type + ' __squarred'"
        :theme="element.theme"
        :colType="prefooterType"
        :biggerTitle="true"
        :hasBorder="false"
      />
    </div>
  </section>
</template>

<script>
import Cards from './Cards.vue'

export default {
  name: 'SectionColsCta',
  props: {
    // If layout with small image (1col) on the left
    // put "4cols" at the component instance
    prefooterType: {
      type: String,
      default: null
    },
    elements: {
      type: Array,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  components: {
    Cards
  }
}
</script>

<style scoped>
.section-cols-cta.cols4 >>> .single-card .wrapper{
  border: none;
}

</style>