import Builder from '../../../builder'
import store from '../../../store/index'
// import Tiles from '../../../components/common/Tiles.vue'

function getFileExtension (filename) {
  return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined
}

function isDocument (filename) {
  const fileExt = getFileExtension(filename)
  const docTypes = ['pdf', 'doc', 'xls', 'pptx', 'xlsx', 'zip']
  return (docTypes.includes(fileExt))
}

function isImage (filename) {
  const fileExt = getFileExtension(filename)
  const docTypes = ['jpg', 'png', 'jpeg', 'gif']
  return (docTypes.includes(fileExt))
}

function isExternalLink (filename) {
  // to test absolute path for blank / self link
  const urlRegEx = new RegExp('^(?:[a-z]+:)?//', 'i')
  return !!urlRegEx.test(filename)
}

export default {
  /**
     * Build content from string or contentful object
     * @param {Object|String|null} content
     * @returns {String|null}
     */
  renderBoxButton (node) {
    const cta = Builder.Common.cta.buildCta({ block_button: [node] }, store.getters.getLang)
    return `<a href="${cta.link}" class="btn btn__inverted" target="${cta.target}">${cta.title}</a>`
  },

  renderBlockCard (node) {
    const titleCard = (node.titleCard) ? node.titleCard : ''
    const subTitleCard = (node.subTitleCard) ? node.subTitleCard : ''
    const contentCard = (node.contentCard) ? node.contentCard : ''
    const imageCard = (node.imageCard) ? node.imageCard : ''
    const colTypeClass = (node.colType === '4cols' && node.type.includes('__image-only')) ? 'col-lg-2 no-borders' : ''
    let html = `<div class="col-lg single-card ${node.type} ${colTypeClass}">`
    html += ' <div class="wrapper" >'
    html += '   <div class="content">'
    const htmlImage = (node.imageCard) ? `   <figure><img class="" loading="lazy" src="${imageCard}" alt="" /></figure>` : ''
    html += htmlImage
    html += '     <div class="desc">'
    const htmlTitleCard = (node.titleCard) ? `       <h3>${titleCard}</h3>` : ''
    html += htmlTitleCard
    const htmlSubTitleCard = (node.subTitleCard) ? `       <h4>${subTitleCard}</h4>` : ''
    html += htmlSubTitleCard
    html += `       <p>${contentCard}</p>`
    html += '     </div>'
    if (node.ctaCard) {
      node.ctaCard.button_text = node.ctaCard.title
      node.ctaCard.button_link = node.ctaCard.link
      node.ctaCard.content_type = 'block_button'
      html += this.renderBoxButton(node.ctaCard)
    }
    html += '   </div>'
    html += ' </div>'
    html += '</div>'
    return html
  },

  renderBlockTextAndImage (node) {
    const box = Builder.BlockTextAndImage.build(node, store.getters.getLang)
    const borderDisplayClass = (box.props.borderDisplay) ? '__has-border' : 'no-border'
    const bottomSpaceClass = (box.props.bottomSpace) ? 'bottom-space' : ''
    const typeClass1 = (box.props.type === '1:1') ? 'col-lg-6' : ''
    const typeClass2 = (box.props.type === '2:1') ? 'col-lg-8' : ''
    const typeClass3 = (box.props.type === '1:2') ? 'col-lg-4' : ''
    let html = '<section class="tiles tiles__renderblock">'
    html += ` <div class="tiles__wrapper row no-gutters align-stretch ${borderDisplayClass} ${bottomSpaceClass}">`
    html += `     <div class="col-lg ${typeClass1} ${typeClass2} ${typeClass3}">`
    html += '       <div class="row no-gutters">'
    html += this.renderBlockCard(box.props.cardLeft)
    html += '       </div>'
    html += '   </div>'
    html += '     <div class="col-lg col-lg-6">'
    html += '       <div class="row">'
    html += this.renderBlockCard(box.props.cardRight)
    html += '       </div>'
    html += '   </div>'
    html += ' </div>'
    html += '</section>'
    return html
  },

  renderBox (node) {
    switch (node.content_type) {
      case 'block_button':
        return this.renderBoxButton(node)
      case 'block_text_and_image_two_columns':
        return this.renderBlockTextAndImage(node)
      case 'embed_code':
        return this.renderEmbedCode(node)
      default:
        return ''
    }
  },

  renderEmbedCode (node) {
    return `<div class="embed-responsive-item">${node.embed_code}</div>`
  },

  renderAsset (node) {
    const docType = getFileExtension(node.data.uri)
    const isDocumentType = isDocument(node.data.uri)
    const isImageType = isImage(node.data.uri)
    const isExtLink = isExternalLink(node.data.uri)
    let target = '_self'
    if (isImageType === true) {
      return `<img src="${node.data.uri}" class="embed-asset" />`
    }
    if (isDocumentType === true || isExtLink) {
      target = '_blank'
    }
    return `<a href="${node.data.uri}" target="${target}" class="embed-asset" >${docType}</a>`
  },

  renderHyperLink (node) {
    const text = node.content[0].value
    const isExtLink = isExternalLink(node.data.uri)
    let target = (node.target) ? node.target : '_self'
    if (isExtLink && target === '_self') {
      target = '_blank'
    }
    return `<a href="${node.data.uri}" target="${target}" class="embed-asset" >${text}</a>`
  }
}
