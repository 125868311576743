// eslint-disable-next-line import/prefer-default-export
export const GET_SITE = `
  siteInfo: sites(site_id:"$site_id",locale :"$lang") {
    site_id
    entity_id
    url
    alert_theme
    alert_message
    mailchimp_list_id
  }
`
