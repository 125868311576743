const config = require('./config')
const development = require('../env.development.enc')
const staging = require('../env.staging.enc')
const production = require('../env.production.enc')

const CryptoJS = require('crypto-js')

/**
 * Get the encrypted file for the node env
 * @param {String} nodeEnv
 * @returns {Void}
 */
module.exports = (nodeEnv) => {
  // get the node env
  const env = (nodeEnv === undefined) ? process.env.NODE_ENV : nodeEnv
  // set default .env file to null
  let envFile = null

  // eslint-disable-next-line no-console
  console.log('Encrypt file => ', env)

  // get the good file by env variable
  switch (env) {
    case 'development':
      envFile = development
      break
    case 'staging':
      envFile = staging
      break
    case 'production':
      envFile = production
      break
    default:
      break
  }

  const encFile = {}

  // eslint-disable-next-line no-console
  console.log('Original file => ', envFile)

  // if env file existe
  if (envFile) {
    // loop on all file key / value
    // eslint-disable-next-line array-callback-return
    Object.keys(envFile).map((objectKey) => {
      // encrypt value
      // eslint-disable-next-line max-len
      encFile[objectKey] = CryptoJS.AES.encrypt(envFile[objectKey], config.secret[env]).toString()
    })
  }

  // eslint-disable-next-line no-console
  console.log('Encrypted file => ', encFile)
  // eslint-disable-next-line no-console
  console.log(`Copy/paste the string in /src/env/env.${nodeEnv}.enc.js=> module.exports =`, JSON.stringify(encFile, null, 4))
}
