<template>
  <section class="section-cards" :class="[
      type ? '' + type : '',
      borderDisplay == 'none' ? 'no-border' : '',
      borderDisplay == 'full' ? 'borders' : '',
      borderDisplay == 'half' ? 'half-border' : '',
      bottomSpace ? 'bottom-space' : '',
      theme ]">
    <div class="row no-gutters justify-content-center" :class="[layoutSize]" v-if="addRow">

      <Cards
          :titleCard="firstRow.title"
          :subTitleCard="firstRow.subTitleCard"
          :contentCard="firstRow.content"
          :type="`${firstRow.type} ${(firstRow.title && !firstRow.content) ? 'col-lg-12' : 'col-lg-8'} pb`"
          :theme="firstRow.theme"
          :noBorder="true"
      />
    </div>
    <div class="row no-gutters card-list"
         :class="[type === 'is-summary' ? 'justify-content-center no-border' : '',
         alignmentColumns === 'center' ? 'justify-content-center no-border' : '' ,
         layoutSize]"
    >
      <Cards
          v-for="(element, index) in elements"
          :key="index"
          :uKey="index"
          :iconCard="element.icon"
          :date="element.date"
          :imageCard="element.imageCard"
          :titleCard="element.title"
          :supTitle="element.supTitle"
          :contentCard="element.content"
          :ctaCard="element.cta"
          :type="`${element.type} ${type} col-md-6 ${colsPerRow}`"
          :theme="element.theme"
          :videoId="element.videoId"
          :subTitleCard="element.subTitleCard"
          :hasFullBorder="element.hasFullBorder"
          :hasBorder="element.hasBorder"
          :noBorder="!borderDisplay"
          :textAlignment="element.textAlignment"
      />
    </div>
    <CtaFullWidth
        v-if="ctaFullWidthData"
        :ctaFullWidthData="ctaFullWidthData"
        :theme="theme"
    />
  </section>
</template>

<script>
import Cards from './Cards.vue'
import CtaFullWidth from './CtaFullWidth.vue'

export default {
  name: 'SectionCards',
  components: {
    Cards,
    CtaFullWidth
  },
  props: {
    addRow: {
      type: Boolean,
      default: false
    },
    firstRow: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: 'classic'
    },
    theme: {
      type: String,
      default: 'white'
    },
    elements: {
      type: Array,
      default: null
    },
    ctaFullWidthData: {
      type: Object,
      default: null
    },
    layout: {
      type: Number,
      default: 1
    },
    alignmentColumns: {
      type: String,
      default: 'start'
    },
    layoutSize: {
      type: String,
      default: ''
    },
    borderDisplay: {
      type: [Boolean, String],
      default: false
    },
    bottomSpace: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * Add the class for the number of column per row
     * @returns {String}
     */
    colsPerRow () {
      if (this.layout === 1) {
        return ''
      }
      if (this.layout === 2) {
        return 'col-lg-6'
      }
      if (this.layout === 3) {
        return 'col-lg-4'
      }
      if (this.layout === 6) {
        return 'col-lg-2'
      }
      return ''
    }
  }
}
</script>

<style lang="scss">
.section-cards {
  .row {
    &.content_area {
      @include breakpoint("lg") {
        width: calc(100% - 7rem);
      }
      margin: 0 auto;
    }
  }

  &.white {
    background-color: $white;
  }

  &.black {
    background-color: $black;
  }

  &.grey {
    background-color: $grey-light;
  }

  &.red {
    background-color: $red;
  }

  &.bottom-space {
    padding-bottom: 4rem;

    @include breakpoint("lg") {
      padding-bottom: 8rem;
    }

    + .section-cards.borders {
      border-top: 1px solid $grey-light;
    }
  }

  &.borders {
    .single-card {
      & > .wrapper {
        @include breakpoint("md") {
          border-right: 1px solid $grey-light;
        }
        border-bottom: 1px solid $grey-light;
      }
    }
  }

  &.no-border {
    .single-card {
      & > .wrapper {
        border: 0;
      }
    }
  }

  &.half-border {
    .single-card {
      @include breakpoint("md") {
        &:nth-child(2n+1) {
          &::after {
            content: '';
            background-color: $grey-light;
            width: 1px;
            height: calc(100% - 16rem);
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
          }
        }

        &:last-child {
          &::after {
            content: none;
          }
        }
      }

      @include breakpoint("lg") {
        .wrapper {
          padding: 0 4rem;
        }

        &::after {
          content: '';
          background-color: $grey-light;
          width: 1px;
          height: calc(100% - 16rem);
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }

      & > .wrapper {
        border: 0;
        border-top: 1px solid $grey-light;
        padding-bottom: 4rem;
      }

      .desc {
        padding-top: 4rem !important;
        width: 100%;
      }

      &__footer {
        width: 100%;
      }
    }
  }


  @include breakpoint("lg") {
    padding-top: 0;
  }

  &.__centered {
    text-align: center;
  }

  &.is-summary {
    @include breakpoint("lg") {
      margin-bottom: -2rem;
    }

    .single-card {
      flex: 0 0 100%;
      max-width: 100%;
      @include breakpoint("lg") {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
      }

      ::v-deep {
        p:not(.date) {
          font-size: 2.1rem;
        }
      }
    }
  }
}
</style>
