export const GET_EN_RESSOURCE_CATEGORIES = `
    pageEn: articlecategorys(locale: "en-CA", slug: "$slug", site_id: "$site_id", articles: true) {
      entity_id
      slug
      full_url
      mount_application
      parent_url
      name
      meta_data
      summary
      image
      components
      child_categories
      articles
    }
`

export const GET_FR_RESSOURCE_CATEGORIES = `
    pageFr: articlecategorys(locale: "fr-CA", slug: "$slug", site_id: "$site_id", articles: true) {
      entity_id
      slug
      full_url
      mount_application
      parent_url
      name
      meta_data
      summary
      image
      components
      child_categories
      articles
    }
`

export const GET_EN_ARTICLES = `
    pageEn: articles(locale: "en-CA", slug: "$slug", site_id: "$site_id", first: 1, offset: 0) {
      entity_id
      title
      display_date
      slug
      full_url
      mount_application
      parent_url
      meta_data
      summary
      image
      components
      date_update
      pre_footer_components
      date_creation
      category
    }
`

export const GET_FR_ARTICLES = `
    pageFr: articles(locale: "fr-CA", slug: "$slug", site_id: "$site_id", first: 1, offset: 0) {
      entity_id
      title
      display_date
      slug
      full_url
      mount_application
      parent_url
      meta_data
      summary
      image
      components
      date_update
      pre_footer_components
      date_creation
      category
    }
`

export const GET_EN_ARTICLES_PREVIEW = `
    pageEn: articles(locale: "en-CA", entity_id: "$entity", site_id: "$site_id", preview: true, first: 1, offset: 0) {
      entity_id
      title
      display_date
      slug
      full_url
      mount_application
      parent_url
      meta_data
      summary
      image
      components
      date_update
      pre_footer_components
      date_creation
      category
    }
`

export const GET_FR_ARTICLES_PREVIEW = `
    pageFr: articles(locale: "fr-CA", entity_id: "$entity", site_id: "$site_id", preview: true, first: 1, offset: 0) {
      entity_id
      title
      display_date
      slug
      full_url
      mount_application
      parent_url
      meta_data
      summary
      image
      components
      date_update
      pre_footer_components
      date_creation
      category
    }
`

export const GET_EN_RESSOURCE_CATEGORIES_PREVIEW = `
    pageEn: articlecategorys(locale: "en-CA", entity_id: "$entity", site_id: "$site_id", preview: true, articles: true) {
      entity_id
      slug
      full_url
      mount_application
      parent_url
      name
      meta_data
      summary
      image
      components
      child_categories
      articles
    }
`

export const GET_FR_RESSOURCE_CATEGORIES_PREVIEW = `
    pageFr: articlecategorys(locale: "fr-CA", entity_id: "$entity", site_id: "$site_id", preview: true, articles: true) {
      entity_id
      slug
      full_url
      mount_application
      parent_url
      name
      meta_data
      summary
      image
      components
      child_categories
      articles
    }
`
