<template>
  <div class="header_wrapper">
    <header>
      <nav class="header-primary">
        <div class="cta-menu-opener">
          <button
              :class="{ 'is-active': isBurgerActive }"
              @click.prevent="toggleMainMenu"
              class="hamburger hamburger--spin"
              type="button"
          >
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </div>
        <div class="brand-nav text-center">
          <router-link to="/">
            <img src="../../../public/img/logo-auto-select.png" alt="Autoselect" />
          </router-link>
        </div>

        <div class="right-nav">
          <div
              v-if="!searchDisabled"
              class="__cta-search"
              @click="searchOpen = !searchOpen"
          >
            <svg
                key="0"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="icon icon-search"
            >
              <path
                  fill="currentColor"
                  d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z"
                  class=""
              ></path>
            </svg>
          </div>
          <b-link href="#" class="lang-switcher" @click="changeLang">
            {{ $t('header.lang') }}
          </b-link>
        </div>
      </nav>

      <nav
          class="nav-mobile-wrapper nav-level-1 nav-fixed-container"
          :class="{ '__is-active': isBurgerActive }"
      >
        <div class="store-nav">
          <!-- STORE -->
          <b-link
              class="cta-find-strore"
              v-if="findStoreNavigation"
              :to="`${getPathUrl()}${findStoreNavigation.slug}`"
          >
            <label>{{ findStoreNavigation.page_title }}</label>

            <span class="icon-group">
              <svg
                  class="icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
              >
                <g id="Store">
                  <path
                      id="Tracé_831"
                      d="M4.9 0v20h20V0zm18.773 1.227v4.294H6.127V1.227zM6.127 18.773V6.748h17.546v12.025z"
                      class="cls-1"
                      data-name="Tracé 831"
                      transform="translate(-4.9)"
                  />
                  <path
                      id="Tracé_832"
                      d="M44.32 41.2h-1.166a.673.673 0 0 0-.654.654v7.056a.673.673 0 0 0 .654.654h1.166a.131.131 0 0 0 .123-.123v-.859a.131.131 0 0 0-.123-.123h-.348a.432.432 0 0 1-.429-.429v-5.317a.432.432 0 0 1 .429-.429h.348a.131.131 0 0 0 .123-.123V41.3c-.043-.018-.082-.1-.123-.1z"
                      class="cls-1"
                      data-name="Tracé 832"
                      transform="translate(-34.811 -32.774)"
                  />
                  <path
                      id="Tracé_833"
                      d="M56.67 41.2h-1.227a.131.131 0 0 0-.123.123v.859a.131.131 0 0 0 .123.123h.409a.432.432 0 0 1 .429.429v1.084a.275.275 0 0 1-.041.184l-.593 1.186a.336.336 0 0 0-.041.184c0 .082 0 .164.041.184l.573 1.207a.336.336 0 0 1 .041.184v1.083a.432.432 0 0 1-.429.429h-.409a.131.131 0 0 0-.123.123v.859a.131.131 0 0 0 .123.123h1.227a.673.673 0 0 0 .654-.654v-2.025a.677.677 0 0 0-.082-.307l-.45-.92a.651.651 0 0 1 0-.552l.47-.941a.677.677 0 0 0 .082-.307v-2.024a.617.617 0 0 0-.654-.634z"
                      class="cls-1"
                      data-name="Tracé 833"
                      transform="translate(-44.993 -32.774)"
                  />
                </g>
              </svg>
              <svg
                  class="icon icon__sm"
                  id="arrow-right"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 31.49 31.49"
              >
                <path
                    d="M21.205 5.007a1.112 1.112 0 00-1.587 0 1.12 1.12 0 000 1.571l8.047 8.047H1.111A1.106 1.106 0 000 15.737c0 .619.492 1.127 1.111 1.127h26.554l-8.047 8.032c-.429.444-.429 1.159 0 1.587a1.112 1.112 0 001.587 0l9.952-9.952a1.093 1.093 0 000-1.571l-9.952-9.953z"
                    fill="#9b9b9b"
                />
              </svg>
            </span>
          </b-link>
          <!-- SELECTED shop -->
          <b-link
              v-if="
              selectedShop && findShopDetailNavigation && findShopNavigation
            "
              class="cta-my-store"
              :to="{
              path: `${getPathUrl()}${findShopNavigation.slug}/${
                findShopDetailNavigation.slug
              }/${selectedShop.slug}`
            }"
          >
            <div>
              <label>{{ $t('generalUi.myShop') }}</label>
              <address>
                {{ selectedShop.name }}
                <br/>
                {{ selectedShop.phone }}
              </address>
            </div>

            <span class="icon-group">
              <svg
                  class="icon icon__md"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="13.478"
                  viewBox="0 0 24 13.478"
              >
                <g id="Shop">
                  <path
                      id="Tracé_834"
                      d="M23.9 144.391a2.135 2.135 0 0 0-2-2.221 2.58 2.58 0 0 1-2.13-1.2l-.113-.159a3.725 3.725 0 0 0-2.991-1.79h-6.319a.493.493 0 0 0-.227.045 3.779 3.779 0 0 0-1.541 1.2l-.544.589a6.029 6.029 0 0 1-1.36 1.133l-4.147-4.011a.682.682 0 0 0-.861 0l-.022.023a.686.686 0 0 0 .023.884l3.807 3.648a7.674 7.674 0 0 1-1.246.295l-2.4.317A2.2 2.2 0 0 0-.1 145.32v1.745a2.176 2.176 0 0 0 2.175 2.175h1.337a2.67 2.67 0 0 0 2.607 2.06 2.767 2.767 0 0 0 1.926-.816 2.472 2.472 0 0 0 .657-1.246h7.41a2.67 2.67 0 0 0 2.606 2.062 2.767 2.767 0 0 0 1.926-.816 2.472 2.472 0 0 0 .657-1.246h.5a2.176 2.176 0 0 0 2.175-2.175zm-6.39 3.58a1.344 1.344 0 0 1 1.11-.589 1.452 1.452 0 0 1 1.11.567 1.286 1.286 0 0 1 .227.77 1.315 1.315 0 0 1-.34.929 1.268 1.268 0 0 1-.952.408 1.364 1.364 0 0 1-1.36-1.36 1.016 1.016 0 0 1 .203-.724zm-12.577 0a1.344 1.344 0 0 1 1.11-.589 1.452 1.452 0 0 1 1.11.567 1.286 1.286 0 0 1 .227.77 1.315 1.315 0 0 1-.34.929 1.268 1.268 0 0 1-.952.408 1.364 1.364 0 0 1-1.36-1.36 1.016 1.016 0 0 1 .203-.724zm15.795-.884a2.665 2.665 0 0 0-4.328.091 2.653 2.653 0 0 0-.385.861H8.579a2.52 2.52 0 0 0-.317-.77v-.045l-.068-.068a2.75 2.75 0 0 0-2.175-1.133 2.7 2.7 0 0 0-2.219 1.155 2.653 2.653 0 0 0-.385.861H2.1a.943.943 0 0 1-.952-.952v-1.745a.973.973 0 0 1 .838-.952l2.425-.272a7.328 7.328 0 0 0 4.578-2.4l.544-.612a3.577 3.577 0 0 1 .952-.816h6.141a2.485 2.485 0 0 1 1.972 1.178l.113.181a3.812 3.812 0 0 0 3.127 1.79.981.981 0 0 1 .906.952v2.493h-.024v.227a.943.943 0 0 1-.952.952h-.589a2.52 2.52 0 0 0-.317-.771v-.181h-.136z"
                      class="cls-1"
                      data-name="Tracé 834"
                      transform="translate(.1 -137.825)"
                  />
                </g>
              </svg>
              <svg
                  class="icon icon__sm"
                  id="arrow-right"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 31.49 31.49"
              >
                <path
                    d="M21.205 5.007a1.112 1.112 0 00-1.587 0 1.12 1.12 0 000 1.571l8.047 8.047H1.111A1.106 1.106 0 000 15.737c0 .619.492 1.127 1.111 1.127h26.554l-8.047 8.032c-.429.444-.429 1.159 0 1.587a1.112 1.112 0 001.587 0l9.952-9.952a1.093 1.093 0 000-1.571l-9.952-9.953z"
                    fill="#9b9b9b"
                />
              </svg>
            </span>
          </b-link>

          <!-- SHOP -->
          <b-link
              v-if="findShopNavigation && !selectedShop"
              class="cta-my-store"
              :to="`${getPathUrl()}${findShopNavigation.slug}`"
          >
            <label>{{ findShopNavigation.page_title }}</label>
            <span class="icon-group">
              <svg
                  class="icon icon__md"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="13.478"
                  viewBox="0 0 24 13.478"
              >
                <g id="Shop">
                  <path
                      id="Tracé_834"
                      d="M23.9 144.391a2.135 2.135 0 0 0-2-2.221 2.58 2.58 0 0 1-2.13-1.2l-.113-.159a3.725 3.725 0 0 0-2.991-1.79h-6.319a.493.493 0 0 0-.227.045 3.779 3.779 0 0 0-1.541 1.2l-.544.589a6.029 6.029 0 0 1-1.36 1.133l-4.147-4.011a.682.682 0 0 0-.861 0l-.022.023a.686.686 0 0 0 .023.884l3.807 3.648a7.674 7.674 0 0 1-1.246.295l-2.4.317A2.2 2.2 0 0 0-.1 145.32v1.745a2.176 2.176 0 0 0 2.175 2.175h1.337a2.67 2.67 0 0 0 2.607 2.06 2.767 2.767 0 0 0 1.926-.816 2.472 2.472 0 0 0 .657-1.246h7.41a2.67 2.67 0 0 0 2.606 2.062 2.767 2.767 0 0 0 1.926-.816 2.472 2.472 0 0 0 .657-1.246h.5a2.176 2.176 0 0 0 2.175-2.175zm-6.39 3.58a1.344 1.344 0 0 1 1.11-.589 1.452 1.452 0 0 1 1.11.567 1.286 1.286 0 0 1 .227.77 1.315 1.315 0 0 1-.34.929 1.268 1.268 0 0 1-.952.408 1.364 1.364 0 0 1-1.36-1.36 1.016 1.016 0 0 1 .203-.724zm-12.577 0a1.344 1.344 0 0 1 1.11-.589 1.452 1.452 0 0 1 1.11.567 1.286 1.286 0 0 1 .227.77 1.315 1.315 0 0 1-.34.929 1.268 1.268 0 0 1-.952.408 1.364 1.364 0 0 1-1.36-1.36 1.016 1.016 0 0 1 .203-.724zm15.795-.884a2.665 2.665 0 0 0-4.328.091 2.653 2.653 0 0 0-.385.861H8.579a2.52 2.52 0 0 0-.317-.77v-.045l-.068-.068a2.75 2.75 0 0 0-2.175-1.133 2.7 2.7 0 0 0-2.219 1.155 2.653 2.653 0 0 0-.385.861H2.1a.943.943 0 0 1-.952-.952v-1.745a.973.973 0 0 1 .838-.952l2.425-.272a7.328 7.328 0 0 0 4.578-2.4l.544-.612a3.577 3.577 0 0 1 .952-.816h6.141a2.485 2.485 0 0 1 1.972 1.178l.113.181a3.812 3.812 0 0 0 3.127 1.79.981.981 0 0 1 .906.952v2.493h-.024v.227a.943.943 0 0 1-.952.952h-.589a2.52 2.52 0 0 0-.317-.771v-.181h-.136z"
                      class="cls-1"
                      data-name="Tracé 834"
                      transform="translate(.1 -137.825)"
                  />
                </g>
              </svg>
              <svg
                  class="icon icon__sm"
                  id="arrow-right"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 31.49 31.49"
              >
                <path
                    d="M21.205 5.007a1.112 1.112 0 00-1.587 0 1.12 1.12 0 000 1.571l8.047 8.047H1.111A1.106 1.106 0 000 15.737c0 .619.492 1.127 1.111 1.127h26.554l-8.047 8.032c-.429.444-.429 1.159 0 1.587a1.112 1.112 0 001.587 0l9.952-9.952a1.093 1.093 0 000-1.571l-9.952-9.953z"
                    fill="#9b9b9b"
                />
              </svg>
            </span>
          </b-link>
        </div>
        <div class="primary-nav">
          <b-link
              v-for="main in mainNavigation"
              v-bind:key="main.entity_id"
              :to="`${getPathUrl()}${main.slug}`"
          >
            {{ main.page_title }}
          </b-link>
          <span v-for="top in topNavigation" v-bind:key="top.entity_id">
            <b-link v-if="!top.pdf" :to="`${getPathUrl()}${top.slug}`">
              {{ top.page_title }}
            </b-link>
            <b-link
                v-else-if="top.pdf && top.pdf.file && top.pdf.file.url"
                :href="top.pdf.file.url"
                target="_blank"
            >
              {{ top.page_title }}
            </b-link>
          </span>
        </div>
      </nav>
    </header>

    <div
        class="search-container"
        :class="searchOpen ? 'active' : ''"
        v-if="!searchDisabled"
    >
      <div class="__input-wrapper">
        <input
            type="text"
            v-model="q"
            :placeholder="$t('generalUi.search')"
            @keyup.enter="doSearch()"
            @keydown.esc="searchOpen = !searchOpen"
        />
        <span v-show="q" @click="q = ''" class="input-reset">
          <svg
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
              class="icon icon-cross icon__sm"
          >
            <path
                fill="currentColor"
                d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                class=""
            ></path>
          </svg>
        </span>
      </div>
    </div>

    <transition name="fade">
      <section v-if="q && searchOpen" class="search-results row no-gutters">
        <div class="coverflow ol col-lg-7 offset-lg-2">
          <div v-if="searchResult.items">
            <h3>
              {{
                $t('generalUi.searchCount', {
                  count: searchResult.queries.request[0].totalResults,
                  query: q
                })
              }}
            </h3>

            <div
                class="search-results__item"
                v-for="(post, i) in searchResult.items"
                v-bind:post="post"
                :key="i"
            >
              <a class="text-dark" :href="post.link">
                <em>{{ post.title }}</em>
              </a>
              <p>{{ post.snippet }}</p>
            </div>

            <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                per-page="10"
                aria-controls="my-table"
                @change="paginationChange"
            ></b-pagination>
          </div>
          <div v-else>
            <h3>{{ $t('generalUi.noResult') }}</h3>
          </div>
        </div>
      </section>
    </transition>
  </div>
</template>

<script>
import headerMixing from '../../mixins/header'

export default {
  name: 'Header',
  mixins: [headerMixing],
  async mounted () {
    const bodyElem = document.querySelector('body')
    bodyElem.style.removeProperty('overflow')
  },
  methods: {
    /**
     * Set the burger menu state
     * @returns {Void}
     */
    toggleMainMenu () {
      const bodyElem = document.querySelector('body')
      this.isBurgerActive = !this.isBurgerActive

      if (this.isBurgerActive) {
        bodyElem.style.overflow = 'hidden'
      } else {
        bodyElem.style.removeProperty('overflow')
      }
    },
    /**
     * Add class active on the sub menu
     * @param {Object} el
     * @returns {Void}
     */
    toggleSubMenu (el) {
      el.target.querySelector('.nav-level-2')
        .classList
        .add('__is-active')
    },
    /**
     * Remove class active
     * @param {Object} el
     * @returns {Void}
     */
    backToMenu (el) {
      // get all elements
      const elems = document.querySelectorAll('.nav-level-2')
      // loop on the elements
      elems.forEach((element) => {
        // remove class
        element.classList.remove('__is-active')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.header_wrapper {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9;
  border-bottom: 1px solid $grey-b2b;
  height: 45px;
}

a {
  transition: all 0.3s ease-out;
  text-decoration: none;
}

.nav-fixed-container {
  height: calc(
      100vh - 45px
  ); /* Fallback for browsers that do not support Custom Properties */
  height: calc((var(--vh, 1vh) * 100) - 45px);
  width: 100%;
  position: fixed;
  left: 0;
  top: 45px;
  z-index: 99;
  transform: translateX(100%);
  transition: transform 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  overflow: scroll;

  &.__is-active {
    transform: translateX(0%);
  }
}

.header-primary {
  background-color: $dark-grey-unipro;
  height: 4.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
}

.brand-nav {
  img {
    height: 31px;
  }
}

.right-nav {
  display: flex;

  .__cta-search {
    width: 30px;

    svg path {
      fill: white;
    }
  }
}

.lang-switcher {
  color: #fff;
}

.nav-mobile-wrapper {
  background-color: $grey-b2b;

  label {
    margin: 0;
  }

  & a {
    height: 7rem;
    display: flex;
    font-weight: 600;

    &.router-link-active {
      background-color: $menu-active;
    }
  }

  .store-nav {
    background-color: $grey-light;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 2rem;
    position: relative;

    label {
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 0;
      text-align: center;
    }

    a {
      display: flex;
      text-decoration: none;
      justify-content: center;
      align-items: center;
      padding: 0 2.5rem;
      background-color: #fff;
      border-bottom: 1px solid $grey-b2b;
      position: relative;

      > * {
        cursor: pointer;
      }
    }

    .cta-my-strore {
      address {
        font-size: 0.9rem;
        margin-bottom: 0;
        line-height: 1.22;
        font-weight: 600;
      }
    }

    .icon-group {
      position: absolute;
      right: 2.5rem;
      top: 50%;
      transform: translateY(-50%);

      svg {
        margin-right: 0.8rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .primary-nav {
    & > a,
    & > span a {
      color: $white;
      background-color: $black;
      font-weight: 600;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid $dark-grey-b2b;

      label {
        pointer-events: none;
      }

      &.router-link-active {
        background-color: $menu-active;
      }
    }
  }

  .nav-level-2 {
    background-color: $white;

    & a:not(.cta-back-to-menu) {
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid $grey-light;

      svg {
        position: absolute;
        right: 1.5rem;

        path {
          fill: $red;
        }
      }
    }
  }
}

.__actif {
  background-color: $red;
  color: white;
}

.cta-back-to-menu {
  background-color: $black;
  color: $white;
  justify-content: center;
  align-items: center;

  label {
    margin: 0 0 0 1.5rem;
  }
}

.cta-all {
  background-color: $red;
  color: white;
  border-bottom: 1px solid $grey-b2b !important;

  svg path {
    fill: currentColor !important;
  }
}

.search-container {
  transform: translateX(100%);
  transition: all 0.3s ease;
  pointer-events: none;
  border-bottom: 1px solid $grey-light;

  input {
    width: 100vw;
    height: 70px;
    padding: 0 3.5rem;
    background-color: $grey-b2b;
    border: none;
    transition: all 0.3s ease;
    border-radius: 0;
  }

  .input-reset {
    position: absolute;
    right: 4.5rem;
    top: calc(50% - 10px);
  }

  &.active {
    pointer-events: auto;
    transform: translateX(0%);
  }
}

.search-results {
  max-height: calc(
      100vh - 100px
  ) !important; /* Fallback for browsers that do not support Custom Properties */
  max-height: calc((var(--vh, 1vh) * 100) - 100px) !important;

  .coverflow {
    padding-bottom: 40px;
    overflow: auto;
  }
}
</style>
