/* eslint-disable max-len */
<template>
  <div class="my-router">
    <component v-if="!isLoad" v-bind:is="loadingComponent"/>
    <MainLayout v-else>
      <component
          v-for="page in structurePage"
          v-bind:key="page.id"
          :is="page.component" v-bind="page.props"
      >
        <component v-if="page.slot" :is="page.slot.component" v-bind="page.slot.props"></component>
      </component>
    </MainLayout>
  </div>
</template>

<script>
import Vue from 'vue'
import gql from 'graphql-tag'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { GET_EN_NAVIGATION, GET_FR_NAVIGATION } from '../graphql/queries/navigations'
import { GET_FR_PAGE, GET_EN_PAGE } from '../graphql/queries/pages'
import { GET_SITE } from '../graphql/queries/site'
import Components from '../components'
import Builder from '../builder'
import findShop from '../mixins/findShop'
import myRouterPage from '../mixins/MyRouter/myRouterPage'
import myRouterMetaData from '../mixins/MyRouter/myRouterMetaData'
import hackQL from '../utils/hackQL'
import EventBus from '../utils/events'
import { SITE_INFO_SAX } from './configSites'

export default {
  name: 'MyRouter',
  mixins: [findShop, myRouterPage, myRouterMetaData],
  data () {
    return {
      loadingComponent: 'Loading',
      isLoad: false,
      lang: this.$store.getters.getLang,
      page: [],
      pageEn: [],
      pageFr: [],
      structurePage: [],
      productName: '',
      metaData: {
        title: '',
        // Metas desc
        description: '',
        canonicalUrl: window.location.href,
        keywords: '',
        // Metas OG
        ogTitle: '',
        ogUrl: '',
        ogType: '',
        ogImgUrl: ''
      }
    }
  },
  props: {
    isPreview: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    $route (to, from) {
      // when route change, if page is load
      if (this.isLoad === true) {
        // display loader
        this.isLoad = false
        // load the new page
        this.loadPage(to.path)
      }
    },
    page (newPage) {
      // Page not load
      this.isLoad = false
      // Clear page
      this.structurePage = []

      try {
        newPage.forEach((item) => {
          const components = this.$JSON5.parse(item.components)

          // Build breadcrumbs
          let isPageModule = true
          if (item.child_categories) {
            if (!hackQL.is(this.$route.path, 'ressources')) {
              const childCategories = this.$JSON5.parse(item.child_categories)
              this.structurePage.push(Builder.Breadcrumbs.build(childCategories, this.$route.path, this.$t('generalUi.categories')))
              isPageModule = false
            }
          } else if (hackQL.is(this.$route.path, 'services') || (this.$route.query.content_type && this.$route.query.content_type === 'services_category')) {
            isPageModule = false
            this.structurePage.push(Builder.Breadcrumbs.build([], this.$route.path, this.$t('generalUi.categories')))
          } else if (hackQL.is(this.$route.path, 'products') || (this.$route.query.content_type && this.$route.query.content_type === 'product_category')) {
            isPageModule = false
            this.structurePage.push(Builder.Breadcrumbs.build([], this.$route.path, this.$t('generalUi.categories')))
          } else if (hackQL.is(this.$route.path, 'product')) {
            isPageModule = false
            this.structurePage.push(Builder.Breadcrumbs.build([], this.$route.path, this.$t('generalUi.categories')))
          }

          // Build page by components
          if (components) {
            this.setPageComponents(components, item)
          }

          // ajoute une class pour les pages ou la navigation des categories est active
          if (hackQL.is(this.$route.path, 'products') || hackQL.is(this.$route.path, 'services')) {
            if (this.$store.SITE_ID !== 7) {
              document.body.classList.add('categorie-nav__active')
            }
          } else {
            document.body.classList.remove('categorie-nav__active')
          }
          // Build page with child categories
          if (item.child_categories) {
            const childCategories = this.$JSON5.parse(item.child_categories)
            // if is ressources
            if (hackQL.is(this.$route.path, 'ressources')) {
              // build hild ressources
              this.setPageChildCategoriesRessources(childCategories)
            } else {
              // build hild ressources
              this.setPageChildCategories(childCategories, item, true)
              // if we have brand
              if (item.brand) {
                // Build new child category for the brands
                const brandCategories = this.$JSON5.parse(item.brand)
                this.setPageChildCategories(brandCategories, item, false)
              }
            }
          }
          // Build breadcrumbs in page modules
          let displayBack = false
          const childPages = []
          let mTitle = this.$t('generalUi.categories')
          if (this.$route.query.p) {
            displayBack = true
          }
          if (item.page_title) {
            mTitle = item.page_title
          }
          if (displayBack && isPageModule) {
            if (this.$store.SITE_ID !== 7) {
              this.structurePage.push(Builder.Breadcrumbs.build(childPages, this.$route.path, mTitle, 'pages'))
            }
            if (this.$store.SITE_ID !== 7) {
              document.body.classList.add('categorie-nav__active')
            }
          }
          // Build article in ressources page
          if ((hackQL.is(this.$route.path, 'ressources') || (this.$route.query.content_type && this.$route.query.content_type === 'article_category')) && item.articles) {
            const articles = this.$JSON5.parse(item.articles)
            this.setPageArticles(articles)
          }

          // If we are in product page
          if (hackQL.is(this.$route.path, 'product')) {
            // Save the name of product for the breadcrumns
            this.productName = item.name
          }

          // Build products in products page
          if ((hackQL.is(this.$route.path, 'products') || (this.$route.query.content_type && this.$route.query.content_type === 'product_category')) && item.products && !item.child_categories) {
            const categoriesProducts = this.$JSON5.parse(item.products)
            this.setPageProductsInProducts(categoriesProducts)
          }

          // Build brand for product page
          if ((hackQL.is(this.$route.path, 'products') || (this.$route.query.content_type && this.$route.query.content_type === 'product_category')) && item.brand) {
            const brands = this.$JSON5.parse(item.brand)
            this.setPageProductsBrand(brands)
          }

          // Build article
          if (hackQL.is(this.$route.path, 'articles') || (this.$route.query.content_type && this.$route.query.content_type === 'article')) {
            // default category name
            let displayCategory = ''
            // if the article have category
            if (item.category) {
              // get the category
              const articleCategory = this.$JSON5.parse(item.category)
              displayCategory = (articleCategory[0] && articleCategory[0].name) ? `${articleCategory[0].name} |` : ''
            }

            this.structurePage.push(Builder.BlockMultiColumns.build({
              entity_id: `serv_${Math.floor(Math.random() * 9999999999)}`,
              type: 'is-summary',
              columns: [
                {
                  title: `${(item.title) ? item.title : ''}`,
                  date: (item.display_date) ? `${displayCategory} ${item.display_date}` : `${displayCategory}`,
                  description: this.$JSON5.parse(item.summary),
                  noBorder: true
                }
              ]
            }))
          }

          // Build pre footer
          if (item.pre_footer_components) {
            const preFooter = this.$JSON5.parse(item.pre_footer_components)
            this.setPageComponents(preFooter, item)
          }

          // if we haven't any components in the page
          if (!components &&
              !item.pre_footer_components &&
              !item.child_categories &&
              !item.articles &&
              !item.products) {
            // Display the 404 page
            // this.notFound()
          }
        })

        this.isLoad = true
      } catch (error) {
        // Display internal error
        // this.internal()
        // eslint-disable-next-line no-console
        console.log(error)
      }
    }
  },
  methods: {
    /**
     * Display alert message on the site
     * @param {Object} siteInfo
     * @returns {Void}
     */
    prepareAlertInfo (siteInfo) {
      let message = this.$JSON5.parse(siteInfo.alert_message)
      const themeAlertbar = siteInfo.alert_theme
      message = documentToHtmlString(message, {})

      // if we have a message and the message is different from the store
      if (message !== '' && message !== this.$store.getters.getAlertMessage(this.$i18n.locale)) {
        this.$store.dispatch('createSite', {
          alertMessage: message,
          alertTheme: themeAlertbar,
          url: (siteInfo.url) ? siteInfo.url : '',
          isDisplay: true,
          lang: this.$i18n.locale
        })
      } else if (message === '' && this.$store.getters.getAlertMessage(this.$i18n.locale) !== '') {
        this.$store.dispatch('createSite', {
          alertMessage: '',
          alertTheme: themeAlertbar,
          url: '',
          isDisplay: false,
          lang: this.$i18n.locale
        })
      }
    },
    /**
     * Set the components in the page
     * @param {Object} data
     * @returns {Void}
     */
    setupApplication (data) {
      // Set the page fr/en
      this.pageEn = (data.pageEn) ? data.pageEn : []
      this.pageFr = (data.pageFr) ? data.pageFr : []

      // if we have an alert
      if (data.siteInfo && data.siteInfo[0]) {
        this.prepareAlertInfo(data.siteInfo[0])
      }
      // set the full url of the page (use for switch lang)
      if (this.pageEn && this.pageEn[0] && this.pageEn[0].full_url) {
        // eslint-disable-next-line max-len
        this.$store.dispatch('setFullUrl', { fullUrl: this.$JSON5.parse(this.pageEn[0].full_url) })
        this.$store.dispatch('setPageTitle', { pageTitle: this.pageEn[0].page_title })
        if (this.pageEn[0].leading_image) {
          this.$store.dispatch('setPageLeadingImage', { leadingImage: this.$JSON5.parse(this.pageEn[0].leading_image) })
        } else {
          this.$store.dispatch('setPageLeadingImage', { leadingImage: false })
        }
      } else if (this.pageFr && this.pageFr[0] && this.pageFr[0].full_url) {
        // eslint-disable-next-line max-len
        this.$store.dispatch('setFullUrl', { fullUrl: this.$JSON5.parse(this.pageFr[0].full_url) })
        this.$store.dispatch('setPageTitle', { pageTitle: this.pageFr[0].page_title })
        if (this.pageFr[0].leading_image) {
          this.$store.dispatch('setPageLeadingImage', { leadingImage: this.$JSON5.parse(this.pageFr[0].leading_image) })
        } else {
          this.$store.dispatch('setPageLeadingImage', { leadingImage: false })
        }
      }

      // if we have navigation
      if (data.navigationEn || data.navigationFr) {
        this.$store.dispatch(
          'setNavigations',
          {
            navigationEn: (data.navigationEn) ? data.navigationEn : [],
            navigationFr: (data.navigationFr) ? data.navigationFr : []
          }
        )
      }

      // Store Mailchimp ID info
      if (data.siteInfo) {
        // eslint-disable-next-line max-len
        const findRightSiteID = data.siteInfo.find(site => site.site_id === this.$store.SITE_ID.toString())
        // console.log('FIND SITE ID ======= ', findRightSiteID)
        this.$store.dispatch('setMailChimpId', {
          mailChimpId: findRightSiteID.mailchimp_list_id,
          lang: this.$i18n.locale
        })
      }

      // page is load
      this.isLoad = true
      // display page
      this.setPage()
    },
    /**
     * Get the page data
     * @param {String} path
     * @returns {Void}
     */
    async loadPage (path) {
      if (this.isPreview) return
      // page not loading
      this.isLoad = false

      path = path.replace('/fr/', '')
        .replace('/en/', '')
      const url = path.split('/')
        .filter(e => e !== '')

      // if is the home
      if (!url || url.length < 1) {
        path = '/'
      } else {
        // get last slug
        path = url[url.length - 1]
      }

      // prepare request for the navigation
      let navigationRequest = (this.$i18n.locale === 'fr-CA') ? GET_FR_NAVIGATION : GET_EN_NAVIGATION
      // prepare default request (request by page)
      let pageRequest = (this.$i18n.locale === 'fr-CA') ? GET_FR_PAGE : GET_EN_PAGE

      // if the navigation is already here don't request navigation
      navigationRequest = (this.$store.getters.getCountNavigation(this.$i18n.locale) === 0) ? navigationRequest : ''

      // test if is an hack query
      const hackQuery = hackQL.query(pageRequest, path, this.$route.path, this.$i18n.locale)
      if (hackQuery.isHack) {
        pageRequest = hackQuery.pageRequest // eslint-disable-line
        path = hackQuery.path // eslint-disable-line
      }

      // prepare the graphql query
      // detect site id for calls that do not load mian.js
      let siteID = 9
      if (document.location.pathname.startsWith(`/en/${SITE_INFO_SAX.slugEn}`) || document.location.pathname.startsWith(`/fr/${SITE_INFO_SAX.slugFr}`)) {
        siteID = 7
      }
      const query = gql`
        query {
          ${GET_SITE.replace(/\$lang/g, this.$i18n.locale).replace(/\$site_id/g, siteID)}
          ${navigationRequest.replace(/\$region/g, this.$store.getters.getMyRegion).replace(/\$site_id/g, siteID)}
          ${pageRequest.replace(/\$slug/g, path).replace(/\$region/g, this.$store.getters.getMyRegion).replace(/\$site_id/g, siteID)}
        }
      `
      // send the graphql
      await this.$apollo.query({ query }).then((resp) => {
        // setup the page
        this.setupApplication(resp.data)
      })
    }
  },
  created () {
    // load all component for the application
    Components.forEach((item) => {
      Vue.component(item.name, item)
    })
  },
  mounted () {
    // page is loading
    this.isLoad = false
    // load the page components
    this.loadPage(this.$route.path)

    // Watcher when the user change the lang
    this.$store.watch(
      (state, getters) => getters.getLang,
      (newValue, oldValue) => {
        if (oldValue !== newValue && this.isLoad === true) {
          setTimeout(() => {
            this.loadPage(this.$route.path)
          }, 500)
        }
      }
    )

    // Event when the breadcrumbs request the product name
    EventBus.$on('get-name-breadcrumbs', (params) => {
      // Send the product name
      EventBus.$emit('set-name-breadcrumbs', { value: this.productName })
    })

    // Event when we have an error with graphql
    EventBus.$on('graphql-error-data', (params) => {
      this.isLoad = true
      if (params.data) {
        this.setupApplication(params.data)
      }
    })

    // Event when we get the preview page stucture
    EventBus.$on('set-router-preview', (params) => {
      // reset the page
      this.page = []
      this.pageEn = []
      this.pageFr = []
      this.structurePage = []

      // apply new page structure
      this.setupApplication(params.data)

      // if we remove the breadcrumbs padding
      if (params.removePadding && params.removePadding === true) {
        setTimeout(() => {
          const elmts = document.querySelectorAll('.preview .app-body section:nth-child(2)')
          elmts[0].style.paddingTop = '0px'
        }, 100)
      }
    })

    // Event when we display the loader
    EventBus.$on('router-loading', (params) => {
      this.isLoad = (params.value) ? params.value : false
    })
  }
}
</script>

<style lang="scss" scoped>
.my-router {
  width: 100%;
  height: 100%;
}
</style>
