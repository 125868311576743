import uuidv4 from '../../utils/uuidv4'
import Common from './common'

export default {

  /**
   * Builder for Multi column
   * @param {Object} data
   * @param {String|null} componentName
   * @param {String} lang
   * @returns {Object}
   */
  build (data, componentName, lang) {
    const props = []

    // if we don't have component name, use the default component for multi column
    if (!componentName) {
      componentName = 'Block4Cards'
    }

    let addRow = false
    let firstRow = {}

    // if we have a title or content on multi column (root)
    if (data.title) {
      addRow = true
      firstRow = {
        title: data.title,
        type: 'col-lg-8',
        // content:
        theme: 'white'
      }
    }

    // if we have column
    if (data.columns) {
      // create card
      data.columns.forEach((column) => {
        const ctaLink = (column.cta.length > 0) ? column.cta[0] : column.cta
        props.push({
          title: Common.Title.buildTitle(column.title),
          imageCard: Common.Image.buildImage(column.image),
          cta: ctaLink
        })
      })
    }

    return {
      id: `${data.entity_id}_${uuidv4()}`,
      component: componentName,
      props: {
        addRow,
        firstRow,
        theme: (data.color_theme) ? data.color_theme : 'white',
        elements: props,
        ctaFullWidthData: Common.Cta.buildCta(data, lang)
      }
    }
  }
}
