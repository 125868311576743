<template>
  <nav>
    <div class="custom-navigation">
      <nav class="custom-navigation__wrapper row no-gutters">
        <div class="spacer spacer__red col-lg-1"></div>

        <div class="__nav-element col-lg-2">
          <b-link
              class="icon-group-link __black"
              @click="displayAdvanceSearch = !displayAdvanceSearch"
              :class="{ 'active': displayAdvanceSearch }"
              v-b-toggle.categories-nav-toggler
          >
            <label v-if="displayAdvanceSearch">{{ $t('generalUi.hideCategories') }}</label>
            <label v-else>{{ $t('generalUi.openCategories') }}</label>
            <span class="icon-group">
              <svg
                  class="icon icon__sm"
                  :class="{ 'arrow-rotate': displayAdvanceSearch }"
                  id="arrow-right"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 31.49 31.49"
              >
                <path
                    d="M21.205 5.007a1.112 1.112 0 00-1.587 0 1.12 1.12 0 000 1.571l8.047 8.047H1.111A1.106 1.106 0 000 15.737c0 .619.492 1.127 1.111 1.127h26.554l-8.047 8.032c-.429.444-.429 1.159 0 1.587a1.112 1.112 0 001.587 0l9.952-9.952a1.093 1.093 0 000-1.571l-9.952-9.953z"
                    fill="#9b9b9b"
                />
              </svg>
            </span>
          </b-link>
        </div>
      </nav>
    </div>

    <div class="row no-gutters">
      <div class="col-12">
        <b-collapse id="categories-nav-toggler" :visible="true">
          <div class="row no-gutters">
            <Cards
                v-for="element in elements"
                :key="element.uKey"
                :uKey="element.uKey"
                :imageCard="element.imageCard"
                :subTitleCard="element.subTitleCard"
                :contentCard="element.contentCard"
                :ctaCard="element.ctaCard"
                type="col-lg-4"
                :hasFullBorder="true"
            />
          </div>
        </b-collapse>
      </div>
    </div>
    <!-- FIN NAV -->
  </nav>
</template>

<script>
import Cards from './Cards.vue'

export default {
  name: 'BlogNavCategories',
  components: {
    Cards
  },
  data () {
    return {
      displayAdvanceSearch: true
    }
  },
  props: {
    elements: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-navigation {
  margin-top: -1px;
  border-top: 1px solid $grey-light;
  border-bottom: 1px solid $grey-light;
}

.spacer {
  &__red {
    background-color: $red;
  }

  display: none;

  @include breakpoint("lg") {
    display: block;
  }
}

.icon {
  transition: transform 0.3s ease;
}

.arrow-rotate {
  transform: rotate(450deg);
}

.icon-group-link.__black {
  border-right: 1px solid $grey-light;
  border-left: 1px solid $grey-light;

  @include breakpoint("lg") {
    border-left: 0;
  }

  &:hover, &.active {
    background-color: #fff;
  }
}
</style>
