<template>
  <div class="ais-input-wrapper">
    <input
        class="ais-search-input"
        type="search"
        v-model="query"
        :placeholder="$t('generalUi.search')"
    />
  </div>
</template>

<script>
import { connectSearchBox } from 'instantsearch.js/es/connectors'
import { createWidgetMixin } from 'vue-instantsearch'
import IconSearch from '../../components/icons/IconSearch.vue'
import IconClose from '../../components/icons/IconClose.vue'

export default {
  components: {
    IconSearch,
    IconClose
  },
  mixins: [createWidgetMixin({ connector: connectSearchBox })],
  props: {
    delay: {
      type: Number,
      default: 400,
      required: false
    }
  },
  data () {
    return {
      timerId: null,
      localQuery: ''
    }
  },
  destroyed () {
    if (this.timerId) {
      clearTimeout(this.timerId)
    }
  },
  computed: {
    query: {
      get () {
        return this.localQuery
      },
      set (val) {
        this.localQuery = val
        if (this.timerId) {
          clearTimeout(this.timerId)
        }
        this.timerId = setTimeout(() => {
          this.state.refine(this.localQuery)
        }, this.delay)
      }
    }
  }
}
</script>
