export default {
  /**
   * Builde title
   * @param {String|null} title
   * @returns {String|null}
   */
  buildTitle (title) {
    return (title) || null
  },
  /**
   * Build the sup title (for ressources category / date)
   * @param {Object} data
   * @returns {String|null}
   */
  buildSupTitle (data) {
    // build sup title only for article
    if (data.content_type === 'article') {
      let supTitle = null

      if (data.category &&
          data.category.length > 0 &&
          data.category[0].name) {
        supTitle = `${data.category[0].name} | `
      }

      if (data.display_date) {
        supTitle = (supTitle === null) ? '' : supTitle
        supTitle += data.display_date
      }

      return supTitle
    }

    return null
  }
}
