<template>
  <div class="region-selector">
      <label for=""> {{ $t("generalUi.selectRegion") }}</label>
      <select v-model="selectedRegion">
        <option value="global">Global</option>
        <option value="AB">Alberta</option>
        <option value="BC">British Columbia</option>
        <option value="MB">Manitoba</option>
        <option value="NB">New Brunswick</option>
        <option value="NL">Newfoundland and Labrador</option>
        <option value="NS">Nova Scotia</option>
        <option value="ON">Ontario</option>
        <option value="PE">Prince Edward Island</option>
        <option value="QC">Quebec</option>
        <option value="SK">Saskatchewan</option>
        <option value="NT">Northwest Territories</option>
        <option value="NU">Nunavut</option>
        <option value="YT">Yukon</option>
      </select>
    </div>
</template>

<script>
/**
 * @TODO: @Jerem add label (you are in preview mode, select your region blablabla...) and css
 */
export default {
  name: 'SelectRegion',
  data () {
    return {
      selectedRegion: 'global'
    }
  },
  props: {
    callback: {
      type: Function,
      default: () => {}
    }
  },
  watch: {
    selectedRegion: {
      handler (val) {
        this.callback(val)
      }
    }
  }
}
</script>

<style lang="scss">
    .region-selector{
        padding: 2rem;

        label{
            margin-right: 2rem;
        }
    }
</style>