<template>
  <section
    class="simple-content container-fluid"
    :class="[
      `simple-content__${type} ${(content === null) ? 'btn-only' : ''}`,
      isProductPage || cta.length > 1 ? 'title-only' : '',
      borderDisplay == 'none' ? 'no-border' : '',
      borderDisplay == 'full' ? 'borders' : '',
      borderDisplay == 'half' ? 'half-border' : '',
      bottomSpace ? 'bottom-space' : ''
    ]" >
    <a v-if="anchor"  :id="[`${anchor}`]" />
    <div class="row wrapper justify-content-center">
      <div class="col-lg-8"
          :class="alignment === 'start' ? 'start':'text-center'"
         >
        <h2 v-if="title" v-html="title" :class="[alignment,`h2_${font_size}`]" ></h2>
        <p v-if="content" v-html="content" :class="font_size" ></p>
        <p v-if="textOnly" v-html="textOnly" :class="font_size" ></p>
        <div
          v-if="cta.length > 0"
          class="cta_parent"
        >
          <div
            v-for="(btn, i) in cta"
            :key="i"
          >
            <b-link
              v-if="btn && isExternal(btn.link)"
              :href="btn.link"
              class="btn"
              :class="type === 'black' ? 'btn__inverted' : '' "
              :target="btn.target ? btn.target : '_self'"
            >{{ btn.title }}</b-link>
            <b-link
              v-if="btn && !isExternal(btn.link)"
              :to="btn.link"
              class="btn"
              :class="type === 'black' ? 'btn__white' : '' "
              :target="btn.target ? btn.target : '_self'"
            >{{ btn.title }}</b-link>
          </div>
        </div>
        <div v-if="icon" class="icon_wrapper">
          <svg class="icon icon__sm" aria-hidden="true" data-prefix="fal" data-icon="long-arrow-down" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M252.485 343.03l-7.07-7.071c-4.686-4.686-12.284-4.686-16.971 0L145 419.887V44c0-6.627-5.373-12-12-12h-10c-6.627 0-12 5.373-12 12v375.887l-83.444-83.928c-4.686-4.686-12.284-4.686-16.971 0l-7.07 7.071c-4.686 4.686-4.686 12.284 0 16.97l116 116.485c4.686 4.686 12.284 4.686 16.971 0l116-116.485c4.686-4.686 4.686-12.284-.001-16.97z"/></svg>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import linkMixing from '../../mixins/link'

export default {
  name: 'SectionSimpleContent',
  mixins: [linkMixing],
  props: {
    type: {
      type: String,
      default: 'base'
    },
    icon: {
      default: true
    },
    title: {
      type: String,
      default: null
    },
    content: {
      type: String,
      default: null
    },
    isProductPage: {
      type: Boolean,
      default: false
    },
    textOnly: {
      default: ''
    },
    cta: {
      type: [Array]
    },
    alignment: {
      type: String,
      default: null
    },
    font_size: {
      type: String,
      default: null
    },
    borderDisplay: {
      type: Boolean,
      default: false
    },
    bottomSpace: {
      type: Boolean,
      default: false
    },
    anchor: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss">
.cta_parent {

  @include breakpoint('md') {
    display: inline-flex;
  }

  & > div{
    margin-bottom: 2rem;
    @include breakpoint('md') {
      margin-right: 2rem;
      margin-bottom: 0;
    }

    &:last-child{
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}

.simple-content{
  border-top: 1px solid $grey-light;
  border-bottom: 1px solid $grey-light;
  &.no-border{
    border-top: none;
    border-bottom: none;
  }

  &.bottom-space {
    margin-bottom: 4rem;

    @include breakpoint("lg") {
      margin-bottom: 8rem;
    }
  }
}

.simple-content {
  padding-top: 7.5rem;
  padding-bottom: 9rem;
  position: relative;

  @include breakpoint('lg') {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  &.btn-only {
    padding-top: 8rem;
    padding-bottom: 6rem;
    a {
      margin-top: 0;
    }
  }

  .wrapper{
    position: relative;
    z-index: 2;
  }

  h2 {
    margin: 0 auto;
    text-align: center;
    margin-bottom: 3rem;
    color: currentColor;
    @include breakpoint('md') {
      max-width: 70vw;
    }
  }

  h2.start{
    text-align: left;
  }

  h2.h2_small{
    font-size: 2.3rem;
  }

  .col-lg-8{
    &.start{

      @include breakpoint('lg') {
        padding-left: 0px;

      }
    }

  }

  p {
    font-size: 2.1rem;
    color: currentColor;

    p{
      color: currentColor;
    }
    &.small{
      font-size: 1.4rem;
    }
  }

  .btn{
    margin-top: 2rem;
  }

  &.title-only{
    padding-top: 2.8rem;
    padding-bottom: 2.8rem;

    h1{
      margin-bottom: 0;
    }
    p{
      margin: 0;
      font-size: 1.4rem;
      font-weight: 600;
    }
  }

  &__red {
    background-color: $red;
    color: $white;

    h1{
      color: currentColor;
    }
    p{
      color: $white;
    }
  }
  &__grey {
    background-color: $grey-b2b;

    h1, p{
      color: currentColor;
    }
  }
  &__black {
    background-color: $black;
    color: $white;

    h1, p{
      color: currentColor;
    }
  }

  .icon_wrapper {
    z-index: 2;
    margin-top: 4rem;

    @include breakpoint('md') {

    }

    svg {
      width: 1.8rem;
      path {
        fill: currentColor;
      }
    }
  }

  .scroll_dot{
    height: 0;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
}
</style>
