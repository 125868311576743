<template>
  <ais-instant-search
      class="ais-search-hero-container"
      :search-client="searchClient"
      :index-name="index_search"
  >
    <ais-search-box>
      <algolia-debounced-search-box />
    </ais-search-box>
    <ais-stats>
      <div class="ais-search-results-hero-wrapper" :class="query ? 'algoliaOpen' : '' " slot-scope="{ query }"
           ref="algolia">
        <ais-hits class="ais-search-results-hero row no-gutters">
          <div slot-scope="{ items }">
            <ais-state-results>
              <h5 slot-scope="{ state: { query }, results: { hits } }">
                {{
                  $t('generalUi.searchCount', {
                    count: hits.length,
                    query: query
                  })
                }}
              </h5>
            </ais-state-results>
            <div
                class="ais-search-results-hero__item"
                v-for="item in items"
                :key="item.objectID"
            >
              <a class="text-dark" :href="item.url">
                <ais-highlight attribute="title" :hit="item"/>
              </a>
            </div>
          </div>
        </ais-hits>
      </div>
    </ais-stats>
    <!-- <ais-pagination :padding="2" :show-first="false" :show-last="false" /> -->
  </ais-instant-search>
</template>

<script>
import algoliasearch from 'algoliasearch/lite'
import ClickOutside from 'vue-click-outside'
import IconSearch from '../icons/IconSearch.vue'
import IconClose from '../icons/IconClose.vue'
import AlgoliaDebouncedSearchBox from './AlgoliaDebouncedSearchBox.vue'
import { ALGOLIA } from '../../router/configSites'

const algoliaClient = algoliasearch(ALGOLIA.appId, ALGOLIA.apiKey)

const searchClient = {
  ...algoliaClient,
  search (requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0
        }))
      })
    }

    return algoliaClient.search(requests)
  }
}

export default {
  components: {
    AlgoliaDebouncedSearchBox,
    IconSearch,
    IconClose
  },
  props: {
    siteID: {
      type: String,
      default: '4'
    },
    env: {
      type: String,

      default: ''
    }
  },
  data () {
    return {
      searchClient,
      index_search: ''
    }
  },
  created () {
    this.index_search = this.getSiteIndex()
  },
  methods: {
    getSiteIndex () {
      let searchIndex = 'bumpertobumper_'
      if (
        process.env.NODE_ENV === 'development' ||
          process.env.NODE_ENV === 'staging'
      ) {
        searchIndex = searchIndex.concat('staging_')
      }
      searchIndex = searchIndex.concat(
        String(this.siteID),
        '_',
        this.$i18n.locale.toLowerCase()
      )
      return searchIndex
    }
  },
  directives: {
    ClickOutside
  }
}
</script>

<style lang="scss">
.ais-search-hero-container {
  margin-top: 3rem;
  position: relative;
  .ais-Stats-text {
    display: none;
  }
  .ais-input-wrapper {
    position: relative;

    .icon {
      position: absolute;
      right: 1.5rem;
      top: 27px;
    }
  }
  .ais-search-input {
    height: 70px;
    width: 100%;
    border: none;
    border-radius: 0;
    transition: all 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    padding: 0 1.5rem;
    border: 1px solid $dark-grey-b2b;
    border-radius: 3px;
    background-color: #fff;

    @include breakpoint('lg') {
    }

    /* clears the 'X' from Internet Explorer */
    &::-ms-clear,
    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

    /* clears the 'X' from Chrome */
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }
  .ais-SearchBox-submit {
    color: white;
    position: relative;
    z-index: 2;
    cursor: pointer;
  }
  &__cta-search {
    color: #fff;
    position: relative;
    z-index: 2;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .active & {
      @include breakpoint('lg') {
        color: #000;
      }
    }
  }
  .ais-reset-cta {
    position: absolute;
    top: 0;
  }
  &.active {
    .ais-search-input {
      width: 100%;
      pointer-events: auto;
      padding: 20px 40px;
      font-size: 2.1rem;

      @include breakpoint('lg') {
        // width: 39.666666665vw;
      }
    }
    &__cta-search {
      color: #fff;
    }
  }
  .ais-Highlight-highlighted {
    background-color: $red;
    color: $white;
  }
  .ais-Pagination {
    .ais-Pagination-list {
      list-style: none;
    }
    .ais-Pagination-item {
      &--previousPage {
      }
      &--nextPage {
      }
      .ais-Pagination-item {
      }
    }
  }
}
.ais-search-results-hero-wrapper {
  opacity: 0;
  pointer-events: none;
  transition: all ease-out 0.3s;
  &.algoliaOpen {
    opacity: 1;
    pointer-events: initial;
  }
}
.ais-search-results-hero {
  background-color: $white;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 3rem;
  max-height: 400px;
  overflow: auto;
  position: absolute;
  top: 110%;
  left: 0;
  width: 100%;
  border: 1px solid $grey-b2b;

  &__item {
    margin-bottom: 1rem;
    em {
      font-size: 2.1rem;
      font-style: normal;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
