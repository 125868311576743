var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ais-instant-search',{staticClass:"ais-search-hero-container",attrs:{"search-client":_vm.searchClient,"index-name":_vm.index_search}},[_c('ais-search-box',[_c('algolia-debounced-search-box')],1),_c('ais-stats',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var query = ref.query;
return _c('div',{ref:"algolia",staticClass:"ais-search-results-hero-wrapper",class:query ? 'algoliaOpen' : ''},[_c('ais-hits',{staticClass:"ais-search-results-hero row no-gutters",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return _c('div',{},[_c('ais-state-results',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var query = ref.state.query;
var hits = ref.results.hits;
return _c('h5',{},[_vm._v(" "+_vm._s(_vm.$t('generalUi.searchCount', { count: hits.length, query: query }))+" ")])}}],null,true)}),_vm._l((items),function(item){return _c('div',{key:item.objectID,staticClass:"ais-search-results-hero__item"},[_c('a',{staticClass:"text-dark",attrs:{"href":item.url}},[_c('ais-highlight',{attrs:{"attribute":"title","hit":item}})],1)])})],2)}}],null,true)})],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }