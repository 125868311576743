export const SITE_INFO_SAX = {
  id: 7,
  slugEn: 'unipromanual',
  slugFr: 'manuelunipro'
}

export default SITE_INFO_SAX

export const ALGOLIA = {
  appId: '8UIV5LO1IM',
  apiKey: '1fc7e8c2dce4a08b4dc2a519af3cc900'
}
