import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const SEND_SEND_GRID = gql`
  query SendGrid($to: String!, $html: String!, $from: String!, $subject: String!) {
    send_grid(to: $to, html: $html, from: $from, subject: $subject) {
      to
    }
  }
`
