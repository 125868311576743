import Hero from './common/Hero.vue'
import Block4Cards from './common/Block4Cards.vue'
import SectionCards from './common/SectionCards.vue'
import BlockAccordion from './common/BlockAccordion.vue'
import SectionSimpleContent from './common/SectionSimpleContent.vue'
import Loading from './common/Loading.vue'
import SearchCity from './common/SearchCity.vue'
import AdvancedSearch from './common/AdvancedSearch.vue'
import MapsFindShop from './common/MapsFindShop.vue'
import Spacer from './common/Spacer.vue'
import SectionColsCta from './common/SectionColsCta.vue'
import MapsSingleShop from './common/MapsSingleShop.vue'
import SelectShop from './common/SelectShop.vue'
import Tiles from './common/Tiles.vue'
import Breadcrumbs from './common/Breadcrumbs.vue'
import CustomNavigationBar from './common/CustomNavigationBar.vue'
import SectionTabs from './common/SectionTabs.vue'
import SectionCategoryService from './common/SectionCategoryService.vue'
import SectionContactUs from './common/SectionContactUs.vue'
import BlogNavCatgerories from './common/BlogNavCategories.vue'
import SocialShared from './common/SocialShared.vue'
import SiteMap from './common/SiteMap.vue'
import BrandFilter from './common/BrandFilter.vue'
import FlipBook from './common/FlipBook.vue'
import Timeline from './common/Timeline.vue'
import FilterRessources from './common/FilterRessources.vue'
import BlockDownload from './common/BlockDownload.vue'

export default [
  Hero,
  Block4Cards,
  BlockAccordion,
  SectionCards,
  SectionSimpleContent,
  Loading,
  SearchCity,
  AdvancedSearch,
  MapsFindShop,
  Spacer,
  SectionColsCta,
  MapsSingleShop,
  SelectShop,
  Tiles,
  Breadcrumbs,
  CustomNavigationBar,
  SectionTabs,
  SectionCategoryService,
  SectionContactUs,
  BlogNavCatgerories,
  SocialShared,
  SiteMap,
  BrandFilter,
  FlipBook,
  BlockDownload,
  Timeline,
  FilterRessources
]
