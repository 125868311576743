const development = require('../env.development.enc')
const staging = require('../env.staging.enc')
const production = require('../env.production.enc')

/**
 * Get the env key without decrypt value
 * @param {String} key
 * @param {String} nodeEnv
 * @returns {String}
 */
module.exports = (key, nodeEnv) => {
  // get the node env
  const env = (nodeEnv === undefined) ? process.env.NODE_ENV : nodeEnv
  // set default .env file to null
  let envFile = null

  // get the good file by env variable
  switch (env) {
    case 'development':
      envFile = development
      break
    case 'staging':
      envFile = staging
      break
    case 'production':
      envFile = production
      break
    default:
      break
  }

  // if the key existe on the file
  if (envFile[key]) {
    // return the value
    return envFile[key]
  }

  return ''
}
