<template>
  <div class="col">
    <form action="" @submit="checkForm()" @submit.prevent>
      <div class="wrapper">
        <div class="input-wrapper text-left" :class="{ '__error': $v.email.$error }">
          <input type="text" v-model.trim="$v.email.$model"  :placeholder="$t('generalUi.email')">
          <p class="error" v-if="!$v.email.required">{{ $t('formValidation.required') }}</p>
          <p class="error" v-if="!$v.email.email">{{ $t('formValidation.email') }}</p>
        </div>

        <button type="submit" value="" class="btn">
          <span v-if="submitStatus === 'PENDING'">{{$t('generalUi.submiting')}}</span>
          <span v-if="submitStatus === 'OK'">{{$t('generalUi.submitedSub')}}</span>
          <span v-if="submitStatus === 'NOTOK'">{{$t('generalUi.submitedError')}}</span>
          <span v-if="submitStatus === 'READY'">{{ $t('footer.newsletterBtn') }}</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { SEND_MAILCHIMP } from '../../graphql/mutations/mailChimp'

export default {
  name: 'NewsletterBlock',
  data () {
    return {
      userLang: this.$store.state.user.lang,
      userProvince: this.$store.state.user.region,
      currentPage: window.location.href,
      userShop: '',
      userStore: this.$store.state.store.selectedShop ? this.$store.state.store.selectedShop.name : '',
      email: '',
      listId: this.$store.getters.getMailChimpId(this.$i18n.locale) ? this.$store.getters.getMailChimpId(this.$i18n.locale) : 'f9fd87f937',
      submitStatus: 'READY',
      success: false,
      error: null,
      loading: false
    }
  },
  validations: {
    email: {
      required,
      email
    }
  },
  methods: {
    /**
     * Validate the form and submit
     * @returns {Void}
     */
    checkForm () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        // this.submitStatus = 'ERROR'
      } else {
        // set the status
        this.submitStatus = 'PENDING'

        // prepare data
        const msg = {
          id: this.listId,
          FNAME: '',
          LNAME: '',
          LEADTYPE: '',
          EMAIL: this.email.trim(),
          ULANG: this.userLang,
          USHOP: this.userShop,
          USTORE: this.userStore,
          UPROVINCE: this.userProvince,
          USPAGE: this.currentPage
        }

        // submit
        this.subscribe(msg)
      }
    },
    /**
     * Send request for the submission
     * @param {Object} data
     * @returns {Void}
     */
    subscribe (data) {
      // send the query
      this.$apollo.query({
        query: SEND_MAILCHIMP,
        variables: data
      }).then((response) => {
        this.submitStatus = 'OK'

        setTimeout(() => {
          this.submitStatus = 'READY'
          this.$v.$reset()
        }, 2500)
      }).catch((error) => {
        this.submitStatus = 'NOTOK'
        setTimeout(() => {
          this.submitStatus = 'READY'
        }, 2500)
        // eslint-disable-next-line
        console.log(error)
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.wrapper{
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  width: 100%;

  .btn{
    
    @include breakpoint('md') {
      position: relative;
      top: -15px;
    }
  }

  .input-wrapper{
    flex-grow: 2;
    @include breakpoint('md') {
      margin-right: 2rem;
    }
  }
}
</style>