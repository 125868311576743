<template>
  <div class="app-container">

    <Header v-if="windowWidth >= '1025'"/>
    <HeaderMobile v-else/>
    <Alert :text="alertMessage" :show="alertDisplay" :theme="alertTheme"/>
    <NewsletterModal/>

    <section class="app-body">
      <slot/>
    </section>

    <Footer/>
  </div>
</template>

<script>
import Header from '../components/layouts/Header.vue'
import HeaderMobile from '../components/layouts/HeaderMobile.vue'
import Footer from '../components/layouts/Footer.vue'
import Alert from '../components/common/Alert.vue'
import NewsletterModal from '../components/common/NewsletterModal.vue'
import SelectRegion from '../components/common/SelectRegion.vue'

export default {
  name: 'Main',
  components: {
    Header,
    HeaderMobile,
    Footer,
    Alert,
    NewsletterModal,
    SelectRegion
  },
  data () {
    return {
      alertDisplay: this.$store.getters.getAlertDisplay(this.$i18n.locale),
      alertMessage: this.$store.getters.getAlertMessage(this.$i18n.locale),
      alertTheme: this.$store.getters.getAlertTheme
    }
  },
  methods: {
    /**
     * Check the modal state for display them
     * @returns {Void}
     */
    checkModalState () {
      // If the modal haven't display
      if (!localStorage.modalNewsletterFirstLoad) {
        // display the modal after 5 seconds
        setTimeout(() => {
          this.$bvModal.show('b2b-modal-newsletter')
        }, 5000)
        // change the state of the modal
        localStorage.modalNewsletterFirstLoad = true
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.checkModalState()
    })
  }
}
</script>

<style lang="scss">
.app-body {
  p a {
    transition: all 0.3s ease;
    font-weight: bold;

    &:hover {
      color: #000;
      font-weight: bold;
    }
  }
}

.app-container {
  transition: padding 0.2s ease;
  padding-top: 45px;
  @include breakpoint('lg') {
    padding-top: 100px;
  }
}

.categorie-nav__active {
  .app-container {
    padding-top: 117px;
    @include breakpoint('lg') {
      padding-top: 180px;
    }

    @include breakpoint('xl') {
      padding-top: 181px;
    }
  }
}
</style>
