import uuidv4 from '../../utils/uuidv4'
import Common from './common'

export default {
  /**
   * Build the tile theme
   * @param {String|null} theme
   * @returns {String|null}
   */
  buildTheme (theme) {
    return (theme) || null
  },
  /**
   * Build the template (1:1 / 2:1 / 1:2)
   * @param {String|null} template
   * @returns {String}
   */
  buildTemplate (template) {
    return (template) || '1:1'
  },
  /**
   * Build the image
   * @param {Object|null} data
   * @returns {Object}
   */
  buildImg (data) {
    if (data && data.image && data.image.file) {
      return {
        imageCard: data.image.file.url,
        type: '__squarred __image-only'
      }
    }

    return {}
  },
  /**
   * Build the text
   * @param {Object|null} data
   * @param {String} lang
   * @returns {Object}
   */
  buildTxt (data, lang) {
    if (data && (data.title || data.content)) {
      return {
        titleCard: data.title,
        contentCard: Common.Content.buildContent(data.content),
        ctaCard: Common.Cta.buildCta(data, lang),
        type: '__squarred'
      }
    }

    return {}
  },
  /**
   * Builder for text and image
   * @param {Object} data
   * @param {String} lang
   * @returns {Object}
   */
  build (data, lang) {
    // Build image and set position to left
    const img = this.buildImg(data)
    let cardLeft = img
    // Build text and set position to right
    const txt = this.buildTxt(data, lang)
    let cardRight = txt

    // if possition is inversed
    if (data.alignment_image && data.alignment_image === '2') {
      cardLeft = txt
      cardRight = img
    }

    return {
      id: `${data.entity_id}_${uuidv4()}`,
      component: 'Tiles',
      props: {
        theme: this.buildTheme(data.color_theme),
        type: this.buildTemplate(data.template),
        cardLeft,
        cardRight,
        borderDisplay: data.border_display === 'true',
        bottomSpace: data.bottom_space === 'true'
      }
    }
  }
}
