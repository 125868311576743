var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section-cards",class:[
    _vm.type ? '' + _vm.type : '',
    _vm.borderDisplay == 'none' ? 'no-border' : '',
    _vm.borderDisplay == 'full' ? 'borders' : '',
    _vm.borderDisplay == 'half' ? 'half-border' : '',
    _vm.bottomSpace ? 'bottom-space' : '',
    _vm.theme ]},[(_vm.addRow)?_c('div',{staticClass:"row no-gutters justify-content-center",class:[_vm.layoutSize]},[_c('Cards',{attrs:{"titleCard":_vm.firstRow.title,"subTitleCard":_vm.firstRow.subTitleCard,"contentCard":_vm.firstRow.content,"type":((_vm.firstRow.type) + " " + ((_vm.firstRow.title && !_vm.firstRow.content) ? 'col-lg-12' : 'col-lg-8') + " pb"),"theme":_vm.firstRow.theme,"noBorder":true}})],1):_vm._e(),_c('div',{staticClass:"row no-gutters card-list",class:[_vm.type === 'is-summary' ? 'justify-content-center no-border' : '',
       _vm.alignmentColumns === 'center' ? 'justify-content-center no-border' : '' ,
       _vm.layoutSize]},_vm._l((_vm.elements),function(element,index){return _c('Cards',{key:index,attrs:{"uKey":index,"iconCard":element.icon,"date":element.date,"imageCard":element.imageCard,"titleCard":element.title,"supTitle":element.supTitle,"contentCard":element.content,"ctaCard":element.cta,"type":((element.type) + " " + _vm.type + " col-md-6 " + _vm.colsPerRow),"theme":element.theme,"videoId":element.videoId,"subTitleCard":element.subTitleCard,"hasFullBorder":element.hasFullBorder,"hasBorder":element.hasBorder,"noBorder":!_vm.borderDisplay,"textAlignment":element.textAlignment}})}),1),(_vm.ctaFullWidthData)?_c('CtaFullWidth',{attrs:{"ctaFullWidthData":_vm.ctaFullWidthData,"theme":_vm.theme}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }