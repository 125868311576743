import uuidv4 from '../../utils/uuidv4'
import Common from './common'

export default {
  /**
   * Build the sub title
   * @param {String|null} subTile
   * @returns {String|null}
   */
  buildSubTitle (subTile) {
    return (subTile) || null
  },
  /**
   * Build the elements
   * @param {Object|null} columns
   * @param {String} lang
   * @returns {Array}
   */
  buildElements (columns, lang) {
    if (columns) {
      const elements = []

      columns.forEach((element) => {
        // if is image
        if (element.image && element.image.file && element.image.file.url) {
          elements.push({
            type: '__image-only',
            image: element.image.file.url
          })
        }

        // if is text
        if (element.title && element.description) {
          elements.push({
            type: `__icon-top-right`,
            theme: element.colour_theme,
            title: element.title,
            subTitle: this.buildSubTitle(element.sub_title),
            content: Common.Content.buildContent(element.description),
            iconCard: Common.Icon.buildIcon(element.icon),
            cta: Common.Cta.buildCta(element, lang)
          })
        }
      })

      return elements
    }

    return []
  },
  /**
   * Builder for CTA block
   * @param {Object} data
   * @param {String} lang
   * @returns {Object}
   */
  build (data, lang) {
    const elements = this.buildElements(data.columns, lang)
    return {
      id: `${data.entity_id}_${uuidv4()}`,
      component: 'SectionColsCta',
      props: {
        elements,
        prefooterType: '4cols'
      }
    }
  }
}
