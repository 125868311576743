/* eslint-disable max-len */
import gmapsInit from '../components/map/utils/gmaps'
import EventBus from '../utils/events'

export default {
  data () {
    return {
      shops: this.$store.getters.getShops,
      stores: this.$store.getters.getStores,
      location: this.$store.getters.getLocationAddress,
      filterServices: [],
      zoom: 11,
      centerMap: {},
      gMapIsLoad: false
    }
  },
  methods: {
    /**
     * Event when the location change
     * @returns {Void}
     */
    locationChange () {
      this.$store.dispatch('setLocationAddress', { locationAddress: this.location })
    },
    /**
     * Get all services
     * @returns {Array}
     */
    getServices () {
      return this.$store.getters.getServices
    },
    /**
     * Find a store/shop
     * @param {Boolean} zoomToNearShop
     * @returns {Void}
     */
    async findShop (zoomToNearShop) {
      // eslint-disable-next-line max-len
      this.$store.dispatch('setLocationAddress', { locationAddress: this.location })

      // if we don't have location, stop here
      if (this.location === null || this.location.replace(/ /g, '') === '') return

      // The map is not load
      this.gMapIsLoad = false
      EventBus.$emit('gmap-is-load', { value: false })

      // Get the geocoder
      const google = await gmapsInit()
      const geocoder = new google.maps.Geocoder()

      // geocode the location
      geocoder.geocode({ address: this.location }, (results, status) => {
        // if is ok
        if (status === 'OK') {
          // if we don't have data, we stop here
          if (results.length === 0) return
          // set the zoom
          this.zoom = 0
          EventBus.$emit('gmap-set-zoom', { value: 0 })

          // center the map on the location
          this.centerMap = results[0].geometry.location
          EventBus.$emit('gmap-set-centerMap', { value: results[0].geometry.location })

          setTimeout(() => {
            // set the zoom on the location
            this.zoom = 11
            EventBus.$emit('gmap-set-zoom', { value: 11 })
            // the map is load
            this.gMapIsLoad = true
            EventBus.$emit('gmap-is-load', { value: true })
            // if we want to zoom on the near shop/store
            if (zoomToNearShop && zoomToNearShop === true) {
              // zoom to the near shop/store
              EventBus.$emit('select-near-shop', { value: results[0].geometry.location })
            }
          }, 100)
        } else {
          // if we haven't any result
          if (status === 'ZERO_RESULTS') return
          // Retry after 2sec (OVER_QUERY_LIMIT)
          setTimeout(() => {
            this.findShop()
          }, 2000)
        }
      })
    }
  },
  updated () {
    // if we have a location, find the store/shop
    if (this.location) this.findShop(true)
  },
  mounted () {
    // event when the input location change
    EventBus.$on('search-change', (params) => {
      // set the ew location
      this.location = params.value
      this.locationChange()
    })

    // watcher when the stores change (apply filter for exemple)
    this.$store.watch(
      (state, getters) => getters.getStores,
      (newValue, oldValue) => {
        this.stores = newValue
      }
    )

    // watcher when the shops change (apply filter for exemple)
    this.$store.watch(
      (state, getters) => getters.getShops,
      (newValue, oldValue) => {
        this.shops = newValue
      }
    )

    // event when the user click on the button search
    EventBus.$on('search-click-callback', () => this.findShop(true))

    setTimeout(() => {
      // if we have location, find store/shop and zoom on the shop/store
      if (this.location) this.findShop(true)
    }, 2000)
  }
}
