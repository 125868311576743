const encrypt = require('./lib/encrypt')
const decrypt = require('./lib/decrypt')
const uncrypt = require('./lib/uncrypt')
const get = require('./lib/get')
const development = require('./env.development.enc')
const staging = require('./env.staging.enc')
const production = require('./env.production.enc')

module.exports = {
  env: {
    development,
    staging,
    production
  },
  encrypt,
  decrypt,
  uncrypt,
  get
}
