<template>
  <section id="flipbook" ></section>
</template>

<script>
export default {
  name: 'FlipBook',
  props: {
    url: {
      type: String,
      default: ''
    }
  },
  mounted () {
    // create an iframe
    const ifrm = document.createElement('iframe')
    // add the flipbook url on the iframe
    ifrm.setAttribute('src', this.url)
    // add style
    ifrm.style.width = '100%'
    ifrm.style.height = '80vh'
    // inject on the page
    document.getElementById('flipbook').appendChild(ifrm)
  }
}
</script>