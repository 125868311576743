<template>
  <section class="accordion-list accordion container-fluid" role="tablist">
    <div class="row no-gutters">
      <div class="col offset-lg-1 col-lg-10">
        <article
            v-for="(section, index) in elements"
            :key="section.title"
            :id="wrapperId(convertSpaceUnderscore(section))"
            class="accordion-list__wrapper"
        >
          <div
              class="accordion-list__toggler"
              v-b-toggle="convertSpaceUnderscore(section)"
              @click.prevent
          >
            <span class="accordion-list__toggler--title">{{
                section.title
              }}</span>

            <div class="accordion-list__toggler--icon"><span class="toggle"></span></div>
          </div>
          <b-collapse
              accordion="accordion-list"
              class="accordion-list__content"
              :id="convertSpaceUnderscore(section)"
              :class="{ full: 'item' + index == selectedReadMore }"
              @click.prevent
          >
            <div v-if="section.summary" v-html="section.summary"></div>
            <div v-if="section.content">
              <div v-if="section.content" v-html="section.content"></div>
              <div
                  class="read-more"
                  @click="toggleTextLength($event, 'item' + index)"
                  :class="readMoreOpen ? 'open' : ''"
              >
                <icon-arrow/>
                <span v-if="!readMoreOpen">{{ $t('readMore') }}</span>
                <span v-else>{{ $t('readLess') }}</span>
              </div>
            </div>
          </b-collapse>
        </article>
        <div class="d-flex justify-content-end">
          <back-to-top/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BackToTop from '../common/BackToTop.vue'
import IconArrow from '../icons/IconArrow.vue'

export default {
  name: 'BlockAccordion',
  components: {
    BackToTop,
    IconArrow
  },
  data () {
    return {
      selectedReadMore: undefined,
      readMoreOpen: false,
      id: null,
      emmitted: false
    }
  },
  props: {
    elements: {
      type: Array,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    summary: {
      type: String,
      default: null
    },
    content: {
      type: String,
      default: null
    }
  },
  methods: {
    toggleTextLength (event, itemIndex) {
      if (!this.readMoreOpen) {
        this.readMoreOpen = false
        this.selectedReadMore = itemIndex
        this.readMoreOpen = true
      } else {
        this.selectedReadMore = undefined
        this.readMoreOpen = false

        setTimeout(() => {
          const el = event.target.parentElement.closest('.accordion-list').offsetTop
          window.scrollTo({
            top: el - 200,
            behavior: 'smooth'
          })
        }, 300)
      }
    },
    convertSpaceUnderscore (section) {
      return `${section.title.toLowerCase()
        .replace(/\s/g, '-')}-${this.id}`
    },
    wrapperId (id) {
      return `${id}-wrapper`
    }
  },
  mounted () {
    // eslint-disable-next-line no-underscore-dangle
    this.id = this._uid
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (isJustShown) {
        this.readMoreOpen = false
        setTimeout(() => {
          const el = document.getElementById(this.wrapperId(collapseId))
          const y = el.getBoundingClientRect().top + (document.documentElement.scrollTop - 200)
          window.scroll({
            top: y,
            behavior: 'smooth'
          })
        }, 330)
      }
    })

    this.$nextTick(() => {
      setTimeout(() => {
        if (this.$route.hash) {
          const id = this.$route.hash.substr(1)
          const el = document.querySelector(`.accordion-list__content[id*=${id}]`)
          if (el) {
            const y = el.getBoundingClientRect().top + (window.scrollY - 200)
            window.scroll({
              top: y,
              behavior: 'smooth'
            })

            // TODO: hack, remove at some point
            if (window.accordionOpened !== this.id) {
              this.$root.$emit('bv::toggle::collapse', el.id)
              window.accordionOpened = this.id
            }
          }
        }
      }, 1000)
    })
  }
}
</script>

<style lang="scss">
.accordion-list {
  padding-top: 4rem;
  padding-bottom: 4rem;

  &__wrapper {
    border: 1px solid $grey-light;
    margin-bottom: 2.3rem;
  }

  &__toggler {
    cursor: pointer;
    height: 6.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 2.7rem;

    &--title {
      cursor: pointer;
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 1em;
    }

    &--icon {
      flex: 0 0 6.8rem;
      width: 6.8rem;
      height: 6.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-left: 1px solid $grey-light;

      .toggle {
        width: 2rem;
        height: 0.2rem;
        position: relative;
        transition: .2s all cubic-bezier(.17, .67, .09, .97);
        background: $dark-grey-b2b;

        .collapsed & {
          &::after {
            transform: rotate(90deg);
            opacity: 1;
          }
        }

        .collapsed & {
          transform: rotate(90deg);
        }

        &::after {
          content: "";
          width: 2rem;
          transition: .2s all cubic-bezier(.17, .67, .09, .97);
          transition-delay: 0.1s;
          height: 0.2rem;
          position: absolute;
          opacity: 0;
          background: $dark-grey-b2b;
        }
      }

    }
  }

  &__content {
    padding: 4rem;
    background-color: $grey-light3;
    border-top: 1px solid $grey-light;
    max-height: 400px;
    overflow: hidden;
    position: relative;
    translate: max-height 0.3s ease-out;

    &.full {
      max-height: 5000px;
      padding-bottom: 8rem;
    }
  }

  .read-more {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(0deg, rgba(250, 250, 250, 1) 60%, rgba(255, 255, 255, 0) 100%);
    width: 100%;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;

    svg {
      transform: rotate(90deg);
      color: $red;
      width: 1.1rem;
      margin-right: 1rem;
      transition: all 0.2s ease-in;
    }

    &.open {
      svg {

        transform: rotate(-90deg);
      }
    }
  }
}
</style>
