import {
  GET_EN_SERVICES_CATEGORIES,
  GET_FR_SERVICES_CATEGORIES,
  GET_EN_SERVICES_CATEGORIES_PREVIEW,
  GET_FR_SERVICES_CATEGORIES_PREVIEW
} from '../graphql/queries/services'
import {
  GET_EN_PRODUCTS_CATEGORIES,
  GET_FR_PRODUCTS_CATEGORIES,
  GET_EN_PRODUCTS,
  GET_FR_PRODUCTS,
  GET_EN_PRODUCTS_PREVIEW,
  GET_FR_PRODUCTS_PREVIEW,
  GET_EN_PRODUCTS_CATEGORIES_PREVIEW,
  GET_FR_PRODUCTS_CATEGORIES_PREVIEW
} from '../graphql/queries/products'
import {
  GET_EN_BRANDS,
  GET_FR_BRANDS,
  GET_EN_BRANDS_PREVIEW,
  GET_FR_BRANDS_PREVIEW
} from '../graphql/queries/brands'
import {
  GET_EN_RESSOURCE_CATEGORIES,
  GET_FR_RESSOURCE_CATEGORIES,
  GET_EN_ARTICLES,
  GET_FR_ARTICLES,
  GET_EN_ARTICLES_PREVIEW,
  GET_FR_ARTICLES_PREVIEW,
  GET_EN_RESSOURCE_CATEGORIES_PREVIEW,
  GET_FR_RESSOURCE_CATEGORIES_PREVIEW
} from '../graphql/queries/articles'

/**
 * List of slug (en/fr)
 */
const pagesUrl = {
  services: ['services', 'services'],
  products: ['our-products', 'nos-produits'],
  product: ['products', 'produits'],
  brands: ['brands', 'marques'],
  ressources: ['resources', 'ressources'],
  articles: ['articles', 'articles'],
  procedures: ['operations-manual', 'manuel-exploitation'],
  proceduresStore: ['procedures-store', 'procedures-magasin'],
  storeDetail: ['store-details', 'magasin-detail'],
  shopDetail: ['shop-details', 'atelier-detail']
}

export default {
  /**
   * Get the good path
   * @param {String} path
   * @returns {Array}
   */
  getPath (path) {
    return pagesUrl[path]
  },
  /**
   * Test if the path is on the fullurl
   * @param {String} fullPath
   * @param {String} path
   * @param {Number} segmentIndex
   * @returns {Boolean}
   */
  is (fullPath, path, segmentIndex) {
    segmentIndex = (segmentIndex) || 2
    return (fullPath.split('/')[segmentIndex] && pagesUrl[path].includes(fullPath.split('/')[segmentIndex]))
  },
  /**
   * Test if the request get the services categories
   * @param {String} pageRequest
   * @param {String} path
   * @param {String} fullPath
   * @param {String} baseUrl
   * @param {String} lang
   * @returns {Object}
   */
  servicesCategories (pageRequest, path, fullPath, baseUrl, lang) {
    let isHack = false
    const splitUrl = fullPath.split('/')

    // if is services categories request
    if (splitUrl[2] && pagesUrl[baseUrl].includes(splitUrl[2])) {
      // is the home category
      if (splitUrl.length === 3) {
        path = '/'
      } else {
        // use last slug
        path = splitUrl[splitUrl.length - 1]
      }

      // force path to root service category if the path is empty
      path = (path === '') ? '/' : path

      // set the request defnied by the lang
      pageRequest = (lang === 'fr-CA') ? GET_FR_SERVICES_CATEGORIES : GET_EN_SERVICES_CATEGORIES

      // use hack query
      isHack = true
    }

    return {
      pageRequest,
      path,
      isHack
    }
  },
  /**
   * Test if the request get the products categories
   * @param {String} pageRequest
   * @param {String} path
   * @param {String} fullPath
   * @param {String} baseUrl
   * @param {String} lang
   * @returns {Object}
   */
  productsCategories (pageRequest, path, fullPath, baseUrl, lang) {
    let isHack = false
    const splitUrl = fullPath.split('/')
    let getProduct = false

    // if is products categories request
    if (splitUrl[2] && pagesUrl[baseUrl].includes(splitUrl[2])) {
      // is the home category
      if (splitUrl.length === 3) {
        path = '/'
      } else {
        // use last slug and get products for the page
        path = splitUrl[splitUrl.length - 1]
        getProduct = true
      }

      // set the request defnied by the lang
      pageRequest = (lang === 'fr-CA') ? GET_FR_PRODUCTS_CATEGORIES : GET_EN_PRODUCTS_CATEGORIES

      // Add products in page or not
      pageRequest = pageRequest.replace(/\$bool/g, getProduct)

      // use hack query
      isHack = true
    }

    return {
      pageRequest,
      path,
      isHack
    }
  },
  /**
   * Test if the request get the product
   * @param {String} pageRequest
   * @param {String} path
   * @param {String} fullPath
   * @param {String} baseUrl
   * @param {String} lang
   * @returns {Object}
   */
  product (pageRequest, path, fullPath, baseUrl, lang) {
    let isHack = false
    const splitUrl = fullPath.split('/')

    // if is product request
    if (splitUrl[2] && pagesUrl[baseUrl].includes(splitUrl[2])) {
      // get product slug
      path = splitUrl[splitUrl.length - 1]

      // set the request defnied by the lang
      pageRequest = (lang === 'fr-CA') ? GET_FR_PRODUCTS : GET_EN_PRODUCTS

      // use hack query
      isHack = true
    }

    return {
      pageRequest,
      path,
      isHack
    }
  },
  /**
   * Test if the request get the brands
   * @param {String} pageRequest
   * @param {String} path
   * @param {String} fullPath
   * @param {String} baseUrl
   * @param {String} lang
   * @returns {Object}
   */
  brands (pageRequest, path, fullPath, baseUrl, lang) {
    let isHack = false
    const splitUrl = fullPath.split('/')

    // if is brands request
    if (splitUrl[2] && pagesUrl[baseUrl].includes(splitUrl[2])) {
      // get brands slug
      path = splitUrl[splitUrl.length - 1]

      // set the request defnied by the lang
      pageRequest = (lang === 'fr-CA') ? GET_FR_BRANDS : GET_EN_BRANDS

      // use hack query
      isHack = true
    }

    return {
      pageRequest,
      path,
      isHack
    }
  },
  /**
   * Test if the request get the ressources categories
   * @param {String} pageRequest
   * @param {String} path
   * @param {String} fullPath
   * @param {String} baseUrl
   * @param {String} lang
   * @returns {Object}
   */
  ressourcesCategories (pageRequest, path, fullPath, baseUrl, lang) {
    let isHack = false
    const splitUrl = fullPath.split('/')

    // if is products categories request
    if (splitUrl[2] && pagesUrl[baseUrl].includes(splitUrl[2])) {
      // is the home category
      if (splitUrl.length === 3) {
        path = '/'
      } else {
        // use last slug and get brands for the page
        path = splitUrl[splitUrl.length - 1]
      }

      // set the request defnied by the lang
      pageRequest = (lang === 'fr-CA') ? GET_FR_RESSOURCE_CATEGORIES : GET_EN_RESSOURCE_CATEGORIES

      // use hack query
      isHack = true
    }

    return {
      pageRequest,
      path,
      isHack
    }
  },
  /**
   * Test if the request get the article
   * @param {String} pageRequest
   * @param {String} path
   * @param {String} fullPath
   * @param {String} baseUrl
   * @param {String} lang
   * @returns {Object}
   */
  articles (pageRequest, path, fullPath, baseUrl, lang) {
    let isHack = false
    const splitUrl = fullPath.split('/')

    // if is article request
    if (splitUrl[2] && pagesUrl[baseUrl].includes(splitUrl[2])) {
      // get article slug
      path = splitUrl[splitUrl.length - 1]

      // set the request defnied by the lang
      pageRequest = (lang === 'fr-CA') ? GET_FR_ARTICLES : GET_EN_ARTICLES

      // use hack query
      isHack = true
    }

    return {
      pageRequest,
      path,
      isHack
    }
  },
  /**
   * Test if the request get the procedure page
   * @param {String} pageRequest
   * @param {String} path
   * @param {String} fullPath
   * @param {String} baseUrl
   * @param {String} lang
   * @returns {Object}
   */
  procedures (pageRequest, path, fullPath, baseUrl, lang) {
    let isHack = false
    const splitUrl = fullPath.split('/')

    // if is procedures request
    if (splitUrl[2] && pagesUrl[baseUrl].includes(splitUrl[2])) {
      // is the home category
      if (splitUrl.length === 3) {
        path = (lang === 'fr-CA') ? pagesUrl[baseUrl][1] : pagesUrl[baseUrl][0]
      } else {
        // use last slug and get page
        path = splitUrl[splitUrl.length - 1]
      }

      if (path === '') {
        path = (lang === 'fr-CA') ? pagesUrl[baseUrl][1] : pagesUrl[baseUrl][0]
      }

      // use hack query
      isHack = true
    }

    return {
      pageRequest,
      path,
      isHack
    }
  },
  /**
   * Test if is the page shop or store detail
   * @param {String} pageRequest
   * @param {String} fullPath
   * @param {String} lang
   * @returns {Object}
   */
  detailPage (pageRequest, fullPath, lang) {
    let isHack = false
    let path = ''

    // if is store detail page
    if (fullPath.includes(pagesUrl.storeDetail[0]) || fullPath.includes(pagesUrl.storeDetail[1])) {
      path = (lang === 'fr-CA') ? pagesUrl.storeDetail[1] : pagesUrl.storeDetail[0]
      isHack = true
    }

    // if is the shop detail page
    if (fullPath.includes(pagesUrl.shopDetail[0]) || fullPath.includes(pagesUrl.shopDetail[1])) {
      path = (lang === 'fr-CA') ? pagesUrl.shopDetail[1] : pagesUrl.shopDetail[0]
      isHack = true
    }

    return {
      pageRequest,
      path,
      isHack
    }
  },
  /**
   * Build the hackQL query
   * @param {String} pageRequest
   * @param {String} path
   * @param {String} fullPath
   * @param {String} lang
   * @returns {Object}
   */
  query (pageRequest, path, fullPath, lang) {
    // test shop or store details page
    const detailPage = this.detailPage(pageRequest, fullPath, lang)
    // if is shop or store details page
    if (detailPage.isHack) {
      // return hackQL
      return detailPage
    }

    // test services categories
    const serviceHack = this.servicesCategories(pageRequest, path, fullPath, 'services', lang)
    // if is services categories
    if (serviceHack.isHack) {
      // return hackQL
      return serviceHack
    }

    // test products categories
    const productsHack = this.productsCategories(pageRequest, path, fullPath, 'products', lang)
    // if is products categories
    if (productsHack.isHack) {
      // return hackQL
      return productsHack
    }

    // test product
    const productHack = this.product(pageRequest, path, fullPath, 'product', lang)
    // if is product
    if (productHack.isHack) {
      // return hackQL
      return productHack
    }

    // test brands
    const brandsHack = this.brands(pageRequest, path, fullPath, 'brands', lang)
    // if is brands
    if (brandsHack.isHack) {
      // return hackQL
      return brandsHack
    }

    // test ressources categories
    const ressourcesCategoriesHack = this.ressourcesCategories(pageRequest, path, fullPath, 'ressources', lang)
    // if is ressources categories
    if (ressourcesCategoriesHack.isHack) {
      // return hackQL
      return ressourcesCategoriesHack
    }

    // test article
    const articlesHack = this.articles(pageRequest, path, fullPath, 'articles', lang)
    // if is article
    if (articlesHack.isHack) {
      // return hackQL
      return articlesHack
    }

    // test procedures
    const proceduresHack = this.procedures(pageRequest, path, fullPath, 'procedures', lang)
    // if is procedure
    if (proceduresHack.isHack) {
      // return hackQL
      return proceduresHack
    }

    // test procedures-store
    const proceduresStoreHack = this.procedures(pageRequest, path, fullPath, 'proceduresStore', lang)
    // if is procedures-store
    if (proceduresStoreHack.isHack) {
      // return hackQL
      return proceduresStoreHack
    }

    // by default return the default request
    return {
      pageRequest,
      path,
      isHack: false
    }
  },
  /**
   * Build the hackQL query for the preview mode
   * @param {String} pageRequest
   * @param {String} contentType
   * @param {String} lang
   * @returns {Object}
   */
  preview (pageRequest, contentType, lang) {
    // by default is not hack query
    let isHack = false

    // switch on content type
    switch (contentType) {
      // is for page brands
      case 'brand':
        // the query is hack
        isHack = true
        // change the request with the good lang
        pageRequest = (lang && lang === 'fr-CA') ? GET_FR_BRANDS_PREVIEW : GET_EN_BRANDS_PREVIEW
        break
      // is for page product
      case 'product':
        // the query is hack
        isHack = true
        // change the request with the good lang
        pageRequest = (lang && lang === 'fr-CA') ? GET_FR_PRODUCTS_PREVIEW : GET_EN_PRODUCTS_PREVIEW
        break
      // is for page article
      case 'article':
        // the query is hack
        isHack = true
        // change the request with the good lang
        pageRequest = (lang && lang === 'fr-CA') ? GET_FR_ARTICLES_PREVIEW : GET_EN_ARTICLES_PREVIEW
        break
      // is for page product category
      case 'product_category':
        // the query is hack
        isHack = true
        // change the request with the good lang
        pageRequest = (lang && lang === 'fr-CA') ? GET_FR_PRODUCTS_CATEGORIES_PREVIEW : GET_EN_PRODUCTS_CATEGORIES_PREVIEW
        // Add products
        pageRequest = pageRequest.replace(/\$bool/g, true)
        break
      // is for page service category
      case 'services_category':
        // the query is hack
        isHack = true
        // change the request with the good lang
        pageRequest = (lang && lang === 'fr-CA') ? GET_FR_SERVICES_CATEGORIES_PREVIEW : GET_EN_SERVICES_CATEGORIES_PREVIEW
        break
      // is for page article category
      case 'article_category':
        // the query is hack
        isHack = true
        // change the request with the good lang
        pageRequest = (lang && lang === 'fr-CA') ? GET_FR_RESSOURCE_CATEGORIES_PREVIEW : GET_EN_RESSOURCE_CATEGORIES_PREVIEW
        break

      default:
        isHack = false
        break
    }

    // return the query
    return {
      pageRequest,
      isHack
    }
  }
}
