import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import boxparser from './boxparser'

const BLOCKS_EMBEDDED_INLINE = 'embedded-entry-inline'

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: node => boxparser.renderHyperLink(node),
    [INLINES.ASSET_HYPERLINK]: node => boxparser.renderHyperLink(node),
    [BLOCKS.EMBEDDED_ASSET]: node => boxparser.renderAsset(node),
    [BLOCKS.EMBEDDED_ENTRY]: node => boxparser.renderBox(node.data.target),
    [BLOCKS_EMBEDDED_INLINE]: node => boxparser.renderBox(node.data.target)
  }
}

export default {
  /**
   * Build content from string or contentful object
   * @param {Object|String|null} content
   * @returns {String|null}
   */
  buildContent (content) {
    if (content && typeof content === 'string') {
      return content
    }

    return (content) ? documentToHtmlString(content, options) : null
  }
}
