/**
 * Validate the phone number
 * @param {String} phoneNumber
 * @returns {Boolean}
 */
export default function phoneValidation (phoneNumber) {
  // regex to test
  const regexValidation = [
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
    /^\+?([0-9]{1})\)?[-. ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  ]

  // by default is not validate
  let isValidate = false

  // test all regex
  regexValidation.forEach((reg) => {
    // is validate
    if (phoneNumber.match(reg)) {
      isValidate = true
    }
  })

  return isValidate
}
