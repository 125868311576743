const config = require('./config')
const development = require('../env.development.enc')
const staging = require('../env.staging.enc')
const production = require('../env.production.enc')

const CryptoJS = require('crypto-js')

/**
 * Get the env key with decrypted value
 * @param {String} key
 * @param {String} nodeEnv
 * @returns {String}
 */
module.exports = (key, nodeEnv) => {
  // get the node env
  const env = (nodeEnv === undefined) ? process.env.NODE_ENV : nodeEnv
  // set default .env file to null
  let envFile = null

  // get the good file by env variable
  switch (env) {
    case 'development':
      envFile = development
      break
    case 'staging':
      envFile = staging
      break
    case 'production':
      envFile = production
      break
    default:
      break
  }

  // if key exist on env file
  if (envFile[key]) {
    // decrypt the value
    return CryptoJS.AES.decrypt(envFile[key], config.secret[env]).toString(CryptoJS.enc.Utf8)
  }

  return ''
}
