export const GET_EN_PRODUCTS_CATEGORIES = `
    pageEn: product_categories(locale: "en-CA", slug: "$slug", products: $bool) {
      products
      components
      slug
      full_url
      entity_id
      meta_data
      child_categories
      pre_footer_components
      mount_application
      short_description
      components
      icon
      image
      promotions
      brand
    }
`

export const GET_FR_PRODUCTS_CATEGORIES = `
    pageFr: product_categories(locale: "fr-CA", slug: "$slug", products: $bool) {
      products
      components
      slug
      full_url
      entity_id
      meta_data
      child_categories
      pre_footer_components
      mount_application
      short_description
      components
      icon
      image
      promotions
      brand
    }
`

export const GET_EN_PRODUCTS = `
    pageEn: products(locale: "en-CA", slug: "$slug") {
      parent_url
      entity_id
      full_url
      slug
      mount_application
      name
      meta_data
      short_description
      image
      components
      promotions
      pre_footer_components
    }
`

export const GET_FR_PRODUCTS = `
    pageFr: products(locale: "fr-CA", slug: "$slug") {
      parent_url
      entity_id
      full_url
      slug
      mount_application
      name
      meta_data
      short_description
      image
      components
      promotions
      pre_footer_components
    }
`

export const GET_FR_PARENT = `
  product_category(locale:"fr-CA", entity_id: "$entity") {
    name
    icon
    slug
  }
`

export const GET_EN_PARENT = `
  product_category(locale:"en-CA", entity_id: "$entity") {
    name
    icon
    slug
  }
`

export const GET_EN_PRODUCTS_PREVIEW = `
    pageEn: products(locale: "en-CA", entity_id: "$entity", preview: true) {
      parent_url
      entity_id
      full_url
      slug
      mount_application
      name
      meta_data
      short_description
      image
      components
      promotions
      pre_footer_components
    }
`

export const GET_FR_PRODUCTS_PREVIEW = `
    pageFr: products(locale: "fr-CA", entity_id: "$entity", preview: true) {
      parent_url
      entity_id
      full_url
      slug
      mount_application
      name
      meta_data
      short_description
      image
      components
      promotions
      pre_footer_components
    }
`

export const GET_EN_PRODUCTS_CATEGORIES_PREVIEW = `
    pageEn: product_categories(locale: "en-CA", entity_id: "$entity", preview: true, products: $bool) {
      products
      components
      slug
      full_url
      entity_id
      meta_data
      child_categories
      pre_footer_components
      mount_application
      short_description
      components
      icon
      image
      promotions
      brand
    }
`

export const GET_FR_PRODUCTS_CATEGORIES_PREVIEW = `
    pageFr: product_categories(locale: "fr-CA", entity_id: "$entity", preview: true, products: $bool) {
      products
      components
      slug
      full_url
      entity_id
      meta_data
      child_categories
      pre_footer_components
      mount_application
      short_description
      components
      icon
      image
      promotions
      brand
    }
`
