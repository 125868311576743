export default {
  metaInfo: {
    title: 'Auto-select',
    titleTemplate: '| Auto-select',
    htmlAttrs: {
      lang: 'en'
    },
    meta: {
      description: 'Nous savons que s’il vous faut une pièce, il vous la faut tout de suite. Que vous soyez un consommateur, un professionnel du secteur de l’automobile ou un grossiste, nous comptons sur le plus vaste réseau de distribution du Canada pour vous fournir les produits dont vous avez besoin.'
    }
  },
  header: {
    lang: 'EN'
  },
  footer: {
    newsletterTitle: 'Abonnez-vous à notre infolettre',
    newsletterPlaceholder: 'E-mail',
    newsletterBtn: 'Souscrire'
  },
  pages: {
    siteRoot: {
      infobumpertobumper: 'programmebanniere'
    },
    singleShop: {
      title: 'Nos services',
      contact: 'Nous contacter',
      directions: 'Obtenir les directions',
      close: 'Fermé'
    },
    findShop: {
      title: 'Trouvez mon magasin',
      findShopLabel: 'Trouvez un atelier',
      intro: 'Notre réseau comprend plus de 4000 ateliers de réparation au Canada, y compris les marques Uni-Pro et Auto-Select, où vous pouvez toujours compter sur des techniciens hautement qualifiés et professionnels, dont la priorité est la satisfaction de la clientèle.',
      placeHolderLocation: 'Adresse / code postal',
      placeHolderName: 'Par nom',
      or: 'ou',
      btnFindShop: 'Trouvez un magasin',
      searchLabel: 'Recherche',
      btnAdvancedSearch: 'Recherche avancée',
      btnSelectShop: 'Sélectionnez cet atelier',
      btnDetail: 'Voir les détails',
      resetFilter: 'Réinitialiser le filtre',
      website: 'Site Internet'
    },
    findShopDetail: {
      select: 'Sélectionnez cet atelier',
      change: 'Retour aux résultats'
    }
  },
  errors: {
    shopNotFound: 'Désolé, ce magasin n\'a pas été trouvé!',
    pageError: 'Désolé, nous avons une erreur sur cette page!',
    pageNotFound: {
      text: 'La page que vous recherchez n\'a pas été trouvée.',
      button: 'Retour sur la home'
    },
    internal: {
      title: 'Erreur inattendue',
      text: 'Vous pouvez revenir à notre',
      link: 'Page d\'accueil'
    },
    previewNotFound: 'L\'aperçu n\'est pas activé pour le type de contenu'
  },
  generalUi: {
    all: 'Tout',
    back: 'Retour',
    download: 'Télécharger',
    myShop: 'Mon atelier',
    findStore: 'Trouvez un magasin',
    findMyShop: 'Trouver mon magasin',
    login: 'Connexion',
    copyright: 'Tous les contenus ©__year__ Uni-Sélect Inc. Tous droits réservés.<br>Uni-Pro est une marque déposée de Uni-Sélect inc.',
    learnMore: 'Apprendre encore plus',
    exclusiveBrands: 'Nos marques exclusives',
    notExclusiveBrands: 'Nos marques',
    writeToUs: 'Écrivez nous',
    send: 'Envoyer',
    submiting: 'En cours...',
    submited: 'Message envoyé ! Merci !',
    submitedSub: 'Souscription envoyée !<br>Merci !',
    submitedError: 'Une erreur s\'est produite',
    name: 'Nom',
    email: 'Email',
    phone: 'Téléphone',
    search: 'Rechercher',
    searchCount: 'Résultats de la recherche - {count} résultat(s) pour {query}',
    product: 'Produit',
    brand: 'Marque',
    categories: 'Catégories',
    hideCategories: 'Fermer les catégories',
    openCategories: 'Ouvrir les catégories',
    modalNewsletterTitle: 'Inscrivez-vous à notre infolettre !',
    searchArticle: 'Rechercher un article',
    selectRegion: 'Choisissez une région :',
    noResult: 'Désolé, aucun résultat pour cette recherche',
    noResultInRadius: 'Nous n\'avons pu trouver de résultat dans un rayon de 50 km autour de l\'adresse spécifiée.',
    goBack: 'Retour au menu'
  },
  linksLablel: {
    allproducts: 'Tout les produits'
  },
  formValidation: {
    required: 'S\'il vous plait, remplissez le champ ci-dessus',
    minLength: 'S\'il vous plait, ce champ doit contenir au minimum {number} lettres.',
    email: 'S\'il vous plait, entrez un email valide comme votre@gmail.com',
    numeric: 'S\'il vous plait, utilisez que des valeurs numériques',
    phone: 'S\'il vous plait, utilisez un numéro valide',
    fname: 'Votre nom',
    lname: 'Votre prénom',
    whoru: 'Qui êtes vous?',
    magasin: 'Magasin',
    atelier: 'Atelier',
    consommateur: 'Consommateur'
  },
  backToTop: 'Retour en haut',
  readMore: 'Lire plus',
  readLess: 'Lire moins'
}
