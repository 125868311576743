import Title from './title'
import Content from './content'
import Icon from './icon'
import Image from './image'
import Type from './type'
import Cta from './cta'
import Video from './video'

export default {
  Title,
  Content,
  Icon,
  Image,
  Type,
  Cta,
  Video
}
