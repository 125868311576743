import uuidv4 from '../../utils/uuidv4'
import Common from './common'

export default {
  /**
   * Builder for article category
   * @param {Object} data
   * @param {Object} _this
   * @returns {Object}
   */
  build (data, _this) {
    const element = {
      uKey: `${data.entity_id}_${uuidv4()}`,
      imageCard: Common.Image.buildImage(data.image),
      subTitleCard: (data.name) ? data.name : '',
      contentCard: Common.Content.buildContent(data.summary),
      ctaCard: Common.Cta.buildCta({
        block_button: [{
          button_link: `${_this.$route.path}/${data.slug}`,
          button_text: _this.$t('generalUi.learnMore')
        }]
      }, _this.$i18n.locale)
    }

    return element
  }
}
