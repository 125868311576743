<template>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" >
      <b-button variant="outline-primary" @click="selectMyShop" class="space-right" :class="[displaySelect ? '' : 'hide_btn' ]" >{{ textBtnLeft}}</b-button>
      <b-button variant="outline-primary" @click="goToFindShop" >{{ textBtnRight }}</b-button>
    </div>
  </div>
</template>

<script>
import EventBus from '../../utils/events'

export default {
  name: 'SelectShop',
  data () {
    return {
      displaySelect: true
    }
  },
  props: {
    shopId: {
      type: String,
      default: ''
    },
    textBtnRight: {
      type: String,
      default: ''
    },
    textBtnLeft: {
      type: String,
      default: ''
    }
  },
  methods: {
    /**
     * Go to page find a shop
     * @returns {Void}
     */
    goToFindShop () {
      // get the url
      let historyPath = this.$route.path.split('/')
      historyPath.pop()
      historyPath.pop()
      historyPath = historyPath.join('/')

      // go to the url
      this.$router.replace({
        path: historyPath,
        query: {}
      })
    },
    /**
     * When the user click on select shop button
     * @returns {Void}
     */
    selectMyShop () {
      // select the shop
      if (this.displaySelect) {
        const shop = this.$store.getters.getShopBySlug(this.$route.path.split('/')[this.$route.path.split('/').length - 1])
        this.$store.dispatch('setSelectedShop', { shopId: shop.id })
      }

      // go to home
      setTimeout(() => {
        this.$router.push('/')
      }, 500)
    }
  },
  mounted () {
    // when we get the event for display the button select shop
    EventBus.$on('display-select', (params) => {
      this.displaySelect = params.value
    })
  }
}
</script>

<style lang="scss" scoped>
.hide_btn {
  display: none;
}
.space-right {
  margin-bottom: 20px;

    @include breakpoint('sm'){
      margin-right: 20px;
      margin-bottom: 0;
    }
}
</style>
