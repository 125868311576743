<template>
  <div class="contact-infos row no-gutters">
    <div class="col col-lg-4">
      <GoogleMap
          name="map-single-shop"
          :zoom="16"
          :markers="getMarker()"
          :pins="getPin()"
          :fullscreenControl="false"
          :mapTypeControl="false"
          :centerMap="centerMap"
          :streetViewControl="false"
          theme="grey"
      >
        <Loading/>
      </GoogleMap>
    </div>
    <div class="col-12 col-lg-4 contact contact__wrapper order-3 order-lg-2">

      <h2 class="pb-5">{{ $t('pages.singleShop.contact') }}</h2>

      <article
          v-for="item in colContact"
          v-bind:key="item.key"
          class="contact contact__item"
      >
        <Icons class="label-size icons" category="contact" :categoryKey="`${item.key}`" :display="displayIcon(item)"/>

        <div v-if="item.isHtml && item.isHtml === true" class="label-size" v-html="item.val"></div>

        <div v-else-if="item.isArray && item.isArray === true && parseInt(item.val.length, 10) > 0"
             class="horaires-wrapper">
          <div
              v-for="obj in item.val"
              v-bind:key="obj.id"
              class="horaires"
          >
            <div class="days label-size">{{ displayDayLabel(obj) }}</div>
            <div class="hours label-size">
              <span v-if="obj.close && obj.close === true">{{ $t('pages.singleShop.close') }}</span>
              <span v-else>
                <span
              v-for="objShift in obj.shifts"
              v-bind:key="objShift.Opening"
              class="shifts"
                >
                {{ objShift.Opening }} - {{ objShift.Closing }} 
                </span>
              </span>
            </div>
          </div>

        </div>

        <div v-else-if="item.isLink && item.isLink === true" class="label-size">
          <a :href="item.val">
                  <span v-if="item.key === 'facebook_link'">
                    Facebook
                  </span>
            <span v-if="item.key === 'website_link'">
                    {{ $t('pages.findShop.website') }}
                  </span>
          </a>
        </div>

        <div v-else-if="item.isPhone && item.isPhone === true" class="label-size">
          <a :href="`tel:${item.val}`">
            <b>{{ item.val }}</b>
          </a>
        </div>

        <div v-else class="label-size">
                <span v-if="!item.isArray">
                  {{ item.val }}
                </span>
        </div>

      </article>
    </div>
    <div class="col-12 col-lg-4 contact contact__wrapper black order-2 order-lg-3">
      <h2 class="pb-5">{{ $t('pages.singleShop.title') }}</h2>
      <div v-if="this.colServices[0] && this.colServices[0].key === 'services'">
        <article
            v-for="item in this.colServices[0].val"
            v-bind:key="item.service_id"
            class="contact__item"
        >
          <Icons class="label-size icons icon-base" category="services" :categoryKey="`${item.service_id}`"
                 :useDefault="true"/>

          <div class="label-size">
            {{ displayServiceLabel(item) }}
          </div>
        </article>
      </div>
    </div>

  </div>
</template>

<script>
import GoogleMap from '../map/GoogleMap.vue'
import Loading from '../../components/common/Loading.vue'
import gmapsInit from '../map/utils/gmaps'
import shopDetails from '../../mixins/shopDetails'
import EventBus from '../../utils/events'
import Icons from '../icons/Icons.vue'

export default {
  name: 'MapsSingleShop',
  mixins: [shopDetails],
  components: {
    GoogleMap,
    Loading,
    Icons
  },
  data () {
    return {
      centerMap: {}
    }
  },
  props: {
    displaySelect: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    /**
     * Test if we display the icon
     * @param {Object} item
     * @returns {Boolean}
     */
    displayIcon (item) {
      // if an array
      if (item.isArray) {
        // if arrau is not empty return true
        return (item.isArray === true && parseInt(item.val.length, 10) > 0)
      }

      // by default return true
      return true
    },
    /**
     * Display the label in the good lang
     * @param {Object} item
     * @returns {String}
     */
    displayDayLabel (item) {
      switch (this.$i18n.locale) {
        case 'fr-CA':
          return item.label_fr
        default:
          return item.label_en
      }
    },
    /**
     * Display the label in the good lang for the services
     * @param {Object} item
     * @returns {String}
     */
    displayServiceLabel (item) {
      switch (this.$i18n.locale) {
        case 'fr-CA':
          return item.title_fr
        default:
          return item.title_en
      }
    },
    /**
     * Get the marker
     * @returns {Array}
     */
    getMarker () {
      return [this.shop]
    },
    /**
     * Get the default pin
     * @returns {Object}
     */
    getPin () {
      return {
        active: require('../../assets/pins/AutoSelect_active.png'),
        default: require('../../assets/pins/AutoSelect_default.png'),
        auto_select_active: require('../../assets/pins/AutoSelect_active.png'),
        auto_select_default: require('../../assets/pins/AutoSelect_default.png'),
        uni_pro_active: require('../../assets/pins/Uni-Pro_active.png'),
        uni_pro_default: require('../../assets/pins/Uni-Pro_default.png')
      }
    },
    /**
     * Geocode the shop for center on the shop
     * @returns {Void}
     */
    async geocodeShop () {
      const google = await gmapsInit()
      this.centerMap = new google.maps.LatLng(Number(this.shop.lat), Number(this.shop.lon))
    }
  },
  mounted () {
    // geocode the current shop
    this.geocodeShop()
    // display the button for select the shop
    EventBus.$emit('display-select', { value: this.displaySelect })
    // display the good info on the hero
    EventBus.$emit('display-in-hero', { value: this.displaySelect })
    // prepare the block map
    this.prepareDataMap()
  }
}
</script>

<style lang="scss" scoped>
.maps-bumper {
  width: 100vw;

  @include breakpoint('lg') {
    width: 100%;
  }
}

.contact-infos {
  border-bottom: 1px solid $grey-light;
}

.contact {
  &.black {
    background-color: $black;

    & > * {
      color: white
    }
  }

  &__wrapper {
    padding: 3.5rem;

    @include breakpoint('lg') {
      padding: 4rem;

      .table {
        max-width: 100%;
      }
    }

    @include breakpoint('xl') {
      padding: 7rem;

      .table {
        max-width: 100%;
      }
    }
  }

  &__item {
    margin-bottom: 2rem;
    display: flex;
  }

  .horaires-wrapper {
    flex: 1 1 auto;
    margin-bottom: 3rem;
  }

  .horaires {
    display: flex;
    padding: 1%;

    .hours {
      text-align: right;
    }

    & > div {
      flex: 0 1 50%;

      &.days {
        @include breakpoint('md') {
          flex: 0 1 20%;
        }
        @include breakpoint('lg') {
          flex: 0 1 50%;
        }
        @include breakpoint('xl') {
          flex: 0 1 35%;
        }
      }
    }
  }

  .cta-dir {
    margin-top: 3rem;
  }
}

.label-size {
  font-size: 1.8rem;
  line-height: 1em;

  @include breakpoint('lg') {
    font-size: 2.1rem;
  }

  &.icons {
    padding: 0 0;
    width: 25px;
    margin-right: 1.5rem;
  }
}
</style>
