import uuidv4 from '../../utils/uuidv4'
import Common from './common'

export default {
  /**
   * Build the arrow icon
   * @param {String|null} content
   * @returns {Boolean}
   */
  buildIcon (content) {
    return (content) ? (content === 'true') : false
  },
  /**
   * Builder for full block
   * @param {Object} data
   * @param {String} lang
   * @returns {Object}
   */
  build (data, lang) {
    // if is spacer
    const ownProps = Object.keys(data)
    if (ownProps.length === 3 && data.color_theme) {
      return {
        id: `${data.entity_id}_${uuidv4()}`,
        component: 'Spacer',
        props: {
          color: data.color_theme
        }
      }
    }

    const cta = []

    if (data.block_button) {
      data.block_button.forEach((element) => {
        const fakeData = {
          block_button: [element]
        }
        cta.push(Common.Cta.buildCta(fakeData, lang))
      })
    }
    return {
      id: `${data.entity_id}_${uuidv4()}`,
      component: 'SectionSimpleContent',
      props: {
        type: data.color_theme,
        title: Common.Title.buildTitle(data.title),
        content: Common.Content.buildContent(data.content),
        textOnly: Common.Title.buildTitle(data.textOnly),
        icon: this.buildIcon(data.has_arrow),
        cta,
        isProductPage: (data.isProductPage),
        alignment: (data.alignment) ? data.alignment : 'center',
        font_size: (data.font_size) ? data.font_size : 'large',
        borderDisplay: data.border_display === 'true',
        bottomSpace: data.bottom_space === 'true',
        anchor: (data.anchor) ? data.anchor : ''
      }
    }
  }
}
