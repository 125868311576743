<template>
  <section class="custom-navigation" :class="layout">
    <div class="no-gutters" :class="[ layout == 'list' ? 'row wrapper justify-content-center': ' row wrapper']">
      <div :class="[ layout == 'list' ? 'col-lg-8 start': 'col']">
        <nav class="custom-navigation__wrapper row no-gutters">
          <div :class="[ layout == 'list' ? 'spacer ': ' spacer spacer__red col-lg-1']"></div>
          <div :class="[ layout == 'list' ? '__nav-element ': ' __nav-element col-lg-2']"
               v-for="element in customElements"
               v-bind:key="element.key"
          >
            <b-link
                :href="element.slug"
                :class="[ layout == 'list' ? ' list': ' icon-group-link __black']"
            >
              <label>{{ element.title }}</label>

              <span class="icon-group" v-if="layout == 'default'">
            <svg v-if="displayIcon(element, 'find-shop')" class="icon icon__md" xmlns="http://www.w3.org/2000/svg"
                 width="24" height="13.478" viewBox="0 0 24 13.478">
              <g id="Shop">
                <path id="Tracé_834"
                      d="M23.9 144.391a2.135 2.135 0 0 0-2-2.221 2.58 2.58 0 0 1-2.13-1.2l-.113-.159a3.725 3.725 0 0 0-2.991-1.79h-6.319a.493.493 0 0 0-.227.045 3.779 3.779 0 0 0-1.541 1.2l-.544.589a6.029 6.029 0 0 1-1.36 1.133l-4.147-4.011a.682.682 0 0 0-.861 0l-.022.023a.686.686 0 0 0 .023.884l3.807 3.648a7.674 7.674 0 0 1-1.246.295l-2.4.317A2.2 2.2 0 0 0-.1 145.32v1.745a2.176 2.176 0 0 0 2.175 2.175h1.337a2.67 2.67 0 0 0 2.607 2.06 2.767 2.767 0 0 0 1.926-.816 2.472 2.472 0 0 0 .657-1.246h7.41a2.67 2.67 0 0 0 2.606 2.062 2.767 2.767 0 0 0 1.926-.816 2.472 2.472 0 0 0 .657-1.246h.5a2.176 2.176 0 0 0 2.175-2.175zm-6.39 3.58a1.344 1.344 0 0 1 1.11-.589 1.452 1.452 0 0 1 1.11.567 1.286 1.286 0 0 1 .227.77 1.315 1.315 0 0 1-.34.929 1.268 1.268 0 0 1-.952.408 1.364 1.364 0 0 1-1.36-1.36 1.016 1.016 0 0 1 .203-.724zm-12.577 0a1.344 1.344 0 0 1 1.11-.589 1.452 1.452 0 0 1 1.11.567 1.286 1.286 0 0 1 .227.77 1.315 1.315 0 0 1-.34.929 1.268 1.268 0 0 1-.952.408 1.364 1.364 0 0 1-1.36-1.36 1.016 1.016 0 0 1 .203-.724zm15.795-.884a2.665 2.665 0 0 0-4.328.091 2.653 2.653 0 0 0-.385.861H8.579a2.52 2.52 0 0 0-.317-.77v-.045l-.068-.068a2.75 2.75 0 0 0-2.175-1.133 2.7 2.7 0 0 0-2.219 1.155 2.653 2.653 0 0 0-.385.861H2.1a.943.943 0 0 1-.952-.952v-1.745a.973.973 0 0 1 .838-.952l2.425-.272a7.328 7.328 0 0 0 4.578-2.4l.544-.612a3.577 3.577 0 0 1 .952-.816h6.141a2.485 2.485 0 0 1 1.972 1.178l.113.181a3.812 3.812 0 0 0 3.127 1.79.981.981 0 0 1 .906.952v2.493h-.024v.227a.943.943 0 0 1-.952.952h-.589a2.52 2.52 0 0 0-.317-.771v-.181h-.136z"
                      class="cls-1" data-name="Tracé 834" transform="translate(.1 -137.825)"/>
              </g>
            </svg>
            <svg v-if="displayIcon(element, 'find-store')" data-v-146e2983="" xmlns="http://www.w3.org/2000/svg"
                 width="20" height="20" viewBox="0 0 20 20" class="icon">
              <g data-v-146e2983="" id="Store">
                <path data-v-146e2983="" id="Tracé_831"
                      d="M4.9 0v20h20V0zm18.773 1.227v4.294H6.127V1.227zM6.127 18.773V6.748h17.546v12.025z"
                      data-name="Tracé 831" transform="translate(-4.9)" class="cls-1"></path>
                <path data-v-146e2983="" id="Tracé_832"
                      d="M44.32 41.2h-1.166a.673.673 0 0 0-.654.654v7.056a.673.673 0 0 0 .654.654h1.166a.131.131 0 0 0 .123-.123v-.859a.131.131 0 0 0-.123-.123h-.348a.432.432 0 0 1-.429-.429v-5.317a.432.432 0 0 1 .429-.429h.348a.131.131 0 0 0 .123-.123V41.3c-.043-.018-.082-.1-.123-.1z"
                      data-name="Tracé 832" transform="translate(-34.811 -32.774)" class="cls-1"></path>
                <path data-v-146e2983="" id="Tracé_833"
                      d="M56.67 41.2h-1.227a.131.131 0 0 0-.123.123v.859a.131.131 0 0 0 .123.123h.409a.432.432 0 0 1 .429.429v1.084a.275.275 0 0 1-.041.184l-.593 1.186a.336.336 0 0 0-.041.184c0 .082 0 .164.041.184l.573 1.207a.336.336 0 0 1 .041.184v1.083a.432.432 0 0 1-.429.429h-.409a.131.131 0 0 0-.123.123v.859a.131.131 0 0 0 .123.123h1.227a.673.673 0 0 0 .654-.654v-2.025a.677.677 0 0 0-.082-.307l-.45-.92a.651.651 0 0 1 0-.552l.47-.941a.677.677 0 0 0 .082-.307v-2.024a.617.617 0 0 0-.654-.634z"
                      data-name="Tracé 833" transform="translate(-44.993 -32.774)" class="cls-1"></path>
              </g>
            </svg>

            <svg
                class="icon icon__sm"
                id="arrow-right"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 31.49 31.49"
            >
              <path
                  d="M21.205 5.007a1.112 1.112 0 00-1.587 0 1.12 1.12 0 000 1.571l8.047 8.047H1.111A1.106 1.106 0 000 15.737c0 .619.492 1.127 1.111 1.127h26.554l-8.047 8.032c-.429.444-.429 1.159 0 1.587a1.112 1.112 0 001.587 0l9.952-9.952a1.093 1.093 0 000-1.571l-9.952-9.953z"
                  fill="#9b9b9b"
              />
            </svg>
          </span>
            </b-link>
          </div>
        </nav>
      </div>
    </div>
  </section>
</template>

<script>
import gql from 'graphql-tag'

export default {
  name: 'CustomNavigationBar',
  data () {
    return {
      customElements: this.elements,
      navigations: this.$store.getters.getNavigation()
    }
  },
  props: {
    elements: {
      type: Array,
      default: () => []
    },
    layout: {
      type: String,
      default: 'default'
    }
  },
  methods: {
    /**
     * Display the icon on the button
     * @param {Object} element
     * @param {String} icon
     * @returns {Boolean}
     */
    displayIcon (element, icon) {
      // by default, don't display icon
      let displayIcon = false

      // loop on the navigation object
      this.navigations.forEach((nav) => {
        // if the navigation equal icon key
        if (nav.navigation_id === icon) {
          // parse navigation element
          const item = this.$JSON5.parse(nav.element)
          // if the navigation element slug is equal to current element slug
          if (item[0] && `/${(this.$i18n.locale === 'fr-CA') ? 'fr' : 'en'}/${item[0].slug}` === element.slug) {
            // display the icon
            displayIcon = true
          }
        }
      })
      return displayIcon
    },
    isEelmentInArray (elementId) {
      let Flag = false
      this.customElements.forEach((element) => {
        if (element.key === elementId) {
          Flag = true
        }
      })
      return Flag
    }
  },
  mounted () {
    // loop on all element
    this.customElements.forEach((element) => {
      if (element.entity_id && element.entity_id.replace(/ /g, '') !== '') {
        // prepare the query for get the button
        const query = gql`
          query {
            block_button: navigation(locale: "${this.$i18n.locale}", entity_id:"${element.entity_id}", site_id: "${this.$store.SITE_ID}") {
              element
            }
          }
        `
        this.$apollo.query({ query }).then((resp) => {
          const button = resp.data.block_button
          // if we have data
          if (button.element && typeof button.element === 'string') {
            const data = this.$JSON5.parse(button.element)
            // format the button
            if (data[0]) {
              let url = ''
              if (data[0].slug) {
                url = (this.$i18n.locale === 'fr-CA') ? `/fr/${data[0].slug}` : `/en/${data[0].slug}`
              } else if (data[0].block_button) {
                url = data[0].block_button.button_link
              }
              element.key = data[0].entity_id
              element.slug = url
              element.title = data[0].page_title
            }
            // format the rest of elements in navigation
            if (data.length > 1) {
              data.forEach((el) => {
                const isInArray = this.isEelmentInArray(el.entity_id)
                if (element.key !== el.entity_id && !isInArray) {
                  let url = ''
                  if (el.slug) {
                    url = (this.$i18n.locale === 'fr-CA') ? `/fr/${el.slug}` : `/en/${el.slug}`
                  } else if (el.block_button) {
                    url = el.block_button.button_link
                  }
                  this.customElements.push({
                    key: (el.entity_id) ? el.entity_id : '',
                    title: (el.page_title) ? el.page_title : '',
                    slug: url
                  })
                }
              })
            }
          }
        })
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.custom-navigation {
  border-bottom: 1px solid $grey-light;

  .icon-group-link {
    border: none;
  }

  &.default {
    .__nav-element {
      a {
        border-right: 1px solid $grey-light;
      }
    }
  }

  &.list {
    padding: 0 0 1.5rem 3.5rem;

    @include breakpoint('lg') {
      padding: 0 0 1.5rem 0;
    }

    .spacer {
      display: none;
    }

    .__nav-element {
      border-bottom: none;

      a {
        transition: none;
        border-right: 1px solid black;
        padding: 0 10px 0 0;
        margin: 0 10px 0 0;

        &:hover {
          color: black;
          font-weight: 600;
        }
      }

      &:last-child a {
        border-right: 0;
        padding-right: 0;
      }

      label {
        margin: 0;
        display: inline;
        cursor: pointer;
      }
    }
  }
}


.spacer {
  &__red {
    background-color: $red;
  }

  display: none;

  @include breakpoint('lg') {
    display: block;
  }
}
</style>
