export default {
  /**
   * Build icon
   * @param {Object|null} icon
   * @returns {String|null}
   */
  buildIcon (icon) {
    return (icon && icon.file && icon.file.html) ? icon.file.html : null
  }
}
