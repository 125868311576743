import Hero from './components/hero'
import Promotions from './components/promotions'
import FullBlock from './components/fullblock'
import BlockMultiColumns from './components/blockmulticolumns'
import BlockAccordion from './components/blockaccordion'
import Block4Cards from './components/block4cards'
import Map from './components/map'
import BlockCta from './components/cta'
import BlockTextAndImage from './components/blockTxtImg'
import Breadcrumbs from './components/breadcrumbs'
import CustomNavigation from './components/customNavigation'
import BlockTable from './components/blocktable'
import BlockSectionForm from './components/blockform'
import ArticleCategory from './components/articleCategory'
import SiteMap from './components/sitemap'
import FlipBook from './components/flipbook'
import Timeline from './components/timeline'
import BlockDownload from './components/blockdownload'
// Common
import cta from './components/common/cta'

export default {
  Hero,
  Promotions,
  FullBlock,
  BlockMultiColumns,
  BlockAccordion,
  Map,
  BlockCta,
  BlockTextAndImage,
  Block4Cards,
  Breadcrumbs,
  CustomNavigation,
  BlockTable,
  BlockSectionForm,
  ArticleCategory,
  SiteMap,
  FlipBook,
  BlockDownload,
  Common: {
    cta
  },
  Timeline
}
