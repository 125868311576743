<template>
  <section class="section-cards" :class="[
      type ? '' + type : '',
      theme ]">
    <div class="row no-gutters justify-content-center" v-if="addRow">

      <Cards
          :titleCard="firstRow.title"
          :type="`${firstRow.type} col-lg-12 text-center p4r`"
          :theme="firstRow.theme"
          :noBorder="true"
          :biggerTitle="true"
      />
    </div>
    <div class="row no-gutters card-list justify-content-center no-border">
      <div class="container-custom">
        <div class="row">
          <CardsFullClickable
            v-for="(element, index) in elements"
            :key="index"
            :uKey="index"
            :imageCard="element.imageCard"
            :titleCard="element.title"
            :ctaCard="element.cta"
            :type="`${element.type} col-md-6 col-lg-3 __squarred-with-border`"
            :theme="element.theme"
            :hasFullBorder="false"
            :hasBorder="false"
            :textAlignment="`center`"
          />
        </div>
      </div>
    </div>
    <CtaFullWidth
        v-if="ctaFullWidthData"
        :ctaFullWidthData="ctaFullWidthData"
        :theme="theme"
    />
  </section>
</template>

<script>
import CardsFullClickable from './CardsFullClickable.vue'
import CtaFullWidth from './CtaFullWidth.vue'
import Cards from './Cards.vue'

export default {
  name: 'Block4Cards',
  components: {
    CardsFullClickable,
    CtaFullWidth,
    Cards
  },
  props: {
    addRow: {
      type: Boolean,
      default: false
    },
    firstRow: {
      type: Object,
      default: null
    },
    theme: {
      type: String,
      default: 'white'
    },
    type: {
      default: 'classic'
    },
    elements: {
      type: Array,
      default: null
    },
    ctaFullWidthData: {
      type: Object,
      default: null
    }
  }
}
</script>
