import Vue from 'vue'
import Router from 'vue-router'
import MyRouter from './MyRouter.vue'
import Internal from '../components/pages/Internal.vue'
import Preview from '../components/pages/Preview.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '*',
      name: 'my-router',
      component: MyRouter
    },
    {
      path: '/:lang/500/',
      name: 'internal',
      component: Internal
    },
    {
      path: '/:lang/preview/:entity/*',
      name: 'preview',
      component: Preview
    }
  ]
})

export default router
