import uuidv4 from '../../utils/uuidv4'
import Common from './common'

export default {

  /**
   * Builder for Multi column
   * @param {Object} data
   * @param {String|null} componentName
   * @param {String} lang
   * @returns {Object}
   */
  build (data, componentName, lang) {
    const props = []

    // if we don't have component name, use the default component for multi column
    if (!componentName) {
      componentName = 'BlockAccordion'
    }

    // if we have column
    if (data.sections) {
      // create card
      data.sections.forEach((column) => {
        props.push({
          title: Common.Title.buildTitle(column.title),
          summary: Common.Content.buildContent(column.summary),
          content: Common.Content.buildContent(column.content)
        })
      })
    }

    return {
      id: `${data.entity_id}_${uuidv4()}`,
      component: componentName,
      props: {
        elements: props
      }
    }
  }
}
