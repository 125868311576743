import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const SEND_MAILCHIMP = gql`
  query mailchimpListSignup($id: String!, $FNAME: String!, $LNAME: String!, $LEADTYPE: String!, $EMAIL: String!, $ULANG: String!, $USHOP: String!, $USTORE: String!, $UPROVINCE: String!, $USPAGE: String!) {
    mailchimp_list_signup(id: $id, FNAME: $FNAME, LNAME: $LNAME, LEADTYPE: $LEADTYPE, EMAIL: $EMAIL, ULANG: $ULANG, USHOP: $USHOP, USTORE: $USTORE, UPROVINCE: $UPROVINCE, USPAGE: $USPAGE) {
      status
    }
  }
`
