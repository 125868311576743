<template>
  <section v-html="siteMapHtml" id="siteMap" ></section>
</template>

<script>
import hackQL from '../../utils/hackQL'

export default {
  name: 'SiteMap',
  data () {
    return {
      siteMapHtml: ''
    }
  },
  props: {
    elements: {
      type: Array,
      default: null
    }
  },
  methods: {
    /**
     * Create the link object
     * @param {Object} item
     * @param {Boolean} isRoot
     * @param {Number} lvl
     * @param {String} p
     * @returns {Object}
     */
    createLink (item, isRoot, lvl, p) {
      let format = null
      // if is root
      if (isRoot) {
        // create h3
        const h3 = document.createElement('h3')
        h3.innerHTML = (item.name) || ''
        format = h3
      } else if (lvl === 1) {
        // is the first level, create bold
        const b = document.createElement('b')
        b.innerHTML = (item.name) || ''
        format = b
      } else {
        // create a span
        const span = document.createElement('span')
        span.innerHTML = (item.name) || ''
        format = span
      }

      // default href
      let href = '#'

      // if we have an url
      if (item.url) {
        href = item.url
      }

      // if we add params to the url
      if (p) {
        href += `?p=${p}`
      }

      // create the link
      const a = document.createElement('a')
      a.appendChild(format)
      a.href = href
      return a
    },
    /**
     * Build the params for the link
     * @param {String} p
     * @param {Object} element
     * @param {Number} lvl
     * @returns {String|Null}
     */
    buildLinkParams (p, element, lvl) {
      // if we are in products or services categories page
      if ((hackQL.is(element.url, 'products') || hackQL.is(element.url, 'services')) && lvl > 1) {
        // if we start the tree structure (first category)
        if (lvl === 2) {
          // Force to null
          p = null
        }

        // if is the first element of the category
        if (p === null) {
          // add the entity id as params
          p = element.entity_id
        } else {
          // Split the params for get only the good params for the current level
          p = p.split('.')
          p = p.slice(0, (lvl - 2))
          p = p.join('.')
          // Add the new entity id for the current level
          p += `.${element.entity_id}`
        }
      } else {
        // we force to null (don't use params)
        p = null
      }

      return p
    },
    /**
     * Build the tree structure
     * @param {Array} array
     * @param {Boolean} getObject
     * @param {Boolean} isRoot
     * @param {Number} lvl
     * @param {String} p
     * @returns {Boolean|Object}
     */
    buildTree (array, getObject, isRoot, lvl, p) {
      // if we don't have params for the link, force to null
      p = (!p) ? null : p

      // create the ul
      const ul = document.createElement('ul')

      // loop on the data lvl
      array.forEach((element, i) => {
        // create a li
        const li = document.createElement('li')

        // format the params if is needed
        p = this.buildLinkParams(p, element, lvl)

        // add element and create a link to the page
        li.appendChild(this.createLink(element, isRoot, lvl, p))
        li.className = `sitemap_lvl_${lvl}`

        // if we have a child
        if (element.children) {
          // add next level
          li.appendChild(this.buildTree(element.children, true, false, (lvl + 1), p))
        }

        // add the li on the current ul level
        ul.appendChild(li)
      })

      // if we want the object
      if (getObject) {
        // return the ul object
        return ul
      }

      // Add the tree structure on the dom
      document.getElementById('siteMap').appendChild(ul)
      return true
    }
  },
  mounted () {
    // Build the sitemap
    this.buildTree(this.elements, false, true, 0)
  }
}
</script>

<style lang="scss" >
#siteMap {
  padding: 1%;
}

#siteMap, #siteMap ul, #siteMap li {
  position: relative;
}

#siteMap ul{
  list-style: none;
  margin: 2%;
}

.sitemap_lvl_2 > a {
  text-decoration: underline;
}

#siteMap ul li{
  margin: 1%;
}
</style>
