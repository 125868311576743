import uuidv4 from '../../utils/uuidv4'
import Common from './common'

export default {
  /**
   * Set the good content
   * @param {Object} data
   * @returns {String}
   */
  setContent (data) {
    // if is article or service category, use the summary as content
    if (data.content_type === 'article' || data.content_type === 'services_category') {
      return data.summary
    }

    // if is product category, use the short description as content
    if (data.content_type === 'product_category') {
      return data.short_description
    }

    // else use the description
    return data.description
  },
  /**
   * Set the good title
   * @param {Object} data
   * @returns {String}
   */
  setTitle (data) {
    // if is article category, service category or product category, use the name
    if (data.content_type === 'article_category' || data.content_type === 'services_category' || data.content_type === 'product_category') {
      return data.name
    }

    // else, use the title
    return data.title
  },
  /**
   * Set the icon
   * @param {Object} data
   * @returns {Object|null}
   */
  setIcon (data) {
    // if is product categoty or service category, don't use icon
    if (data.content_type === 'product_category' || data.content_type === 'services_category') {
      return null
    }

    return data.icon
  },
  /**
   * Builder for Multi column
   * @param {Object} data
   * @param {String|null} componentName
   * @param {String} lang
   * @returns {Object}
   */
  build (data, componentName, lang) {
    const props = []

    // if we don't have component name, use the default component for multi column
    if (!componentName) {
      componentName = 'SectionCards'
    }

    // check if is a multi column spacer
    const ownProps = Object.keys(data)
    if (ownProps.length === 3 && data.color_theme && !data.columns) {
      return {
        id: `${data.entity_id}_${uuidv4()}`,
        component: 'Spacer',
        props: {
          color: data.color_theme
        }
      }
    }

    let addRow = false
    let firstRow = {}

    // if we have a title or content on multi column (root)
    if (data.title) {
      addRow = true
      firstRow = {
        title: data.title,
        type: (data.type) ? 'col-lg-8' : '',
        content: Common.Content.buildContent(data.content),
        theme: (data.color_theme) ? data.color_theme : ''
      }
    } else if (data.content) {
      addRow = true
      firstRow = {
        type: (data.type) ? 'col-lg-8' : '',
        content: Common.Content.buildContent(data.content),
        theme: (data.color_theme) ? data.color_theme : ''
      }
    }

    // define the layout (number of column), default 1
    const layout = (data.layout) ? data.layout : 0

    // if we have column
    if (data.columns) {
      // create card
      data.columns.forEach((column) => {
        if (layout === 0) {
          column.noBorder = true
        }
        props.push({
          title: Common.Title.buildTitle(this.setTitle(column)),
          supTitle: (column.supTitle) ? column.supTitle : Common.Title.buildSupTitle(column),
          date: (column.date) ? column.date : '',
          content: Common.Content.buildContent(this.setContent(column)),
          icon: Common.Icon.buildIcon(this.setIcon(column)),
          imageCard: (Common.Image.buildImage(column.image))
            ? Common.Image.buildImage(column.image)
            : Common.Image.buildImage(column.background_image),
          type: `${Common.Type.buildType(column)} ${(data.type) ? 'col-lg-8 col-xl-6 ' : ''}`,
          theme: (column.colour_theme) ? column.colour_theme : null,
          subTitleCard: (column.sub_title) ? column.sub_title : null,
          cta: Common.Cta.buildCta(column, lang),
          videoId: Common.Video.buildVideo(column.video__id),
          hasFullBorder: (column.hasFullBorder) ? column.hasFullBorder : false,
          hasBorder: (column.hasBorder) ? column.hasBorder : false,
          noBorder: (column.noBorder) || (data.type) ? column.noBorder : false,
          textAlignment: Common.Type.buildAlignment(column.alignment)
        })
      })
    }
    return {
      id: `${data.entity_id}_${uuidv4()}`,
      component: componentName,
      props: {
        addRow,
        firstRow,
        theme: (data.color_theme) ? data.color_theme : 'white',
        elements: props,
        type: (data.type) ? data.type : '',
        ctaFullWidthData: Common.Cta.buildCta(data, lang),
        prefooterType: '4cols',
        layout,
        alignmentColumns: (data.alignment_columns) ? data.alignment_columns : 'start',
        layoutSize: (data.alignment_columns) ? data.layout_size : 'full',
        borderDisplay: data.border_display,
        bottomSpace: data.bottom_space === 'true'
      }
    }
  }
}
