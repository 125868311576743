export default {
  /**
   * Build the imgage
   * @param {Object|null} image
   * @returns {String|null}
   */
  buildImage (image) {
    return (image && image.file && image.file.url) ? image.file.url : null
  }
}
