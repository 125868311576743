import uuidv4 from '../../utils/uuidv4'

export default {
  /**
   * Builder for custom navigation
   * @param {Object} data
   * @param {Object} _this
   * @returns {Object}
   */
  build (data, _this) {
    const elements = []
    // default component to use
    let component = 'CustomNavigationBar'
    if (data.navigation) {
      // if is the social shared component
      if (data.navigation.length === 1 && data.navigation[0].navigation_id === 'social_shared') {
        component = 'SocialShared'
      } else if (data.navigation.length === 1 && data.navigation[0].navigation_id === 'filter_ressources') {
        // if is the filter for ressource component
        component = 'FilterRessources'
      } else {
        // Build the default custom navigation
        data.navigation.forEach((nav) => {
          if (nav.element && nav.element[0]) {
            const ownProps = Object.keys(nav.element[0])
            // if we get only entity ID for build button
            if (ownProps.length === 1 && nav.element[0].entity_id) {
              elements.push({
                entity_id: nav.entity_id,
                key: (nav.element[0].entity_id) ? nav.element[0].entity_id : '',
                title: (nav.element[0].page_title) ? nav.element[0].page_title : '',
                slug: (_this.$i18n.locale === 'fr-CA') ? `/fr/${nav.element[0].slug}` : `/en/${nav.element[0].slug}`
              })
            } else {
              elements.push({
                key: (nav.element[0].entity_id) ? nav.element[0].entity_id : '',
                title: (nav.element[0].page_title) ? nav.element[0].page_title : '',
                slug: (_this.$i18n.locale === 'fr-CA') ? `/fr/${nav.element[0].slug}` : `/en/${nav.element[0].slug}`
              })
            }
          }
        })
      }
    }

    return {
      id: `${data.entity_id}_spacer_${uuidv4()}`,
      component,
      props: {
        elements,
        layout: data.layout ? data.layout : 'default'
      }
    }
  }
}
