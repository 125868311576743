import uuidv4 from '../../utils/uuidv4'
import Common from './common'

export default {
  /**
   * Builder for the timeline
   * @param {Object} data
   * @param {String} lang
   * @returns {Object}
   */
  build (data, lang) {
    const props = []
    if (data.components) {
      data.components.forEach((component) => {
        props.push({
          title: Common.Title.buildTitle(component.title),
          content: Common.Content.buildContent(component.description),
          subTitleCard: (component.sub_title) ? component.sub_title : null,
          cta: Common.Cta.buildCta(component, lang),
          textAlignment: Common.Type.buildAlignment(component.alignment)
        })
      })
    }

    return {
      id: `${data.entity_id}_${uuidv4()}`,
      component: 'Timeline',
      props: {
        elements: props
      }
    }
  }
}
