module.exports = {
  VUE_APP_ENC: 'U2FsdGVkX19wyjSWNGg1XpaOW5a9aIIx8QDzzQuzUyA=',
  VUE_APP_API_HOST: 'U2FsdGVkX1/Oa9vs09wQ9idvb5JBis+NUEXVdjX4/hscdRla82PRHuwaOulqLoOt',
  VUE_APP_GOOGLE_MAP_API_KEY: 'U2FsdGVkX1/FzZnfnGV8JB4XuM0A55EnDHftMF3tkjUoerxLWd97RUnl7PS6ayoAKaZ93exFy/fn9bRqv9iy/Q==',
  VUE_APP_GEOIP_API_KEY: 'U2FsdGVkX1+SIMyGtICbEraEkdrRF7qrtFLu0DEzQB8=',
  VUE_APP_S3D_CLOUDFRONT_ID: 'U2FsdGVkX19eXBIxQ0ELrWMbkcqooJP8JvHoq1nkI3w=',
  VUE_APP_S3D_BUCKET: '',
  VUE_APP_SENDGRID_API_KEY: 'U2FsdGVkX1+dEUOswq0t2SoK/2q2KKuAf2FJaGHWbuNChmf/NOYeidTIuMBNmg/JJ6ptNeHXypz/wN/gnk0N9FbtTDVhiFCnJav6WsfcY4S3SlIEVWSD8al2Q6vdvUgm',
  VUE_APP_MAILCHIMP_API_KEY: 'U2FsdGVkX1+QRhGOY50HYj11Cy1fkzl5dNAGBo3Byj7dbdG6zJ+jj4MrIJxlqbmL8FQQO3t51eO+5NEXIkIFAA==',
  VUE_APP_GRAPHQL_AUTH: 'U2FsdGVkX19TYgPAW8TXxmqyTKdybNPq4I1XbrkxA3eqrB92PtTQdVZP19zc/Kep'
}
