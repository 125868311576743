<template>
  <div>
    <b-modal
      :id="`b2b-modal-pdfviewer-${this.randomId}`"
      size="lg"
      centered
      scrollable
      :hide-footer="true"
    >
        <vue-pdf-app :pdf="pdfSrc"></vue-pdf-app>
    </b-modal>
  </div>
</template>

<script>
import VuePdfApp from 'vue-pdf-app'
import 'vue-pdf-app/dist/icons/main.css'

export default {
  name: 'ModalPdf',
  components: {
    VuePdfApp
  },
  data () {
    return {
    }
  },
  props: {
    randomId: {
      defaut: ''
    },
    pdfSrc: {
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
#vuePdfApp {
  min-height: 80vh;
}
.wrapper {
  .btn {
    @include breakpoint("md") {
    }
  }

  .input-wrapper {

    @include breakpoint("md") {

    }
  }
}
::v-deep .modal-content{

  // padding: 3.5rem;

  @include breakpoint("mdpi") {
    // padding: 8rem 8rem 6rem;
  }
  // .modal-header {
  //   border: 0;
  //   padding: 0;
  //   margin-bottom: 1.5rem;

  //     @include breakpoint("mdpi") {
  //       margin-bottom: 3rem;
  //     }
  // }

  .modal-body{
    padding: 0;
  }

  // .close{
  //   text-indent: -5000px;
  //   background-image: url('~@/assets/icons/cross.svg');
  //   background-repeat: no-repeat;
  //   background-position: center;

  //   position: absolute;
  //   top: 15px;
  //   right: 25px;

  //   @include breakpoint("mdpi") {
  //     top: 30px;
  //     right: 40px;
  //   }
  // }

  // .btn{
  //   margin-top: 2rem;
  // }
}
</style>
