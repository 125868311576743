<template>
  <div class="row no-gutters" >
    <div 
      class="col-12 spacer"
      :class="color"
      v-bind:style="{ height: height }"
    >
    </div>
  </div>
</template>

<script>
export default {
  name: 'Spacer',
  props: {
    height: {
      type: String,
      default: '40px'
    },
    color: {
      type: String,
      default: '#EFEFEF'
    }
  }
}
</script>

<style lang="scss" scoped>
.black {
  color: $white;
  background-color: $black;
}
.red{
  color: $white;
  background-color: $red;
}
.grey{
  color: $black;
  background-color: $grey-light;
}
// block 5 spacer
.spacer {
  opacity: 1;
}
</style>
