<template>
  <section
      class="tiles"
  >
    <div class="tiles__wrapper row no-gutters align-stretch"
         :class="[
            borderDisplay ? '__has-border' : 'no-border',
            bottomSpace ? 'bottom-space' : '',
        ]">
      <div
          class="col-lg"
          :class="[
            type == '1:1' ? 'col-lg-6' : '',
            type == '2:1' ? 'col-lg-8' : '',
            type == '1:2' ? 'col-lg-4' : '',
        ]"
      >
        <div class="row no-gutters">
          <Cards
              v-bind="cardLeft"
              :theme="theme"
              :noBorder="true"
          />
        </div>
      </div>

      <div
          class="col-lg __has-border"
      >
        <div class="row no-gutters">
          <Cards
              v-bind="cardRight"
              :theme="theme"
              :noBorder="true"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Cards from './Cards.vue'

export default {
  name: 'Tiles',
  components: {
    Cards
  },
  props: {
    theme: {
      default: 'black'
    },
    type: {
      default: '1:1'
    },
    cardLeft: {
      default: null
    },
    cardRight: {
      default: null
    },
    borderDisplay: {
      type: Boolean,
      default: false
    },
    bottomSpace: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.tiles {

  &__renderblock {
    margin-bottom: 2rem;
    img {
      margin-bottom:2rem;
      width: 100%;

      @include breakpoint("lg") {
        padding-right: 4rem;
      }
    }
  }

  &__wrapper::v-deep {
    .row {
      height: 100%;
    }

    .__image-only {
      figure {
        min-height: 25rem;
      }
    }
  }

  .tiles__wrapper.__has-border {
    border-bottom: 1px solid $grey-light;    
  }

  .tiles__wrapper.__has-border {
    .__image-only{
      border-right: 1px solid $grey-light;
    }

  }
  .bottom-space {
    margin-bottom: 4rem;

    @include breakpoint("lg") {
      margin-bottom: 8rem;
    }

    &.__has-border {
      border-top: 1px solid $grey-light;
    }
  }
}
</style>
