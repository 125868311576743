<template>
  <div class="video-wrapper">
    <iframe :src="`https://player.vimeo.com/video/${videoId}?background=1`" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
  </div>
</template>

<script>
export default {
  name: 'BackgroundVideo',
  props: {
    videoId: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.video-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  overflow: hidden;

  @include o-bkg-overlay(#000000, 0.5);
}
.video-wrapper iframe {
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */  
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>