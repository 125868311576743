<template>
  <section class="section-tabs" :class="type ? '' + type : '' ">
    <div class="row no-gutters" v-for="(line, i) in elements" :key="i" :class="customId">
      <Cards
          v-for="(element, index) in line"
          :key="index"
          :uKey="index"
          :iconCard="element.icon"
          :imageCard="element.imageCard"
          :titleCard="element.title"
          :contentCard="element.content"
          :ctaCard="element.cta"
          :type="`${element.type} col-5ths col-6`"
          :theme="element.theme"
          :videoId="element.videoId"
          :subTitleCard="element.subTitleCard"
          :hasFullBorder="element.hasFullBorder"
          :isCategoryTile="true"
          :isPromotion="element.isPromotion"
      />
    </div>
  </section>
</template>

<script>
import Cards from './Cards.vue'
import EventBus from '../../utils/events'

export default {
  name: 'SectionTabs',
  components: {
    Cards
  },
  props: {
    type: {
      type: String,
      default: 'classic'
    },
    theme: {
      type: String,
      default: 'white'
    },
    elements: {
      type: Array,
      default: null
    },
    customId: {
      type: String,
      default: 'tab_product'
    }
  },
  mounted () {
    // When we switch between product/brand filter
    EventBus.$on('active-brand-in-product', (params) => {
      // display the good tabs
      document.getElementsByClassName(params.value.class)
        .forEach((elmnt) => {
          elmnt.style.display = params.value.display
        })
    })

    // if the current tab is not product
    if (this.customId !== 'tab_product') {
      // hide the tab
      document.getElementsByClassName(this.customId)
        .forEach((elmnt) => {
          elmnt.style.display = 'none'
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.section-tabs {
  border-left: 1px solid $grey-light;
  margin-bottom: -1px;
  border-right: 1px solid $grey-light;

  @include breakpoint('lg') {
    border-right: 0;
  }

  &.__centered {
    text-align: center;
  }
}

.hide_tab {
  display: none;
}
</style>
