export const GET_EN_BRANDS = `
    pageEn: brands(locale: "en-CA", slug: "$slug") {
      entity_id
      products
      name
      slug
      full_url
      mount_application
      meta_data
      short_description
      featured
      components
      image
      pre_footer_components
    }
`

export const GET_FR_BRANDS = `
    pageFr: brands(locale: "fr-CA", slug: "$slug") {
      entity_id
      products
      name
      slug
      full_url
      mount_application
      meta_data
      short_description
      featured
      components
      image
      pre_footer_components
    }
`

export const GET_EN_BRANDS_PREVIEW = `
    pageEn: brands(locale: "en-CA", entity_id: "$entity", preview: true) {
      entity_id
      products
      name
      slug
      full_url
      mount_application
      meta_data
      short_description
      featured
      components
      image
      pre_footer_components
    }
`

export const GET_FR_BRANDS_PREVIEW = `
    pageFr: brands(locale: "fr-CA", entity_id: "$entity", preview: true) {
      entity_id
      products
      name
      slug
      full_url
      mount_application
      meta_data
      short_description
      featured
      components
      image
      pre_footer_components
    }
`
