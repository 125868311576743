<template>
  <div class="row shop-border no-gutters min-map-height" >
    <div class="col-sm-12 col-lg-5 mh-800-px scroll min-map-height" id="shop-list" >
      <div
        class="shop-wrapper row shop-border no-gutters"
        v-for="shop in markers"
        :key="shop.id"
        :id="`shop-card-${shop.id}`"
        :class="{ 'selected-shop': (selectedShop && shop.id === selectedShop.id)}"
      >
        <div class="col-12 col-sm-8 col-xl-8">
          <h3 class="cursor-pointer mb-5" @click="zoomToShop(shop)">{{ shop.name_for_website }}</h3>
          <address class="cursor-pointer card-text-size" @click="zoomToShop(shop)">
            {{shop.address}} <br>
            {{shop.city}}, {{shop.postal_code}} <br>
          </address>
          <a class="font-weight-bold card-text-size" :href="`tel:${shop.phone}`">{{ shop.phone }}</a>
        </div>
        <div class="col col-sm-4 col-xl-auto cta-wrapper justify-content-end">
          <div>
              <b-link @click="goTo(shop)" class="btn btn__smaller btn__inverted d-md-block" >
                {{ $t('pages.findShop.btnDetail') }}
              </b-link>
              <b-button @click="selectMyShop(shop.id)" class="btn btn__smaller " :class="[displaySelect ? '' : 'hide_btn' ]" >
                {{ setCtaLabel }}
              </b-button>
          </div>
        </div>
      </div>

      <div
          v-if="!markers || markers.length === 0"
          class="shop-no-result">
        <h3>{{ $t('generalUi.noResult') }}</h3>
        <p v-if="this.searchType === 'searchByAddress'">
          {{ $t('generalUi.noResultInRadius') }}
        </p>
      </div>
    </div>
    <div class="col-sm-12 col-lg-7 col-xl-7 p-0">
      <GoogleMap
        name="map-find-shop"
        :zoom="zoomToMap"
        :fitToBounds="true"
        :hiddenMarkers="hiddenMarkers"
        :markers="markers"
        :pins="getPins()"
        :fullscreenControl="false"
        :mapTypeControl="false"
        :markerEventClick="selectShop"
        :centerMap="localCenterMap"
        :controlLoader="true"
        mapHeight="800px"
        theme="grey"
        :streetViewControl="false"
        :forceLoader="true"
        :addCustomControl="addCustomControl"
        :lat="getNearestShopLat"
        :lon="getNearestShopLon"
      >
        <Loading />
      </GoogleMap>
    </div>
  </div>
</template>

<script>
import GoogleMap from '../map/GoogleMap.vue'
import Loading from '../../components/common/Loading.vue'
import gmapsInit from '../map/utils/gmaps'
import EventBus from '../../utils/events'

export default {
  name: 'MapsFindShop',
  components: {
    GoogleMap,
    Loading
  },
  data () {
    return {
      hiddenMarkers: [],
      markers: [],
      geolocationError: this.$store.getters.getGeolocationError,
      selectedShop: null,
      zoomToMap: 11,
      gMapIsLoad: false,
      localCenterMap: {},
      myShop: this.$store.getters.getSelectedShop,
      myPosition: this.$store.getters.getMyPosition,
      lang: this.$store.getters.getLang,
      markersIsOrder: false,
      currentUserPosition: null,
      centerPosition: this.$store.getters.getMyPosition,
      searchType: null
    }
  },
  props: {
    servicesList: {
      type: Array,
      default: () => []
    },
    shops: {
      type: Array,
      default: () => []
    },
    gMapLoader: {
      type: Boolean,
      default: false
    },
    centerMap: {
      type: Object,
      default: {}
    },
    zoom: {
      type: Number,
      default: 15
    },
    location: {
      type: String,
      default: ''
    },
    filterServices: {
      type: Array,
      default: () => []
    },
    childUrl: {
      type: Array,
      default: null
    },
    displaySelect: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    /**
     * Watcher when google map is load
     * @returns {Void}
     */
    gMapLoader: {
      handler () {
        this.gMapIsLoad = this.gMapLoader
      },
      deep: true
    },
    /**
     * Watcher when shops/stores change
     * @returns {Void}
     */
    shops: {
      handler () {
        // if marker is empty
        if (this.markers.length === 0) {
          // set the markers
          this.markers = this.shops
          // order the marker by location
          this.orderMarkerByLocation(this.centerPosition)
        }

        // if we apply filter
        if (this.filterServices.length > 0) {
          // apply the filter on the current selected services
          this.applyFilter(this.filterServices)
        }
      },
      deep: true
    },
    /**
     * Watcher for the map zoom
     * @returns {Void}
     */
    zoom: {
      handler () {
        this.zoomToMap = this.zoom
      }
    },
    /**
     * Watcher when the map is centered
     * @returns {Void}
     */
    centerMap: {
      handler () {
        this.localCenterMap = this.centerMap
      },
      deep: true
    },
    /**
     * Watcher when services filter is apply
     * @returns {Void}
     */
    filterServices: {
      handler (newValue) {
        // Apply the filter services
        this.applyFilter(newValue)
      },
      deep: true
    }
  },
  computed: {
    getNearestShopLon () {
      return Number([this.shops[0].lon])
    },
    getNearestShopLat () {
      return Number([this.shops[0].lat])
    },
    setCtaLabel () {
      const getSlug = this.$route.params.pathMatch

      if (getSlug.includes('shop') || getSlug.includes('magasin')) {
        return this.$t('pages.findShopDetail.select')
      }

      return this.$t('pages.findShop.btnSelectShop')
    }
  },
  methods: {
    /**
     * Order marker by location (near shop to far shop)
     * @param {Object} position
     * @returns {Void}
     */
    async orderMarkerByLocation (position) {
      // if no user position is set, stop here
      // if (!this.currentUserPosition) return

      const google = await gmapsInit()
      const myPosition = new google.maps.LatLng(position.latitude, position.longitude)
      const markerOrder = []

      // loop on all markers
      this.markers.forEach((item, key) => {
        // add distance on marker
        const shopPosition = new google.maps.LatLng(item.lat, item.lon)
        const getDistance = this.calcDistance(google, myPosition, shopPosition)
        item.distance = parseFloat(getDistance, 10)
        markerOrder.push(item)
      })

      // order the marker position by distance
      markerOrder.sort((a, b) => ((a.distance > b.distance) ? 1 : -1))
      // set the new order
      this.markers = markerOrder
    },
    /**
     * Find markers on map through geocode
     * @param {String} address
     * @returns {Void}
     */
    async findShopByLocation (address) {
      const google = await gmapsInit()
      const geocoder = new google.maps.Geocoder()

      geocoder.geocode({
        address: `${address} city`,
        componentRestrictions: {
          country: 'CA'
        }
      }, this.sortGeocodeFilteredMarkers(google))
    },
    /**
     * Redirect to the shop/store detail page
     * @param {Number} shop
     * @returns {Void}
     */
    goTo (shop) {
      let toUrl = null
      // get the url by current lang
      if (this.childUrl[0][this.lang]) {
        toUrl = this.childUrl[0][this.lang]
      }

      if (this.childUrl[1][this.lang]) {
        toUrl = this.childUrl[1][this.lang]
      }

      // if we have an url, redirect to the shop/store detail page
      if (toUrl) {
        this.$root.$router.push({
          path: `${toUrl}/${shop.slug}`
        })
      }
    },
    /**
     * Add custom controll on the map
     * @param {Object} map
     * @returns {Void}
     */
    async addCustomControl (map) {
      const google = await gmapsInit()
      // create the container
      const defaultStoreControl = document.createElement('div')
      defaultStoreControl.style.backgroundColor = '#FFFFFF'
      defaultStoreControl.style.width = '40px'
      defaultStoreControl.style.height = '40px'
      defaultStoreControl.style.marginRight = '10px'
      defaultStoreControl.style.cursor = 'pointer'
      defaultStoreControl.style.boxShadow = 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px'
      // create the content
      const targetImg = document.createElement('img')
      targetImg.src = require('../../assets/icons/target.svg')
      targetImg.style.width = '80%'
      targetImg.style.marginTop = '8%'
      targetImg.style.marginLeft = '9%'

      // add content to container
      defaultStoreControl.appendChild(targetImg)

      // add event client on the container
      defaultStoreControl.addEventListener('click', () => {
        if (this.displaySelect === true) {
          this.myShop = this.$store.getters.getSelectedShop
        }

        this.selectShop({ item: this.myShop })
      })

      // Add new controll to the map
      map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(defaultStoreControl)
    },
    /**
     * Apply the filter services on marker
     * @param {Array} newValue
     * @returns {Void}
     */
    applyFilter (newValue) {
      // if the filter is empty
      if (newValue.length === 0) {
        // set all shop/store as marker
        this.markers = this.shops
        // pin the selected shop
        this.pinMyShop(this.selectedShop)
        // order the markers
        this.orderMarkerByLocation(this.centerPosition)
      } else {
        // by default, no marker
        const filter = []

        // loop on all shop/store
        this.shops.forEach((item) => {
          // get the store/shop service list
          const shopServices = []
          item.services.forEach((service) => {
            shopServices.push(service.service_id)
          })

          // if the service is on the list
          if (newValue.every(elem => shopServices.indexOf(elem) > -1)) {
            // if not is the selected shop, remove the icon active and center
            if (this.selectedShop.id !== item.id) {
              item.center = false
              item.active = false
            }
            // add the shop/store to the new list
            filter.push(item)
          }
        })

        // update the marker with the new list of markers
        this.markers = filter
        // Order the markers
        this.orderMarkerByLocation(this.centerPosition)
      }
    },
    /**
     * Get the default pin active/default
     * @returns {Object}
     */
    getPins () {
      return {
        active: require('../../assets/pins/AutoSelect_active.png'),
        default: require('../../assets/pins/AutoSelect_default.png'),
        auto_select_active: require('../../assets/pins/AutoSelect_active.png'),
        auto_select_default: require('../../assets/pins/AutoSelect_default.png')
      }
    },
    /**
     * Calcul distance between two point
     * @param {Object} google
     * @param {Object} myPlace
     * @param {Object} shopPlace
     * @returns {Float}
     */
    calcDistance (google, myPlace, shopPlace) {
      return (google.maps.geometry.spherical.computeDistanceBetween(myPlace, shopPlace) / 1000)
        .toFixed(2)
    },
    /**
     * Select the shop/store
     * @param {Number} shopId
     * @returns {Void}
     */
    selectMyShop (shopId) {
      this.$store.dispatch('setSelectedShop', { shopId })
    },
    /**
     * Select my default shop
     * @param {Object} position
     * @param {Boolean} saveMyShop
     * @returns {Void}
     */
    async selectMyDefaultShop (position, saveMyShop) {
      if (saveMyShop === undefined) {
        saveMyShop = true
      }
      const google = await gmapsInit()
      const geocoder = new google.maps.Geocoder()
      const myPosition = new google.maps.LatLng(position.latitude, position.longitude)

      geocoder.geocode({
        location: myPosition
      }, this.sortGeocodeFilteredMarkers(google))
    },
    /**
     * Filters and order the results from the Google geocode API
     * @param {Object} google
     * @returns {Void}
     */
    sortGeocodeFilteredMarkers (google) {
      return (results, status) => {
        if (status === 'OK') {
          this.markers = []
          if (results.length === 0) {
            return
          }

          const geocodePosition = results[0].geometry.location
          const markerOrder = []
          // the maximum distance to search in meters
          const searchDistance = 50 * 1000

          this.markers = []
          this.shops.forEach((shop) => {
            const shopPosition = new google.maps.LatLng(shop.lat, shop.lon)
            const shopDistance = google.maps.geometry.spherical.computeDistanceBetween(
              geocodePosition,
              shopPosition
            )

            if (shopDistance <= searchDistance) {
              shop.active = false
              shop.center = false
              shop.distance = parseFloat(shopDistance, 10)
              markerOrder.push(shop)
            }
          })

          // order the marker position by distance
          markerOrder.sort((a, b) => ((a.distance > b.distance) ? 1 : -1))
          // set the new order
          this.markers = markerOrder
          this.hiddenMarkers = [{
            position: geocodePosition
          }]

          this.selectShop({ item: this.markers[0], noScroll: true })
        } else {
          this.markers = []
        }
      }
    },
    /**
     * Set the default used location (by IP or navigator)
     * @param {Object} position
     * @param {Boolean} save
     * @returns {Void}
     */
    useDefaultGeoLoc (position, save) {
      // check navigator geoloc
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((userLocation) => {
          // update user position
          this.$store.dispatch('updateUserPosition', { position: userLocation.coords })
          // use navigator geoloc
          this.selectMyDefaultShop(userLocation.coords, save)
          this.currentUserPosition = userLocation.coords
        }, () => {
          // use IP geoloc
          this.selectMyDefaultShop(position, save)
          this.currentUserPosition = position
        })
      } else {
        // use IP geoloc
        this.selectMyDefaultShop(position, save)
        this.currentUserPosition = position
      }
    },
    /**
     * Pin the shop on the map
     * @param {Object} shop
     * @returns {Void}
     */
    pinMyShop (shop) {
      // loop on all markers
      this.markers.forEach((marker, key) => {
        // unselect and unactive all marker
        this.markers[key].active = false
        this.markers[key].center = false
        // if the marker is the shop
        if (shop !== undefined) {
          if (marker.id === shop.id) {
            // select and center on marker
            this.markers[key].active = true
            this.markers[key].center = true
          }
        }
      })
    },
    /**
     * Zoom to the shop
     * @param {Object} shop
     * @returns {Void}
     */
    zoomToShop (shop) {
      // set the selected shop
      this.selectedShop = shop
      this.zoomToMap = 0

      // pin the shop/store on the map
      this.pinMyShop(shop)
      if (shop === undefined) {
        setTimeout(() => {
          // set the new zoom and display the map
          this.zoomToMap = 9
          this.gMapIsLoad = true
        }, 100)
      } else {
        setTimeout(() => {
          // set the new zoom and display the map
          this.zoomToMap = 11
          this.gMapIsLoad = true
        }, 100)
      }
    },
    /**
     * When the shop/store is select (scroll and zoom)
     * @param {Object} item
     * @returns {Void}
     */
    selectShop ({ item, noScroll }) {
      // zoom to the shop/store
      this.zoomToShop(item)

      if (!noScroll) {
        setTimeout(() => {
          if (item !== undefined) {
            this.scrollToTargetAdjusted(item.id)
          }
        }, 500)
      }
    },
    /**
     * Select first shop of the results and scroll to list
     * @returns {Void}
     */
    selectFirstShop () {
      setTimeout(() => {
        if (this.markers[0]) {
          this.selectShop({ item: this.markers[0] })
        } else {
          this.scrollToShopList()
        }
      }, 500)
    },
    /**
     * Scroll vers la liste des shops stores
     */
    scrollToShopList () {
      const element = document.getElementById('searchOptions')
      const offset = 100
      const bodyRect = document.body.getBoundingClientRect().top
      const elementRect = element.getBoundingClientRect().top
      const elementPosition = elementRect - bodyRect
      const offsetPosition = elementPosition - offset

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      })
    },
    /**
     * Fonction pour ajuster le scroll vers la liste des shops stores
     * @param {Object} itemID
     */
    scrollToTargetAdjusted (itemID) {
      this.scrollToShopList()
      setTimeout(() => {
        const shopCardEl = document.getElementById(`shop-card-${itemID}`)
        const shopCardElTopPos = shopCardEl.offsetTop

        document.getElementById('shop-list').scrollTop = shopCardElTopPos
      }, 200)
    },
    /**
     * Reset the marker
     * @returns {Void}
     */
    resetMarker () {
      // loop on all shop/store
      this.shops.forEach((item, key) => {
        // reset all active and center
        this.shops[key].active = false
        this.shops[key].center = false
      })
    }
  },
  mounted () {
    // reset the marker
    this.resetMarker()
    // the the new marker
    this.markers = this.shops
    // default map center
    this.localCenterMap = { lat: Number(this.markers[0].lat), lng: Number(this.markers[0].lon) }
    // eslint-disable-next-line max-len
    this.currentUserPosition = { lat: Number(this.markers[0].lat), lng: Number(this.markers[0].lon) }

    // if we have a location on the search bar or is already setup
    if (this.myPosition && (!this.location || this.location === '')) {
      // this.useDefaultGeoLoc(this.myPosition, false)
    }

    // Watcher the the geolocation got an error
    this.$store.watch(
      (state, getters) => getters.getGeolocationError,
      (newValue, oldValue) => {
        // map is loaded when we have an error
        if (oldValue !== newValue && newValue === true) {
          this.gMapLoader = true
        }
      }
    )

    // Watcher when the position change
    this.$store.watch(
      (state, getters) => getters.getMyPosition,
      (newValue, oldValue) => {
        if (oldValue !== newValue && oldValue === null && newValue !== null) {
          // select the default shop with the new position
          // this.useDefaultGeoLoc(newValue, false)
        }
      }
    )

    // Watcher when the lang change
    this.$store.watch(
      (state, getters) => getters.getLang,
      (newValue, oldValue) => {
        if (oldValue !== newValue) {
          this.lang = newValue
        }
      }
    )

    // Event when the map is load
    EventBus.$on('gmap-is-load', (params) => {
      this.gMapIsLoad = params.value
    })

    // Event when we set the zoom
    EventBus.$on('gmap-set-zoom', (params) => {
      this.zoomToMap = params.value
    })

    // Event when the center map change
    EventBus.$on('gmap-set-centerMap', (params) => {
      this.localCenterMap = params.value
    })

    // Event when user apply filter
    EventBus.$on('update-selected-services', (params) => {
      this.applyFilter(params.value)
    })

    // Event when we select the near shop/store
    EventBus.$on('select-near-shop', (params) => {
      this.centerPosition = {
        latitude: params.value.lat(),
        longitude: params.value.lng()
      }
      this.selectMyDefaultShop(this.centerPosition, false)
    })
    // Event for search by cp or city and pushing new filtered array of stores/shops
    EventBus.$on('search-cp-change', (params) => {
      this.findShopByLocation(params.value)
      this.searchType = 'searchByAddress'
      this.scrollToShopList()
    })
    // Event for search by name and pushing new filtered array of stores/shops
    EventBus.$on('search-name-change', (params) => {
      // eslint-disable-next-line max-len
      const filtreTexte = (arr, requete) => arr.filter(el => el.name_for_website.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().indexOf(requete.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()) !== -1)
      const searchname = filtreTexte(this.shops, params.value)

      this.markers = searchname
      this.searchType = 'searchByName'

      this.selectFirstShop()
    })

    // Dispatch event to show/hide the city search
    EventBus.$emit('display-global-advanced-search', { value: (this.shops.length > 0) })

    // Timer to show the map if we have an error
    setTimeout(() => {
      if (this.geolocationError === true) {
        this.gMapIsLoad = true
      }
    }, 2000)
  }
}
</script>

<style lang="scss" scoped>
.min-map-height {
  min-height: 600px;
}
.cursor-pointer{
  cursor: pointer;
}
.hide_btn {
  display: none;
}
.shop-wrapper{
  padding: 4rem 6rem;
  transition: all 0.3s ease-out;
  justify-content: space-between;

  @include breakpoint('lg'){
    flex-wrap: nowrap;
  }

  address{
    margin-bottom: 0.5rem;
  }

  &:hover:not(.selected-shop){
    background-color: $grey-b2b
  }
}
.scroll {
  overflow: scroll;
  -ms-overflow-style: none;
}
.scroll::-webkit-scrollbar {
  display: none;
}
.shop-border {
  border-bottom: 1px solid $grey-light;
  border-right: 1px solid $grey-light;
}
.shop-no-result {
  padding: 4rem 6rem;
}
.selected-shop {
  background-color: $red;
}
.selected-shop div > h3 {
  color: $white;
}

.cta-wrapper {
  display: flex;
  align-items: flex-end;
  text-align: right;
  margin-top: 2rem;


  @include breakpoint('lg'){
    margin-top: 0rem;
  }

  > div{
    .btn:last-child{
      margin-top: 1rem;
    }
  }
}

@media screen and (max-width: 1025px) {
  #shop-list {
    height: 32rem;
  }
}
</style>
