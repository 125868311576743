<template>
      <div class="content">
        <figure v-if="imageCard">
          <img class="single-card__img" loading="lazy" :src="imageCard" alt="" />
        </figure>
        <div v-if="!type.includes('__image-only')" class="desc">
          <p v-if="date" class="date" v-html="date"></p>
          <p class="sup_title" v-if="supTitle">{{ supTitle }}</p>
          <h3
            v-if="titleCard"
            :class="[biggerTitle ? 'biggerTitle' : '']"
            v-html="titleCard"
          ></h3>
          <h4 class="sub-title" v-if="subTitleCard">
            {{ subTitleCard }}
          </h4>
          <p v-if="contentCard" v-html="contentCard"></p>
        </div>
      </div>
</template>
<script>
export default {
  name: 'CardContent',
  props: {
    type: {
      default: 'classic'
    },
    supTitle: {
      type: String,
      default: null
    },
    textAlignment: {
      type: String,
      default: 'start'
    },
    date: {
      type: String,
      default: null
    },
    subTitleCard: {
      type: String,
      default: null
    },
    imageCard: {
      type: String,
      default: null
    },
    titleCard: {
      type: String,
      default: null
    },
    biggerTitle: {
      type: Boolean,
      default: false
    },
    contentCard: {
      type: String,
      default: null
    },
    ctaCard: {
      type: Object,
      default: null
    }
  }
}
</script>
