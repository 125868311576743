import uuidv4 from '../../utils/uuidv4'

export default {
  /**
   * Builder for the advanced search
   * @param {Object} data
   * @param {Object} _this
   * @returns {Object}
   */
  buildAdvancedSearch (data, _this) {
    const find = (data.button_type === 'store') ? 'Members' : 'Shops'
    const findboth = 'Both'

    return {
      id: `${data.entity_id}_adv_${uuidv4()}`,
      component: 'AdvancedSearch',
      props: {
        servicesList: _this.getServices().filter((obj) => {
          if (obj.object_type === find || obj.object_type === findboth) {
            return true
          }
          return false
        }),
        'filterServices.sync': _this.filterServices
      }
    }
  },
  /**
   * Remove all accents on the string
   * @param {String} string
   * @returns {String}
   */
  removeAccents (string) {
    const accents =
      'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž'
    const accentsOut =
      'AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz'
    return string
      .split('')
      .map((letter, index) => {
        const accentIndex = accents.indexOf(letter)
        return accentIndex !== -1 ? accentsOut[accentIndex] : letter
      })
      .join('')
  },
  /**
   * Order store / shop by city
   * @param {Array} stores
   * @returns {Array}
   */
  orderByCity (stores) {
    const regions = {}

    stores.forEach((item) => {
      if (regions[item.region]) {
        regions[item.region].push(item)
      } else {
        regions[item.region] = []
        regions[item.region].push(item)
      }
      // eslint-disable-next-line max-len
      regions[item.region].sort((a, b) => ((this.removeAccents(a.city) > this.removeAccents(b.city)) ? 1 : -1))
    })

    let newStore = []
    // eslint-disable-next-line array-callback-return
    Object.keys(regions).map((objectKey, index) => {
      newStore = newStore.concat(regions[objectKey])
    })

    return newStore
  },
  /**
   * Build map (find a store / shop)
   * @param {Object} data
   * @param {Object} _this
   * @param {String|null} fullChildPath
   * @returns {Object}
   */
  buildMap (data, _this, fullChildPath) {
    if (data.button_type && data.button_type === 'shop') {
      // Sort region by name and add the user region at the top
      _this.shops.sort((a, b) => ((a.region > b.region) ? 1 : -1))
      // eslint-disable-next-line max-len
      _this.shops.sort(a => ((this.removeAccents(a.region) > _this.$store.getters.getMyRegionName) ? 1 : -1))
      _this.shops = this.orderByCity(_this.shops)
    } else {
      // Sort region by name and add the user region at the top
      _this.stores.sort((a, b) => ((a.region > b.region) ? 1 : -1))
      // eslint-disable-next-line max-len
      _this.stores.sort(a => ((this.removeAccents(a.region) > _this.$store.getters.getMyRegionName) ? 1 : -1))
      _this.stores = this.orderByCity(_this.stores)
    }

    fullChildPath = (fullChildPath === undefined) ? null : fullChildPath
    return {
      id: `${data.entity_id}_map_${uuidv4()}`,
      component: 'MapsFindShop',
      props: {
        servicesList: _this.getServices(),
        shops: (data.button_type && data.button_type === 'shop') ? _this.shops : _this.stores,
        gMapLoader: _this.gMapIsLoad,
        centerMap: _this.centerMap,
        zoom: _this.zoom,
        location: _this.location,
        filterServices: _this.filterServices,
        childUrl: fullChildPath,
        displaySelect: !!((data.button_type && data.button_type === 'shop'))
      }
    }
  },
  /**
   * Build map on find store / shop details
   * @param {Object} data
   * @returns {Object}
   */
  buildMapSingle (data) {
    return {
      id: `${data.entity_id}_map_single_${uuidv4()}`,
      component: 'MapsSingleShop',
      props: {
        displaySelect: !!((data.button_type && data.button_type === 'shop'))
      }
    }
  },
  /**
   * Builder for spacer
   * @param {Object} data
   * @returns {Object}
   */
  buildSpacer (data) {
    return {
      id: `${data.entity_id}_spacer_${uuidv4()}`,
      component: 'Spacer',
      props: {}
    }
  }
}
