import Common from './common'

export default {
  /**
   * Builder for table (5 column per line (on our-product))
   * @param {Object} data
   * @param {String} lang
   * @returns {Array}
   */
  build (data, lang) {
    let props = []
    const global = []

    if (data) {
      data.forEach((line) => {
        props = []
        if (line.columns) {
          line.columns.forEach((column) => {
            props.push({
              title: Common.Title.buildTitle(column.title),
              content: Common.Content.buildContent(column.description),
              icon: Common.Icon.buildIcon(column.icon),
              imageCard: (Common.Image.buildImage(column.image))
                ? Common.Image.buildImage(column.image)
                : Common.Image.buildImage(column.background_image),
              type: Common.Type.buildType(column),
              theme: (column.colour_theme) ? column.colour_theme : null,
              subTitleCard: (column.sub_title) ? column.sub_title : null,
              cta: Common.Cta.buildCta(column, lang),
              videoId: Common.Video.buildVideo(column.video__id),
              hasFullBorder: (column.hasFullBorder) ? column.hasFullBorder : false,
              isPromotion: (column.isPromotion) ? column.isPromotion : false
            })
          })
        }
        global.push(props)
      })
    }
    // rebuilde the array (convert multi array in array to array in array)
    const concatGlobal = [[]]

    global.forEach((line) => {
      line.forEach((item) => {
        concatGlobal[0].push(item)
      })
    })

    return concatGlobal
  }
}
