<template>
  <section class="preview">
    <div class="selectPreviewRegion">
      <SelectRegion :callback="previewRegionCallBack"/>
    </div>
    <div v-if="useLayout">
      <MyRouter :isPreview="true"/>
    </div>
    <div class="errorContentType" v-else>
      {{ $t('errors.previewNotFound') }}
    </div>
  </section>
</template>

<script>
import gql from 'graphql-tag'
import { GET_EN_NAVIGATION, GET_FR_NAVIGATION } from '../../graphql/queries/navigations'
import { GET_FR_PAGE_PREVIEW, GET_EN_PAGE_PREVIEW } from '../../graphql/queries/pages'
import { GET_SITE } from '../../graphql/queries/site'
import MyRouter from '../../router/MyRouter.vue'
import SelectRegion from '../common/SelectRegion.vue'
import EventBus from '../../utils/events'
import hackQL from '../../utils/hackQL'

export default {
  name: 'Preview',
  components: {
    MyRouter,
    SelectRegion
  },
  data () {
    return {
      useLayout: false,
      layoutContentType: [
        'page',
        'brand',
        'product',
        'article',
        'product_category',
        'services_category',
        'article_category'
      ],
      useRegion: 'global'
    }
  },
  methods: {
    /**
     * When the user change the preview region
     * @param {String} region
     * @returns {Void}
     */
    previewRegionCallBack (region) {
      // set the region
      this.useRegion = region
      // reload page with the new region
      this.setPreview()
    },
    /**
     * Get the lang for graphQL
     * @returns {String}
     */
    getLangForGraphQL () {
      switch (this.$route.params.lang) {
        case 'fr':
          return 'fr-CA'
        default:
          return 'en-CA'
      }
    },
    getSiteIdForPreview () {
      switch (this.$route.query.site_id) {
        case 'iU414XaWFobgDMY2Ing4m': // TODO put right id
          return 7
        default:
          return 6
      }
    },
    /**
     * Prepare/send the preview region
     * @returns {Void}
     */
    async setPreview () {
      // display the loader
      EventBus.$emit('router-loading', { value: false })

      // eslint-disable-next-line
      console.log(this.$route.query.site_id)
      this.$store.SITE_ID = this.getSiteIdForPreview()
      // Check if we use the layout
      this.useLayout = (this.$route.query.content_type &&
          this.layoutContentType.includes(this.$route.query.content_type))

      // prepare the navigation request
      const navigationRequest = (this.$route.params.lang && this.getLangForGraphQL() === 'fr-CA') ? GET_FR_NAVIGATION : GET_EN_NAVIGATION
      // prepare the page request
      let pageRequest = (this.$route.params.lang && this.getLangForGraphQL() === 'fr-CA') ? GET_FR_PAGE_PREVIEW : GET_EN_PAGE_PREVIEW

      // test if is hackQL query
      // eslint-disable-next-line max-len
      const hackQuery = hackQL.preview(pageRequest, this.$route.query.content_type, this.getLangForGraphQL())
      // is hackQL
      if (hackQuery.isHack) {
        // update the request
        pageRequest = hackQuery.pageRequest // eslint-disable-line
      }

      // prepare the graphql query
      const query = gql`
        query {
          ${(this.useLayout) ? GET_SITE.replace(/\$lang/g, this.getLangForGraphQL()).replace(/\$site_id/g, this.$store.SITE_ID) : ''}
          ${(this.useLayout) ? navigationRequest.replace(/\$region/g, this.useRegion).replace(/\$site_id/g, this.$store.SITE_ID) : ''}
          ${pageRequest.replace(/\$entity/g, this.$route.params.entity).replace(/\$region/g, this.useRegion).replace(/\$site_id/g, this.$store.SITE_ID)}
        }
      `

      // send the graphql
      await this.$apollo.query({ query }).then((resp) => {
        // this.$route.query.content_type
        const removePadding = !!((this.$route.query.content_type && this.$route.query.content_type === 'article_category'))
        // set the page on MyRouter
        EventBus.$emit('set-router-preview', { data: resp.data, removePadding })
      })
    }
  },
  mounted () {
    // load the preview
    this.setPreview()
  }
}
</script>

<style lang="scss">
.preview {
  .app-body {
    min-height: 70vh;
  }

  .header_wrapper {
    top: 70px;
  }

  .app-body {
    padding-top: 70px;

    section:nth-child(2) {
      padding-top: 70px;
    }
  }

  .breadcrumb-nav {
    padding-top: 70px;
  }

  .errorContentType {
    padding: 70px 2rem;
    font-size: 2.1rem;
  }
}

.selectPreviewRegion {
  position: fixed;
  z-index: 9;
  width: 100%;
  height: 70px;
  background-color: white;
}
</style>
