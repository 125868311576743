<template>
  <section class="block-download">
    <div
        v-if="title"
        class="container-fluid">
      <div class="row wrapper justify-content-center">
        <div class="col-lg-8 text-center">
          <h2 class="block-download__title">
            {{ title }}
          </h2>
        </div>
      </div>
    </div>

    <section-cards
        :add-row="false"
        border-display
        :elements="elements"
        :layout="3"
        layout-size="full"/>
  </section>
</template>

<script>
import SectionCards from './SectionCards.vue'

export default {
  name: 'BlockDownload',
  components: {
    SectionCards
  },
  data () {
    return {
      elements: null
    }
  },
  props: {
    title: {
      type: String,
      default: false
    },
    assets: {
      type: Array,
      default: () => []
    }
  },
  mounted () {
    if (this.assets) {
      this.elements = this.assets.map(asset => ({
        content: `${Math.round(asset.file.details.size / 1024)} KB`,
        title: asset.file.fileName,
        // supTitle: asset.file.contentType,
        cta: {
          link: `https:${asset.file.url}`,
          target: '_blank',
          title: this.$t('generalUi.download')
        }
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
.block-download {
  padding-top: 7.5rem;
  padding-bottom: 9rem;
  position: relative;

  @include breakpoint('lg') {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  &__title {
    margin-bottom: 4rem;
  }

  .section-cards {
    border-top: 1px solid $grey-light;
  }
}
</style>
