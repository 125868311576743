<template>
  <div class="embed-responsive embed-responsive-16by9">
    <vueVimeoPlayer class="embed-responsive-item" :video-id='videoId' :options="playerOption"></vueVimeoPlayer>
  </div>
</template>

<script>
import { vueVimeoPlayer } from 'vue-vimeo-player'

export default {
  name: 'IframeVideo',
  components: { vueVimeoPlayer },
  data () {
    return {
      playerOption: {
        color: 'f80d06',
        byline: true
      }
    }
  },
  props: {
    videoId: {
      type: String,
      default: null
    }
  }
}
</script>
