export default {
  /**
   * Build column type
   * @param {Object} column
   * @returns {String}
   */
  buildType (column) {
    if (column.background_image) {
      return '__image-only'
    }

    return (column.aligment === 'center') ? '__centered' : ''
  },
  /**
   * Build type of alignment (start / end / center)
   * @param {String|null} data
   * @returns {String}
   */
  buildAlignment (data) {
    return (data) || 'start'
  }
}
