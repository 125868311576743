import uuidv4 from '../../utils/uuidv4'
import Common from './common'

export default {
  /**
   * Build the background
   * @param {Object|null} bkg
   * @returns {String|null}
   */
  buildHeroBkg (bkg) {
    return (bkg && bkg.file) ? bkg.file.url : null
  },
  /**
   * Build the grid on the hero
   * @param {String|null} template
   * @returns {Boolean}
   */
  buildBkgGrid (template) {
    switch (template) {
      case 'home':
        return true
      default:
        return false
    }
  },
  /**
   * Build the rotate text
   * @param {String|null} text
   * @returns {String|null}
   */
  buildRotateText (text) {
    return (text) || null
  },
  /**
   * Build alignment
   * @param {String|null} alignment
   * @returns {String}
   */
  buildAlignment (alignment) {
    return (alignment) || 'start'
  },
  /**
   * Build the color theme
   * @param {Object} data
   * @returns {String}
   */
  buildTheme (data) {
    if (data.background_image) {
      return 'white'
    }
    if (data.template && data.template === 'home') {
      return 'white'
    }

    return 'black'
  },
  /**
   * Build the custom slot
   * @param {Object} data
   * @param {Object} _this
   * @returns {Object|null}
   */
  buildSlot (data, _this) {
    if (data.template && data.template === 'search') {
      return {
        component: 'SearchCity',
        props: {
          model: _this.location,
          placeholder: _this.$t('pages.findShop.placeHolderLocation'),
          btnText: _this.$t('pages.findShop.btnFindShop')
        }
      }
    }

    if (data.template && data.template === 'garage') {
      return {
        component: 'SelectShop',
        props: {
          shopId: _this.$route.path.split('/')[_this.$route.path.split('/').length - 1],
          textBtnRight: _this.$t('pages.findShopDetail.change'),
          textBtnLeft: _this.$t('pages.findShopDetail.select')
        }
      }
    }

    return null
  },
  /**
   * Build the custom display description (shop / store details)
   * @param {Object|null} shop
   * @param {Object|null} store
   * @param {String} lang
   * @returns {String}
   */
  formatDescription (shop, store, lang) {
    let formated = ''
    let description = ''

    if (shop) {
      // eslint-disable-next-line no-nested-ternary
      description = (shop.description_fr && lang === 'fr-CA') ? shop.description_fr : (shop.description_en && lang === 'en-CA') ? shop.description_en : ''
      if (description.replace(/ /g, '') === '') {
        description = '<br><br><br><br><br>'
      }
      formated = `
        ${formated}
        <p class="hero_shop" >
          ${description}
        </p>
        <br />
      `
    }

    if (store) {
      // eslint-disable-next-line no-nested-ternary
      description = (store.description_fr && lang === 'fr-CA') ? store.description_fr : (store.description_en && lang === 'en-CA') ? store.description_en : ''
      if (description.replace(/ /g, '') === '') {
        description = '<br><br><br><br><br>'
      }
      formated = `
        ${formated}
        <p class="hero_store">
          ${description}
        </p>
        <br />
      `
    }

    return formated
  },
  /**
   * Build the custom name (shop / store details)
   * @param {Object|null} shop
   * @param {Object|null} store
   * @returns {String}
   */
  formatShopName (shop, store) {
    let formated = ''
    if (shop) {
      formated = `
        ${formated}
        <span class="hero_shop" >
          ${shop.name_for_website}
        </span>
      `
    }

    if (store) {
      formated = `
        ${formated}
        <span class="hero_store">
          ${store.name_for_website}
        </span>
      `
    }

    return formated
  },

  getBannerType (shop) {
    let getBanner = ''

    if (shop) {
      getBanner = shop.banner
    }

    return getBanner
  },
  /**
   * Build if we use video as background
   * @param {String|null} videoId
   * @returns {Boolean}
   */
  buildVideoBkg (videoId) {
    return !!(videoId)
  },
  /**
   * Builder for hero
   * @param {Object} data
   * @param {Object} _this
   * @param {String} lang
   * @returns {Object}
   */
  build (data, _this, lang) {
    const slot = this.buildSlot(data, _this)

    let heroTitle = Common.Title.buildTitle(data.title)
    let heroContent = Common.Content.buildContent(data.content)
    // eslint-disable-next-line no-unused-vars
    let heroBannerType = ''

    if (data.template && data.template === 'garage') {
      const shop = _this.$store.getters.getShopBySlug(_this.$route.path.split('/')[_this.$route.path.split('/').length - 1])
      const store = _this.$store.getters.getStoreBySlug(_this.$route.path.split('/')[_this.$route.path.split('/').length - 1])
      heroTitle = this.formatShopName(shop, store)
      heroContent = this.formatDescription(shop, store, lang)
      heroBannerType = this.getBannerType(shop)
    }
    let globalSearch = false
    if (data.template && data.template === 'global_search') {
      globalSearch = true
    }

    const siteID = String(_this.$store.SITE_ID)
    return {
      id: `${data.entity_id}_${uuidv4()}`,
      component: 'Hero',
      props: {
        heroBkg: this.buildHeroBkg(data.background_image),
        heroBkgVideo: this.buildVideoBkg(data.background_video_id),
        heroVideoId: Common.Video.buildVideo(data.background_video_id),
        hasGridBackground: this.buildBkgGrid(data.template),
        heroBadge: null,
        heroRotatedText: this.buildRotateText(data.rotated_text),
        heroTitle,
        heroSubtitle: null,
        heroContent,
        heroImg: Common.Image.buildImage(data.image),
        heroType: `large`,
        heroCta: Common.Cta.buildCta(data, lang),
        vCentered: false,
        hAlignment: this.buildAlignment(data.alignment),
        heroTheme: this.buildTheme(data),
        heroBannerType,
        globalSearch,
        siteID
      },
      slot
    }
  }
}
