<template>
  <div
    class="col-lg single-card-clickable __centered"
    :class="[
      type ? type : '',
      theme ? theme : '',
      colType === '4cols' && type.includes('__image-only')
        ? 'col-lg-2 no-borders'
        : '',
      iconCard == null && imageCard == null ? '__squarred' : '',
      iconCard ? 'icon-card' : '',
      isPromotion ? 'isPromotion' : '',
      noBorder ? '' : '__has-border-full',
      textAlignClass,
    ]"
  >
    <div class="wrapper">
      <b-link
        class="h-100"
        v-if="btnCta && isExternal(btnCta.link)"
        :href="btnCta.link"
        :target="btnCta.target ? btnCta.target : '_self'"
      >
        <card-content
          class="h-100"
          :imageCard="imageCard"
          :titleCard="titleCard"
          :ctaCard="ctaCard"
          :type="type"
          :theme="theme"
          :textAlignment="`center`"
        />
      </b-link>
      <b-link
        v-if="btnCta && !isExternal(btnCta.link)"
        class="h-100"
        :to="btnCta.link"
        :target="btnCta.target ? btnCta.target : '_self'"
      >
        <card-content
          class="h-100"
          :imageCard="imageCard"
          :titleCard="titleCard"
          :ctaCard="ctaCard"
          :type="type"
          :theme="theme"
          :textAlignment="`center`"
        />
      </b-link>
      <card-content
        v-else
        :imageCard="imageCard"
        :titleCard="titleCard"
        :ctaCard="ctaCard"
        :type="type"
        :theme="theme"
        :textAlignment="`center`"
      />
    </div>
  </div>
</template>
<script>
import gql from 'graphql-tag'
import linkMixing from '../../mixins/link'
import Builder from '../../builder'
import CardContent from '../../components/common/CardContent.vue'

export default {
  name: 'CardsFullClickable',
  mixins: [linkMixing],
  components: {
    CardContent
  },
  data () {
    return {
      btnCta: {
        link: '#',
        title: '...',
        target: '_self'
      }
    }
  },
  props: {
    theme: {
      default: ''
    },
    type: {
      default: 'classic'
    },
    colType: {
      type: String,
      default: null
    },
    supTitle: {
      type: String,
      default: null
    },
    textAlignment: {
      type: String,
      default: 'start'
    },
    date: {
      type: String,
      default: null
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    videoId: {
      type: String,
      default: null
    },
    subTitleCard: {
      type: String,
      default: null
    },
    iconCard: {
      default: null
    },
    imageCard: {
      type: String,
      default: null
    },
    titleCard: {
      type: String,
      default: null
    },
    biggerTitle: {
      type: Boolean,
      default: false
    },
    hasBorder: {
      type: [Boolean, String],
      default: true
    },
    contentCard: {
      type: String,
      default: null
    },
    ctaCard: {
      type: Object,
      default: null
    },
    hasFullBorder: {
      type: Boolean,
      default: false
    },
    uKey: {
      type: [Number, String],
      default: null
    },
    isCategoryTile: {
      type: Boolean,
      default: false
    },
    isPromotion: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * Add text alignment class
     * @returns {String}
     */
    textAlignClass () {
      let tclass = ''

      if (this.textAlignment === 'center') {
        tclass = 'text-center'
      }
      if (this.textAlignment === 'end') {
        tclass = 'text-right'
      }

      return tclass
    }
  },
  mounted () {
    // if we have cta button
    if (this.ctaCard) {
      // if we have the entity id on the button, load he button
      if (
        this.ctaCard.entity_id &&
        this.ctaCard.entity_id.replace(/ /g, '') !== ''
      ) {
        // graphQL request for get the button informations
        const query = gql`
          query {
            block_button: blockbutton(locale: "${this.$i18n.locale}",entity_id:"${this.ctaCard.entity_id}") {
              entity_id
              block_name
              button_text
              button_link
              button_target
              internal_link
              link_to_file
              is_modal
            }
          }
        `

        this.$apollo.query({ query }).then((resp) => {
          const button = resp.data.block_button
          // if we have an internal link
          if (button.internal_link) {
            // and is a string
            if (typeof button.internal_link === 'string') {
              // parse the json
              button.internal_link = this.$JSON5.parse(button.internal_link)
            }
          }

          // if we have an internal link
          if (button.link_to_file) {
            // and is a string
            if (typeof button.link_to_file === 'string') {
              // parse the json
              button.link_to_file = this.$JSON5.parse(button.link_to_file)
            }
          }

          // if we don't use the modal
          if (button.is_modal === null) {
            button.is_modal = false
          }
          // format the button on array
          const formatBuilder = [button]
          // Build the cta button
          // eslint-disable-next-line max-len
          this.btnCta = Builder.Common.cta.buildCta(
            { block_button: formatBuilder },
            this.$i18n.locale
          )
        })
      } else {
        // use the button
        this.btnCta = this.ctaCard
      }
    } else {
      // no cta button
      this.btnCta = null
    }
  }
}
</script>

<style lang="scss">
.single-card-clickable {

  @include breakpoint("lg") {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .sup_title,
  .date {
    text-transform: uppercase;
    font-size: 1.1rem;
  }
  figure {
    max-width: 100%;
    position: relative;
    margin: 3.5rem 3.5rem 0;

    @include breakpoint("md") {
      margin: 4rem 4rem 0;
    }
    @include breakpoint("lg") {
      margin: 4rem 4rem 0;
    }

    img {
      width: 100%;
      height: auto;
      max-height: 400px;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-bottom: 4rem;

    @include breakpoint("lg") {
      padding-bottom: 8rem;
    }

    .content {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .desc {
      padding: 4rem 3.5rem 0 3.5rem;
      word-break: break-word;

      @include breakpoint("lg") {
        padding: 4rem 0 0 0;
        width: 86.66%;
        margin: 0 auto;
      }

      h2 {
        margin-bottom: 2rem;
      }
      h3 {
        margin-bottom: 0;
        font-size: 2rem;

        @include breakpoint("lg") {
          font-size: 1.8rem;
        }

        @include breakpoint("xl") {
          font-size: 2.5rem;
        }
      }
      h4 {
        margin-top: 2rem;
      }
      p {
        margin-bottom: 1.5rem;
        color: currentColor;

        p {
          color: currentColor;
        }
      }
    }
  }
  /*
  * Ajustement des padding du wrapper pour
  *le nouveau block 4 cards autoparts/services
  */
  &.p4r {
    .wrapper {
      padding-top: 3.5rem;
      padding-bottom: 3.5rem;
      .content .desc {
        padding-top: 0;
      }
    }
  }

  &.__centered {
    text-align: center;

    .wrapper {
      padding-top: 2rem;
      @include breakpoint("lg") {
        padding-top: 0;
      }
    }

    .desc {
      padding: 2rem 2.5rem 0 1.5rem;

      @include breakpoint("lg") {
        padding: 2rem 0rem 0 0rem;
      }

      @include breakpoint(xl) {
        padding: 2rem 0rem 0 0rem;
      }
      p {
        margin-bottom: 1.5rem;
      }
    }
  }

  &.__squarred-with-border {
    .wrapper {
      /* min-height: 23vw; */
      figure {
          margin: 2rem 4rem 0;
          max-height: 400px;
      }
      .content {
        border: 1px solid $grey-light !important;
      }
      .desc {
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
    }
  }

  .__link-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    text-decoration: none;
    align-items: center;
  }
}
</style>