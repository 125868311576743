<template>
  <span v-if="display">
    <!-- Services Icons -->
    <img
     v-if="category === 'services'"
        svg-inline
        class="serviceIcon"
        :src="require(`./files/services/${serviceIcon(categoryKey)}`)"
        alt
    />
    <!-- Contact Icons -->
    <img
      v-if="category === 'contact' && categoryKey === 'address'"
        svg-inline
        :src="require('./files/contact/address.svg')"
        alt
    />
    <img
      v-if="category === 'contact' && categoryKey === 'facebook_link'"
      svg-inline
      :src="require('./files/contact/facebook.svg')"
      alt
    />
    <img
      v-if="category === 'contact' && categoryKey === 'fax'"
      svg-inline
      :src="require('./files/contact/fax.svg')"
      alt
    />
    <img
      v-if="category === 'contact' && categoryKey === 'hours'"
      svg-inline
      :src="require('./files/contact/hours.svg')"
      alt
    />
    <img
      v-if="category === 'contact' && categoryKey === 'phone'"
      svg-inline
      :src="require('./files/contact/phone.svg')"
      alt
    />
    <img
      v-if="category === 'contact' && categoryKey === 'website_link'"
      svg-inline
      :src="require('./files/contact/website.svg')"
      alt
    />
    <!-- Default Icon -->
    <!-- <img
      v-else
      svg-inline
      :src="require('./files/default.svg')"
      alt
    /> -->
  </span>
</template>

<script>
const iconsImgs = {
  UNASSIGNED1: 'performance.svg',
  'C4936717-92AF-4FF5-BB0D-10B30C8A5694': 'air_conditioning.svg',
  UNASSIGNED2: 'auto_paint.svg',
  '364A320B-DC6B-4A64-8E42-FFA2B9424CD5': 'auto_parts.svg',
  'C82400AA-5426-4F5C-96B4-EC62A5174252': 'body_products.svg',
  '68D0B28F-F2B1-4A19-8013-929916C1655A': 'brakes.svg',
  '063E3C95-15ED-4F17-A224-30B6844FAF89': 'charging_and_starting_system.svg',
  '4A2CCD4A-B975-441C-85DA-31FAA193E7E2': 'charging_and_starting_system.svg',
  'E2E61DFF-6775-40FD-AD30-D3AE1ABF4879': 'charging_and_starting_system.svg',
  'EAC312F6-82F2-40E9-9269-F135300C2400': 'cleaning_product.svg',
  'D4CF89D4-2B9B-41FD-9063-1E1031FFCDD6': 'diagnosis.svg',
  'DE637E14-44F9-466D-9320-EB618EF64A25': 'diagnosis.svg',
  '3E8F26B4-3255-48C8-9AF9-999D8DD4E029': 'electronic_engine_tune_up.svg',
  '0AC32FB3-B398-4BAD-BC12-983AEB9D6955': 'electronic_fuel_injection.svg',
  UNASSIGNED3: 'electronic_performance.svg',
  UNASSIGNED4: 'exhaust_system.svg',
  UNASSIGNED5: 'farm_equip.svg',
  '500DE20B-02D7-4DD2-AD74-0F700E98E25E': 'heating.svg',
  UNASSIGNED6: 'hydraulics.svg',
  '868DC581-7726-4718-8F47-BBB19DE82805': 'industrial_equip.svg',
  'C232CEAB-B93B-49C6-8B00-93EA48153FB7': 'lighting_and_visibility.svg',
  '343D2EA8-F101-4844-901E-9664BCF6010B': 'lubrication.svg',
  '12891B12-034A-42FE-A33D-51B59D674606': 'oil_change.svg',
  '376AC8BF-AA5D-48C8-8BA1-F89DED95FCA1': 'other.svg',
  'FDCF1B54-BE88-4D24-8DB1-42E6C7B985F9': 'preventive_maintenance.svg',
  '9992F860-5CA7-4369-BDE4-070994A39BCE': 'rustproofing.svg',
  'D3A6678B-ED8E-4F19-9952-E783863B0626': 'sale_and_installation_of_tires.svg',
  UNASSIGNED7: 'security.svg',
  UNASSIGNED8: 'shop_supply.svg',
  'AA7AD7F8-2BC1-4049-82CC-C2CC98B5109B': 'shuttle_service_and_courtesy_vehicles.svg',
  '94C751DB-E798-45CC-9879-14D597CABF15': 'steering.svg',
  UNASSIGNED9: 'storage.svg',
  '22476362-FFE2-4577-8448-B59E0F365DBC': 'suspension_system.svg',
  '085FF635-1A97-4E2B-90DE-B228E2F731CA': 'tire_repair.svg',
  '695D71CC-9B7D-4B94-AA41-AFCAACA2882D': 'tire_rotation.svg',
  '12D5257C-AE5C-4897-96CB-DF680144CAD9': 'tire_storage.svg',
  UNASSIGNED10: 'tires_rims.svg',
  'A65A97DD-D4DE-4D92-98D8-50A98C3D55F3': 'tools.svg',
  '6A816645-9433-4D59-9FE5-37FA2B1DB4CF': 'towing_devices.svg',
  UNASSIGNED11: 'transmission_and_traction.svg',
  'CD2093B5-534B-40F6-9E0D-7F54C7D82C09': 'vehicle_aesthetics.svg',
  '32967BF6-9F39-4839-87AA-0796E839B69A': 'wheel_alignment.svg',
  'BA563B86-D4D8-42EA-B7FC-6AF4C3052BE1': 'wheel_balancing.svg',
  'C3E69C0B-7626-4617-A007-9CCA6400D07E': 'windshield_repair_and_replacement.svg'
}

export default {
  name: 'Icons',
  data () {
    return {
      excludeServices: [
        '64',
        '62',
        '65',
        '68',
        '74',
        '72',
        '66',
        '71',
        '76',
        '73',
        '70',
        '63',
        '69',
        '75',
        '67',
        '100',
        '101',
        '102',
        '103',
        '104',
        '105',
        '106',
        '107',
        '108',
        '109',
        '110',
        '111',
        '112',
        '113',
        '114',
        '115',
        '116',
        '117',
        '118',
        '119',
        '120',
        '121',
        '122',
        '123',
        '124',
        '125'
      ]
    }
  },
  props: {
    category: {
      type: String,
      default: ''
    },
    categoryKey: {
      type: String,
      default: ''
    },
    display: {
      type: Boolean,
      default: true
    },
    useDefault: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    serviceIcon (key) {
      return iconsImgs[key] || 'other.svg'
    }
  }
}
</script>

<style lang="scss" scoped>
.serviceIcon {
  width: 30px;
  height: 25.2px;
}
</style>
