import hackQL from '../../../utils/hackQL'

export default {
  /**
   * Build link for the button
   * @param {Object} button
   * @param {String} lang
   * @returns {String}
   */
  buildBtnLink (button, lang) {
    // if we have internal link
    if (button.internal_link && button.internal_link[0]) {
      // use full path if existe
      if (button.internal_link[0].full_path) {
        return `${button.internal_link[0].full_path}`
      }
      // build the link with the slug
      return `/${(lang && lang === 'fr-CA') ? 'fr' : 'en'}/${button.internal_link[0].slug}`
    }

    // If we have link
    if (button.button_link) {
      return (button.button_link.startsWith('//')) ? `${window.location.origin}${button.button_link.replace(/\/\//g, '/')}` : button.button_link
    }
    if (button.link_to_file && button.link_to_file.file) {
      return button.link_to_file.file.url
    }
    return ''
  },
  /**
   * Build the CTA button
   * @param {Object} data
   * @param {String} lang
   * @returns {Object|null}
   */
  buildCta (data, lang) {
    // if is content type is an execption
    if (data.content_type === 'article' || data.content_type === 'article_category' || data.content_type === 'product' || data.content_type === 'product_category' || data.content_type === 'services_category') {
      let hackPath = null
      let title = ''

      switch (data.content_type) {
        case 'article':
          hackPath = 'articles'
          title = (lang === 'fr-CA') ? 'Lire la suite' : 'Read more'
          break
        case 'article_category':
          hackPath = 'ressources'
          title = (lang === 'fr-CA') ? 'Apprendre encore plus' : 'Learn more'
          break
        case 'product':
          hackPath = 'product'
          title = (lang === 'fr-CA') ? 'Voir les détails ' : 'See details'
          break
        case 'product_category':
          hackPath = 'products'
          title = (lang === 'fr-CA') ? 'Voir les détails ' : 'See details'
          break
        case 'services_category':
          hackPath = 'services'
          title = (lang === 'fr-CA') ? 'En savoir plus' : 'Learn more'
          break
        default:
          hackPath = null
          title = ''
          break
      }

      let link = `${(lang === 'fr-CA') ? '/fr' : '/en'}/${hackQL.getPath(hackPath)[(lang === 'fr-CA') ? 1 : 0]}/${data.slug}`

      if (data.content_type === 'product') {
        link += '?b=history'
      }

      if (data.content_type === 'product_category' || data.content_type === 'services_category') {
        link += `?p=${data.entity_id}`
      }

      return {
        link,
        title,
        target: '_self'
      }
    }

    // if we have button
    if (data.block_button && data.block_button[0]) {
      const ownProps = Object.keys(data.block_button[0])

      // if the button have only entity ID, format the button for get the real data later
      if (ownProps.length === 1 && data.block_button[0].entity_id) {
        return {
          entity_id: data.block_button[0].entity_id,
          link: '#',
          title: '...',
          target: '_self',
          is_modal: false
        }
      }
      return {
        link: this.buildBtnLink(data.block_button[0], lang),
        title: data.block_button[0].button_text,
        target: (data.block_button[0].button_target) ? data.block_button[0].button_target : '_self',
        is_modal: data.block_button[0].is_modal
      }
    }
    return null
  }
}
