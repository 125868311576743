import uuidv4 from '../../utils/uuidv4'

export default {
  /**
   * Builder for the site map page
   * @param {Object} data
   * @returns {Object}
   */
  build (data) {
    return {
      id: `site_map_${uuidv4()}`,
      component: 'SiteMap',
      props: {
        elements: (data.elements) ? data.elements : []
      }
    }
  }
}
