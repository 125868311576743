const config = require('./config')
const development = require('../env.development.enc')
const staging = require('../env.staging.enc')
const production = require('../env.production.enc')

const CryptoJS = require('crypto-js')

/**
 * Descrypt all env file for the current node env
 * @param {String} nodeEnv
 * @returns {Void}
 */
module.exports = (nodeEnv) => {
  // get the node env
  const env = (nodeEnv === undefined) ? process.env.NODE_ENV : nodeEnv
  // set default .env file to null
  let envFile = null

  // if we aren't on dev mode
  if (process.env.NODE_ENV !== 'development') {
    // stop here
    // eslint-disable-next-line no-console
    console.error('[DotEnv] uncrypt method is accecible only on development mode')
    return
  }

  // eslint-disable-next-line no-console
  console.log('Decrypt file => ', env)

  // get the good file by env variable
  switch (env) {
    case 'development':
      envFile = development
      break
    case 'staging':
      envFile = staging
      break
    case 'production':
      envFile = production
      break
    default:
      break
  }

  const encFile = {}

  // eslint-disable-next-line no-console
  console.log('Original file => ', envFile)

  // if env file existe
  if (envFile) {
    // loop on all file key / value
    // eslint-disable-next-line array-callback-return
    Object.keys(envFile).map((objectKey) => {
      // decrypt value
      // eslint-disable-next-line max-len
      encFile[objectKey] = CryptoJS.AES.decrypt(envFile[objectKey], config.secret[env]).toString(CryptoJS.enc.Utf8)
    })
  }

  // eslint-disable-next-line no-console
  console.log('Decrypted file => ', encFile)
  // eslint-disable-next-line no-console
  console.log(`Decrypted file to string /src/env/env.${nodeEnv}.enc.js=> module.exports =`, JSON.stringify(encFile, null, 4))
}
