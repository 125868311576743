export const GET_ALL_PAGE = `
    pageEn: pages(locale: "en-CA",site_id: "$site_id", slug: "$slug", region: "$region") {
      page_title
      full_url
      meta_data
      components
      slug
      leading_image
    }
    pageFr: pages(locale: "fr-CA",site_id: "$site_id", slug: "$slug", region: "$region") {
      page_title
      full_url
      meta_data
      components
      slug
      leading_image
    }
`

export const GET_EN_PAGE = `
    pageEn: pages(locale: "en-CA", site_id: "$site_id", slug: "$slug", region: "$region") {
      page_title
      full_url
      meta_data
      components
      slug
      child_pages
      leading_image
    }
`

export const GET_FR_PAGE = `
    pageFr: pages(locale: "fr-CA", site_id: "$site_id", slug: "$slug", region: "$region") {
      page_title
      full_url
      meta_data
      components
      slug
      child_pages
      leading_image
    }
`

export const GET_EN_PAGE_PREVIEW = `
    pageEn: pages(locale: "en-CA",site_id: "$site_id", entity_id: "$entity", region: "$region", preview: true) {
      page_title
      full_url
      meta_data
      components
      slug
      leading_image
    }
`

export const GET_FR_PAGE_PREVIEW = `
    pageFr: pages(locale: "fr-CA",site_id: "$site_id", entity_id: "$entity", region: "$region", preview: true) {
      page_title
      full_url
      meta_data
      components
      slug
      leading_image
    }
`

export const GET_EN_PAGE_PARENT = `
  page (locale: "en-CA",site_id: "$site_id", region:  "$region", entity_id: "$entity"){
        entity_id
        slug
        page_title
      }
`

export const GET_FR_PAGE_PARENT = `
      page (locale: "fr-CA",site_id: "$site_id", region:  "$region", entity_id: "$entity"){
        entity_id
        slug
        page_title
      }
`

