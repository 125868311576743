<template>
  <div class="maps-bumper h-100">
    <div v-show="true" :class="customClass" :id="mapName" v-bind:style="gmapStyle()"></div>
  </div>
</template>

<script>
import gmapsInit from './utils/gmaps'
import themeGrey from './theme/grey.json'
import EventBus from '../../utils/events'

export default {
  name: 'GoogleMap',
  data () {
    return {
      bounds: null,
      isLoad: false,
      google: null,
      gmap: null,
      autoComplete: null,
      geocoder: null,
      markersOnMap: [],
      mapName: `${this.name.replace(/\s+/g, '-')}-gmap`
    }
  },
  props: {
    name: {
      type: String,
      default: 'main'
    },
    lat: {
      type: Number || String,
      default: 45.50884
    },
    lon: {
      type: Number || String,
      default: -73.58781
    },
    zoom: {
      type: Number,
      default: 3
    },
    fitToBounds: {
      type: Boolean,
      default: false
    },
    mapWidth: {
      type: String,
      default: '100%'
    },
    mapHeight: {
      type: String,
      default: '600px'
    },
    customClass: {
      type: String,
      default: 'google-map'
    },
    hiddenMarkers: {
      type: Array,
      default: () => []
    },
    markers: {
      type: Array,
      default: () => []
    },
    zoomControl: {
      type: Boolean,
      default: true
    },
    mapTypeControl: {
      type: Boolean,
      default: true
    },
    scaleControl: {
      type: Boolean,
      default: true
    },
    streetViewControl: {
      type: Boolean,
      default: true
    },
    rotateControl: {
      type: Boolean,
      default: true
    },
    fullscreenControl: {
      type: Boolean,
      default: true
    },
    markerEventClick: {
      type: Function,
      default: () => {}
    },
    pins: {
      type: Object,
      default: () => ({
        default: require('./assets/default.png'),
        active: require('./assets/active.png')
      })
    },
    centerMap: {
      type: Object,
      default: () => {}
    },
    controlLoader: {
      type: Boolean,
      default: false
    },
    forceLoader: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'default-google-map'
    },
    addCustomControl: {
      type: Function,
      default: () => {}
    }
  },
  watch: {
    /**
     * Watcher when markers change
     * @returns {Void}
     */
    markers: {
      handler () {
        // if google ap is not init, we stop
        if (!this.gmap) return
        // update the markers
        this.updateMarkers()
      },
      deep: true
    },
    /**
     * Watcher for the map zoom
     * @returns {Void}
     */
    zoom: {
      handler () {
        // if google map is not init, we stop
        if (!this.gmap) return
        // set the google map zoom
        this.gmap.setZoom(this.zoom)
      }
    },
    /**
     * Watcher for center the map
     * @returns {Void}
     */
    centerMap: {
      handler () {
        // if google map is not init, we stop
        if (!this.gmap) return
        // Center the map at position
        this.centerMapAt(this.centerMap)
      }
    },
    /**
     * Watcher if we force the loader controll
     * @returns {Void}
     */
    forceLoader: {
      handler () {
        // if the control loader is not active, we stop
        if (!this.controlLoader) return
        // force the loader value
        this.isLoad = this.forceLoader
      }
    },
    /**
     * Watcher when the map is load
     * @returns {Void}
     */
    isLoad: {
      handler () {
        // if map is load
        if (this.isLoad === true) {
          setTimeout(() => {
            // force refresh
            this.google.maps.event.trigger(this.gmap, 'resize')
          }, 1000)
        }
      }
    },

    $route (to, from) {
      setTimeout(() => {
        this.google.maps.event.trigger(this.gmap, 'resize')
      }, 3500)
    }
  },
  methods: {
    /**
     * Get the google map style
     * @returns {Object}
     */
    gmapStyle () {
      return {
        width: this.mapWidth,
        height: this.mapHeight
      }
    },
    /**
     * Center the map t position
     * @param {Object} position
     * @returns {Void}
     */
    centerMapAt (position) {
      this.gmap.setCenter(position)
    },
    /**
     * Add marker on the map
     * @param {Object} position
     * @param {Object} item
     * @returns {Void}
     */
    addMarkerOnMap (position, item) {
      let goodPin
      switch (item.banner) {
        case 'bumper_to_bumper': {
          goodPin = (item.active) ? this.pins.active : this.pins.default
          break
        }
        case 'uni_pro': {
          goodPin = (item.active) ? this.pins.uni_pro_active : this.pins.uni_pro_default
          break
        }
        case 'auto_select': {
          goodPin = (item.active) ? this.pins.auto_select_active : this.pins.auto_select_default
          break
        }
        default: {
          goodPin = (item.active) ? this.pins.active : this.pins.default
          break
        }
      }

      // create new marker
      const marker = new this.google.maps.Marker({
        position,
        icon: goodPin,
        map: this.gmap
      })

      // add event on new marker
      marker.addListener('click', () => {
        this.markerEventClick({
          gmap: this.gmap,
          marker,
          item
        })
      })

      // create custom data on the marker
      marker.customData = item
      // add the marker on map
      this.markersOnMap.push(marker)
      // extends this bounds to contain the given point
      if (this.fitToBounds) {
        this.bounds.extend(position)
      }

      // if we center the map on this item
      if (item.center && item.center === true) {
        this.centerMapAt(position)
      }

      // if all marker is added on the map
      if (this.markers.length === this.markersOnMap.length) {
        // stop the loader if we don't controll
        if (!this.controlLoader) this.isLoad = true
      }
    },
    /**
     * Geocode an item
     * @param {Object} item
     * @returns {Void}
     */
    geoCodeAddress (item) {
      // geocode the element
      this.geocoder.geocode({ address: item.address }, (results, status) => {
        // is ok
        if (status === 'OK') {
          // add the marker on the map
          this.addMarkerOnMap(
            results[0].geometry.location,
            item
          )
        } else {
          // no result
          if (status === 'ZERO_RESULTS') return
          // Retry after 2sec (OVER_QUERY_LIMIT)
          setTimeout(() => {
            this.geoCodeAddress(item)
          }, 2000)
        }
      })
    },
    /**
     * Add markers
     * @returns {Void}
     */
    addMarkers () {
      // loop on all markers
      this.markers.forEach((item) => {
        // add the marker
        this.addMarker(item)
      })
    },
    /**
     * Add the marker
     * @returns {Void}
     */
    addMarker (item) {
      // if the element have long and lat
      if (Number(item.lat) && Number(item.lon)) {
        // add the marker on the map
        this.addMarkerOnMap(
          new this.google.maps.LatLng(Number(item.lat), Number(item.lon)),
          item
        )
      } else if (item.address) {
        // geocode the element with address for get the long and lat
        this.geoCodeAddress(item)
      }
    },
    /**
     * Remove all markers on the map
     * @returns {Void}
     */
    removeAllMarkers () {
      // loop on all markers on the map
      this.markersOnMap.forEach((item) => {
        // remove on the map
        item.setMap(null)
      })
      // reset all markers on the map
      this.markersOnMap = []
      // reset the bounds
      this.bounds = new this.google.maps.LatLngBounds()
    },
    /**
     * When the markers is updated
     * @returns {Void}
     */
    updateMarkers () {
      this.removeAllMarkers()
      this.addMarkers()

      if (this.markers && this.markers.length > 0) {
        if (this.hiddenMarkers && this.hiddenMarkers.length > 0) {
          this.hiddenMarkers.forEach((marker) => {
            this.bounds.extend(marker.position)
          })
        }

        if (this.fitToBounds) {
          const centerMarkers = this.markers.filter(marker => marker.center)

          if (centerMarkers && centerMarkers.length === 0) {
            this.gmap.fitBounds(this.bounds)
          }
        }
      }
    },
    /**
     * Set the google map theme
     * @returns {Array}
     */
    setGoogleMapTheme () {
      switch (this.theme) {
        case 'grey':
          return themeGrey
        default:
          return []
      }
    },
    /**
     * Prepare the search autocompletion
     * @returns {Void}
     */
    prepareAutoComplete () {
      // if the field existe
      if (document.getElementById('location')) {
        // Create autocomplete for the region ca (CANADA) only
        this.autoComplete = new this.google.maps.places.Autocomplete((
          document.getElementById('location')), {
          types: ['(regions)'],
          componentRestrictions: { country: 'ca' }
        })

        // Add event when the place change
        this.autoComplete.addListener('place_changed', (place) => {
          // send event with the new location
          EventBus.$emit('search-change', { value: this.autoComplete.getPlace().formatted_address })
          EventBus.$emit('update-search-change', { value: this.autoComplete.getPlace().formatted_address })
        })
      }
    }
  },
  async mounted () {
    // get the map on the dom
    const element = document.getElementById(this.mapName)
    this.google = await gmapsInit()

    // Options for google map
    const options = {
      zoom: this.zoom,
      center: new this.google.maps.LatLng(this.lat, this.lon),
      zoomControl: this.zoomControl,
      mapTypeControl: this.mapTypeControl,
      scaleControl: this.scaleControl,
      streetViewControl: this.streetViewControl,
      rotateControl: this.rotateControl,
      fullscreenControl: this.fullscreenControl,
      styles: this.setGoogleMapTheme()
    }

    // create google map
    this.gmap = new this.google.maps.Map(element, options)
    // create the geocoder
    this.geocoder = new this.google.maps.Geocoder()
    // create the bounds instance
    this.bounds = new this.google.maps.LatLngBounds()
    // add custom control on the map
    this.addCustomControl(this.gmap)
    // add all markers on the map
    this.addMarkers()
    // prepare the autocomplete
    // this.prepareAutoComplete()

    this.$nextTick(() => {
      setTimeout(() => {

      }, 2000)
    })
  }
}
</script>

<style lang="scss" scoped>
.google-map {
  margin: 0 auto;
  background: gray;
  height: 50vh !important;

  @include breakpoint('lg'){
    height: inherit !important;
  }
  
}

.min-size {
  min-height: 50rem;
}
</style>