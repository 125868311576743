<template>
  <section class="section-cards" :class="type ? '' + type : '' ">
    <div class="row no-gutters">
      <Cards 
        v-for="(element, index) in elements"
        :key="index"
        :uKey="index"
        :iconCard="element.icon"
        :imageCard="element.imageCard"
        :titleCard="element.title"
        :supTitle="element.supTitle"
        :contentCard="element.content"
        :ctaCard="element.cta"
        :type="`${element.type} col-lg-4 col-md-6`"
        :theme="element.theme"
        :videoId="element.videoId"
        :subTitleCard="element.subTitleCard"
        :hasFullBorder="element.hasFullBorder"
        :isPromotion="element.isPromotion"
      />
    </div>
  </section>
</template>

<script>
import Cards from './Cards.vue'

export default {
  name: 'SectionCategoryService',
  components: {
    Cards
  },
  props: {
    type: {
      type: String,
      default: 'classic'
    },
    theme: {
      type: String,
      default: 'white'
    },
    elements: {
      type: Array,
      default: null
    }
  }
}
</script>
