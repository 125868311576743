<template>
  <section class="custom-filter">
    <nav class="custom-filter__wrapper row no-gutters">
      <div class="spacer spacer__red col-lg-1"></div>

      <div class="__nav-element col-lg-1">
        <a href="#" class="active" @click.prevent="activeFilter('product', $event)">
          {{ $t("generalUi.product") }}
        </a>
      </div>

      <div class="__nav-element col-lg-1">
        <a href="#" class="" @click.prevent="activeFilter('brand', $event)">
          {{ $t("generalUi.brand") }}
        </a>
      </div>
    </nav>
  </section>
</template>

<script>
import EventBus from '../../utils/events'

export default {
  name: 'BrandFilter',
  data () {
    return {
      filterActif: true
    }
  },
  methods: {
    /**
     * Active the product/brand filter
     * @param {String} name
     * @param {Object} $event
     * @returns {Void}
     */
    activeFilter (name, $event) {
      // if is brand filter
      if (name === 'brand') {
        // filter is active
        this.filterActif = true
        // display the brands and hide products
        EventBus.$emit('active-brand-in-product', { value: { class: 'tab_product', display: 'none' }})
        EventBus.$emit('active-brand-in-product', { value: { class: 'tab_brand', display: 'flex' }})
      } else {
        // is the product
        this.filterActif = false
        // display the products and hide the brands
        EventBus.$emit('active-brand-in-product', { value: { class: 'tab_product', display: 'flex' }})
        EventBus.$emit('active-brand-in-product', { value: { class: 'tab_brand', display: 'none' }})
      }

      // disable active class on all filter button
      const links = document.querySelectorAll('.__nav-element a')
      links.forEach((element) => {
        element.classList.remove('active')
      })

      // add the active class on the current filter button
      $event.target.classList.add('active')
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-filter {
  border-bottom: 1px solid $grey-light;
}
.spacer {
  &__red {
    background-color: $red;
  }
  display: none;

  @include breakpoint("lg") {
    display: block;
  }
}
.__nav-element {
  border-right: 1px solid $grey-light;

  a {
    height: 7rem;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    &.active,
    &:hover {
      color: white;
      background-color: $menu-active;
    }
  }
}
</style>
