import uuidv4 from '../../utils/uuidv4'
import Common from './common'

export default {
  /**
   * Builder for the breadcrumbs
   * @param {Object} data
   * @param {String} fullUrl
   * @returns {Object}
   */
  build (data, fullUrl, menuTitle, menuType) {
    const elements = []
    data.forEach((element) => {
      let title = ''
      if (element.name) {
        title = element.name
      } else if (element.page_title) {
        title = element.page_title
      }
      elements.push({
        key: element.entity_id,
        icon: Common.Icon.buildIcon(element.icon),
        slug: `${fullUrl}/${element.slug}`,
        name: title
      })
    })
    return {
      id: `breadcrumbs_${uuidv4()}`,
      component: 'Breadcrumbs',
      props: {
        elements,
        menuTitle,
        menuType
      }
    }
  }
}
