export default {
  state: {
    navigationEn: [],
    navigationFr: []
  },

  mutations: {
    SET_NAVIGATION: (state, { navigationEn, navigationFr }) => {
      state.navigationEn = navigationEn
      state.navigationFr = navigationFr
    },
    SET_SITE_MAP: (state, { sitemap }) => {
      state.sitemap = sitemap
    }
  },

  getters: {
    // eslint-disable-next-line
    getNavigation: state => lang => {
      return (lang && lang === 'fr-CA') ? state.navigationFr : state.navigationEn
    },
    getCountNavigation: state => lang => ((lang && lang === 'fr-CA') ? state.navigationFr.length : state.navigationEn.length),
    getSiteMap: state => state.sitemap
  },

  actions: {
    setNavigations ({ commit }, { navigationEn, navigationFr }) {
      commit('SET_NAVIGATION', { navigationEn, navigationFr })
    },
    setSiteMap ({ commit }, { sitemap }) {
      commit('SET_SITE_MAP', { sitemap })
    }
  }
}

