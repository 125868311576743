import gql from 'graphql-tag'

export const GET_ALL_SERVICES = gql`
  query {
    services {
      name
      entity_id
    }
  }
`

export const GET_EN_SERVICES_CATEGORIES = `
    pageEn: services_categories(locale: "en-CA", site_id: "$site_id", slug: "$slug") {
      components
      slug
      full_url
      entity_id
      meta_data
      child_categories
      pre_footer_components
    }
`

export const GET_FR_SERVICES_CATEGORIES = `
    pageFr: services_categories(locale: "fr-CA", site_id: "$site_id", slug: "$slug") {
      components
      slug
      full_url
      entity_id
      meta_data
      child_categories
      pre_footer_components
    }
`

export const GET_EN_SERVICES_CATEGORIES_PREVIEW = `
    pageEn: services_categories(locale: "en-CA", site_id: "$site_id",  entity_id: "$entity", preview: true) {
      components
      slug
      full_url
      entity_id
      meta_data
      child_categories
      pre_footer_components
    }
`

export const GET_FR_SERVICES_CATEGORIES_PREVIEW = `
    pageFr: services_categories(locale: "fr-CA", site_id: "$site_id", entity_id: "$entity", preview: true) {
      components
      slug
      full_url
      entity_id
      meta_data
      child_categories
      pre_footer_components
    }
`
