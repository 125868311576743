<template>
  <section
    class="cta-full-width container-fluid"
    :class="bkgColor ? bkgColor : ''"
  >
    <div class="row">
      <div class="col text-center">
        <button
          v-if="ctaFullWidthData.is_modal && checkMobile"
          class="btn"
          :class="ctaFullWidthData.type ? 'btn__' + ctaFullWidthData.type : 'btn__inverted' "
          @click="openPdfModal()"
        >
          {{ ctaFullWidthData.title ? ctaFullWidthData.title : $t('generalUi.learnMore')}}
        </button>

        <b-link
          v-else
          :href="ctaFullWidthData.link"
          class="btn"
          :class="ctaFullWidthData.type ? 'btn__' + ctaFullWidthData.type : 'btn__inverted' "
          :target="ctaFullWidthData.targetBlank ? '_blank' : '_self'"
        >
          {{ ctaFullWidthData.title ? ctaFullWidthData.title : $t('generalUi.learnMore')}}
        </b-link>
      </div>
    </div>

    <ModalPdf :random-id="randomIndex" :pdfSrc="ctaFullWidthData.link" />
  </section>
</template>

<script>
import ModalPdf from './ModalPdf.vue'

export default {
  name: 'CtaFullWidth',
  components: {
    ModalPdf
  },
  props: {
    ctaFullWidthData: {
      type: Object,
      default: ''
    },
    bkgColor: {
      type: String,
      default: null
    },
    theme: {
      type: String,
      default: 'white'
    }
  },
  data () {
    return {
      /**
       * generate a random numer for the modal pdf since we
       * have any unique id
       */
      randomIndex: Math.floor(Math.random() * 100)
    }
  },
  methods: {
    /**
     * Open the modal pdf modal with the random index number
     */
    openPdfModal () {
      this.$bvModal.show(`b2b-modal-pdfviewer-${this.randomIndex}`)
    },
    /**
     * Detect if mobile browser
     */
    checkMobile () {
      if (navigator.userAgentData.mobile) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.cta-full-width {
  padding-top: 4.4rem;
  padding-bottom: 4.4rem;
  background-color: $grey-b2b;
}
</style>
