var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg single-card",class:[
    _vm.type ? _vm.type : '',
    _vm.theme ? _vm.theme : '',
    _vm.colType === '4cols' && _vm.type.includes('__image-only') ? 'col-lg-2 no-borders' : '',
    _vm.iconCard == null && _vm.imageCard == null ? '__squarred' : '',
    _vm.iconCard ? 'icon-card' : '',
    _vm.isPromotion ? 'isPromotion' : '',
    _vm.noBorder ? '' : '__has-border-full',
    _vm.textAlignClass ]},[(_vm.isCategoryTile && !_vm.isPromotion)?_c('div',{staticClass:"wrapper category-tile",class:_vm.titleCard ? '' : '__logo-only'},[(_vm.btnCta)?_c('b-link',{staticClass:"__link-wrapper",attrs:{"to":_vm.btnCta.link,"target":_vm.btnCta.target ? _vm.btnCta.target : '_self'}},[(_vm.imageCard)?_c('figure',[_c('img',{attrs:{"loading":"lazy","src":_vm.imageCard,"alt":""}})]):_vm._e(),(_vm.titleCard || _vm.contentCard)?_c('div',{staticClass:"desc"},[(_vm.titleCard)?_c('h3',{class:[
              _vm.type.includes('__panel-inverted') ? 'white' : '',
              _vm.biggerTitle ? 'biggerTitle' : ''
              ],domProps:{"innerHTML":_vm._s(_vm.titleCard)}}):_vm._e(),(_vm.contentCard)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.contentCard)}}):_vm._e()]):_vm._e()]):_vm._e()],1):(_vm.videoId)?_c('div',{staticClass:"wrapper"},[_c('IframeVideo',{attrs:{"videoId":_vm.videoId}})],1):_c('div',{staticClass:"wrapper",class:_vm.isPromotion ? 'isPromotion' : ''},[(_vm.iconCard)?_c('div',{staticClass:"icon icon__wrapper icon__lg",domProps:{"innerHTML":_vm._s(_vm.iconCard)}}):_vm._e(),_c('div',{staticClass:"content"},[(_vm.imageCard)?_c('div',{staticClass:"figure-wrapper"},[_c('div',{staticClass:"figure-inner"},[_c('figure',[_c('img',{staticClass:"o-object-fit",attrs:{"loading":"lazy","src":_vm.imageCard,"alt":""}})])])]):_vm._e(),(!_vm.type.includes('__image-only'))?_c('div',{staticClass:"desc"},[(_vm.date)?_c('p',{staticClass:"date",domProps:{"innerHTML":_vm._s(_vm.date)}}):_vm._e(),(_vm.supTitle)?_c('p',{staticClass:"sup_title"},[_vm._v(_vm._s(_vm.supTitle))]):_vm._e(),(_vm.titleCard)?_c('h3',{class:[
              _vm.type.includes('__panel-inverted') ? 'white' : '',
              _vm.biggerTitle ? 'biggerTitle' : ''
            ],domProps:{"innerHTML":_vm._s(_vm.titleCard)}}):_vm._e(),(_vm.subTitleCard)?_c('h4',{staticClass:"sub-title"},[_vm._v(" "+_vm._s(_vm.subTitleCard)+" ")]):_vm._e(),(_vm.contentCard)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.contentCard)}}):_vm._e()]):_vm._e()]),(_vm.btnCta && _vm.btnCta.is_modal)?_c('div',{staticClass:"single-card__footer"},[_c('b-link',{staticClass:"btn",class:_vm.theme != 'black' ? 'btn__inverted' : 'btn__white',on:{"click":function($event){return _vm.openPdfModal()}}},[_vm._v(_vm._s(_vm.btnCta.title))])],1):_vm._e(),(_vm.btnCta && _vm.btnCta !== null && !_vm.btnCta.is_modal)?_c('div',{staticClass:"single-card__footer"},[(_vm.btnCta && _vm.isExternal(_vm.btnCta.link))?_c('b-link',{staticClass:"btn",class:_vm.theme != 'black' ? 'btn__inverted' : 'btn__white',attrs:{"href":_vm.btnCta.link,"target":_vm.btnCta.target ? _vm.btnCta.target : '_self'}},[_vm._v(_vm._s(_vm.btnCta.title))]):_vm._e(),(_vm.btnCta && !_vm.isExternal(_vm.btnCta.link))?_c('b-link',{staticClass:"btn",class:_vm.theme != 'black' ? 'btn__inverted' : 'btn__white',attrs:{"to":_vm.btnCta.link,"target":_vm.btnCta.target ? _vm.btnCta.target : '_self'}},[_vm._v(_vm._s(_vm.btnCta.title))]):_vm._e()],1):_vm._e()]),_c('ModalPdf',{attrs:{"random-id":_vm.randomIndex,"pdfSrc":_vm.btnCta.link}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }