import Vue from 'vue'
import VueI18n from 'vue-i18n'
import frContent from './lang/fr'
import enContent from './lang/en'

Vue.use(VueI18n)

const supportedLanguages = ['fr-CA', 'en-CA']

const storedLanguage = JSON.parse(localStorage.getItem('vuex'))

let locale = 'en-CA'

if (
  storedLanguage &&
  storedLanguage.user &&
  storedLanguage.user.lang &&
  supportedLanguages.indexOf(storedLanguage.user.lang) > -1
) {
  locale = storedLanguage.user.lang
}

export default new VueI18n({
  locale,
  messages: {
    'fr-CA': frContent,
    'en-CA': enContent
  }
})
