<template>
  <section class="contact-us">
    <form class="row no-gutters" action="" @submit="checkForm()" @submit.prevent>
      <div class="col-12 col-lg-6">
        <div class="wrapper">
          <p>{{ formTitle }}</p>
          <div class="input-wrapper" :class="{ '__error': $v.name.$error }">
            <input type="text" v-model.trim="$v.name.$model" :placeholder="$t('generalUi.name')">
            <p class="error" v-if="!$v.name.required">{{ $t('formValidation.required') }}</p>
            <p class="error" v-if="!$v.name.minLength">{{ $t('formValidation.minLength', { number : $v.name.$params.minLength.min }) }}</p>
          </div>
          <div class="input-wrapper" :class="{ '__error': $v.phone.$error }">
            <input type="text" v-model.trim="$v.phone.$model" :placeholder="$t('generalUi.phone')">
            <p class="error" v-if="!$v.phone.required">{{ $t('formValidation.required') }}</p>
            <p class="error" v-if="!$v.phone.phoneValidation">{{ $t('formValidation.phone') }}</p>
            <p class="error" v-if="!$v.phone.minLength">{{ $t('formValidation.minLength', { number : $v.phone.$params.minLength.min }) }}</p>
          </div>
          <div class="input-wrapper" :class="{ '__error': $v.email.$error }">
            <input type="text" v-model.trim="$v.email.$model"  :placeholder="$t('generalUi.email')">
            <p class="error" v-if="!$v.email.required">{{ $t('formValidation.required') }}</p>
            <p class="error" v-if="!$v.email.email">{{ $t('formValidation.email') }}</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 textarea-wrapper" >
        <div class="textarea-inner input-wrapper" :class="{ '__error': $v.msg.$error }">
          <label>Message</label>
          <textarea v-model.trim="$v.msg.$model" />
          <p class="error" v-if="!$v.email.required">{{ $t('formValidation.required') }}</p>
        </div>
      </div>
      <div class="col-12 offset-lg-1 col-lg-10 submit-wrapper text-right">
          <button type="submit" value="" class="btn btn__inverted">
            <span v-if="submitStatus === 'PENDING'">{{$t('generalUi.submiting')}}</span>
            <span v-if="submitStatus === 'OK'">{{$t('generalUi.submited')}}</span>
            <span v-if="submitStatus === 'NOTOK'">{{$t('generalUi.submitedError')}}</span>
            <span v-if="submitStatus === 'READY'">{{$t('generalUi.send')}}</span>
          </button>
        </div>
    </form>
  </section>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators'
import { SEND_SEND_GRID } from '../../graphql/mutations/sendGrid'
import phoneValidation from '../../utils/phoneValidation'
import EventBus from '../../utils/events'

export default {
  name: 'SectionContactForm',
  data () {
    return {
      name: '',
      phone: '',
      email: '',
      msg: '',
      submitStatus: 'READY',
      formTitle: this.$t('generalUi.writeToUs'),
      toMail: '',
      shop: null,
      store: null
    }
  },
  props: {
    customMail: {
      type: String,
      default: null
    },
    contactTitle: {
      type: String,
      default: null
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(2)
    },
    phone: {
      required,
      minLength: minLength(10),
      phoneValidation
    },
    email: {
      required,
      email
    },
    msg: {
      required
    }
  },
  methods: {
    /**
     * Validate and send the form
     * @returns {Void}
     */
    checkForm () {
      this.$v.$touch()
      // if form is invalide
      if (this.$v.$invalid) {
        // update the status
        this.submitStatus = 'READY'
      } else {
        // do your submit logic here
        this.submitStatus = 'PENDING'

        // format the data
        const msg = {
          to: this.toMail,
          from: `${this.email}`,
          subject: `Auto-select - Prise de contact de ${this.name}`,
          html: `
                    <strong>${this.name}</strong> <br>
                    <strong>${this.phone}</strong> <br>
                    <strong>${this.msg}</strong> <br>
                `
        }

        // send the data
        this.$apollo.query({
          query: SEND_SEND_GRID,
          variables: msg
        }).then((response) => {
          this.submitStatus = 'OK'
          setTimeout(() => {
            this.submitStatus = 'READY'

            this.name = ''
            this.phone = ''
            this.msg = ''
            this.email = ''

            this.$v.$reset()
          }, 2500)
        }).catch((error) => {
          this.submitStatus = 'NOTOK' // eslint-disable-next-line
          console.log('error', error)
        })
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      const getSlug = this.$route.params.pathMatch

      if (getSlug.includes('shop') || getSlug.includes('atelier')) {
        this.shop = this.$store.getters.getShopBySlug(this.$route.path.split('/')[this.$route.path.split('/').length - 1])

        if (this.shop.email_for_website && this.shop.email_for_website !== '') {
          this.toMail = this.shop.email_for_website
        } else {
          // set the destination mail
          this.toMail = this.customMail
        }
      } else {
        this.store = this.$store.getters.getStoreBySlug(this.$route.path.split('/')[this.$route.path.split('/').length - 1])
        if (this.store.email_for_website && this.store.email_for_website !== '') {
          this.toMail = this.store.email_for_website
        } else {
          // set the destination mail
          this.toMail = this.customMail
        }
      }

      // set the form title if is in props, overwise use the i18n default title
      this.formTitle = (this.contactTitle) ? this.contactTitle : this.formTitle

      // When we update the to email
      EventBus.$on('contact-update-to-mail', (params) => {
        this.toMail = params.value
      })
    })
  }
}
</script>

<style lang="scss" scoped>
.contact-us{
  .has__border {
      border-right: 1px solid $grey-light;
  }
  .wrapper{
      padding: 8rem 8.33333vw 0;

      p{
          font-weight: 600;
          font-size: 2.1rem;
          margin-bottom: 2.5rem;
      }
  }

  .textarea-wrapper{
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end;
    align-items: flex-end;
    margin-bottom: 0;
    margin-top: 2rem;

    @include breakpoint('lg'){
      margin-top: 0;
    }

    label{
      margin-bottom: 1rem;
      display: block;
      opacity: 0.7;
    }

    .textarea-inner{
      padding: 0 8.33333vw;
      width: 100%;
      position: relative;
      bottom: -4px;

      &.__error{
        textarea{
          border-color: $red;
        }
      }
    }

    textarea{
      width: 100%;
      padding: 2rem;
      border: 1px dashed $grey-light;
      resize: none;
      outline: none;
      height: 15.6rem;
      box-shadow: none;
      -webkit-appearance: none;
    }
  }

  .submit-wrapper{
      height: 8.5rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 3rem;

      @include breakpoint('lg'){
        padding: 0;
      }
  }
}
</style>
