import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import site from './site'
import user from './user'
import store from './store'
import navigations from './navigations'
import page from './page'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    site,
    user,
    store,
    navigations,
    page
  },
  plugins: [createPersistedState({
    paths: ['store.selectedShop', 'user', 'page', 'site']
  })]
})
