import EventBus from '../utils/events'

export default {
  data () {
    return {
      colContact: [],
      colServices: [],
      userPosition: this.$store.getters.getMyPosition,
      shopNotFound: false,
      shop: (this.displaySelect)
        ? this.$store.getters.getShopBySlug(this.$route.path.split('/')[this.$route.path.split('/').length - 1])
        : this.$store.getters.getStoreBySlug(this.$route.path.split('/')[this.$route.path.split('/').length - 1])
    }
  },
  methods: {
    /**
     * Get the current shop
     * @returns {Object}
     */
    getShop () {
      return this.shop ? this.shop : {}
    },
    /**
     * Prepare the block map with contact and services
     * @returns {Void}
     */
    prepareDataMap () {
      // get the current shop
      const shopProps = this.getShop()
      // All props to exclude
      const excludeProps = [
        'id',
        'garage_type',
        'name',
        'name_for_website',
        'email',
        'lat',
        'lon',
        'active',
        'center',
        'postal_code',
        'country',
        'region',
        'city',
        'description_fr',
        'description_en',
        'slug'
      ]

      // loop on all props
      // eslint-disable-next-line array-callback-return
      Object.keys(shopProps).map((objectKey) => {
        // if the props is not exclude
        if (!excludeProps.includes(objectKey)) {
          // get the value
          const propsValue = shopProps[objectKey]
          // method to add
          let addLine = false
          let isString = false
          let isArray = false
          let isLink = false
          let isPhone = false

          // switch on key for the build option
          switch (objectKey) {
            case 'address':
              this.colContact.push({
                key: objectKey,
                val: `<p>
                    ${propsValue}<br>
                    ${(shopProps.city) ? shopProps.city : ''}, ${(shopProps.postal_code) ? shopProps.postal_code : ''} ${(shopProps.region) ? shopProps.region : ''} 
                  </p>`,
                isHtml: true
              })
              break
            case 'services':
              this.colServices.push({
                key: objectKey,
                val: propsValue
              })
              break
            case 'email_for_webiste':
              addLine = false
              if (typeof propsValue === 'string' && propsValue.replace(/ /g, '') !== '' && propsValue.replace(/ /g, '') !== 'null') {
                EventBus.$emit('contact-update-to-mail', { value: propsValue })
              }
              break
            case 'website_link':
            case 'facebook_link':
              if (typeof propsValue === 'string' && propsValue.replace(/ /g, '') !== '' && propsValue.replace(/ /g, '') !== 'null') {
                addLine = true
                isLink = true
              }
              break
            case 'phone':
            case 'fax':
              if (typeof propsValue === 'string' && propsValue.replace(/ /g, '') !== '' && propsValue.replace(/ /g, '') !== 'null') {
                addLine = true
                isPhone = true
              }
              break
            case 'banner':
              addLine = false
              break
            default:
              if (typeof propsValue === 'string' && propsValue.replace(/ /g, '') !== '' && propsValue.replace(/ /g, '') !== 'null') {
                addLine = true
                isString = true
              }

              if (Array.isArray(propsValue)) {
                addLine = true
                isArray = true
              }
              break
          }

          // if we add the line
          if (addLine) {
            this.colContact.push({
              key: objectKey,
              val: propsValue,
              isArray,
              isString,
              isLink,
              isPhone
            })
          }
        }
      })

      // if we have the shop position
      if (shopProps.lat && shopProps.long) {
        this.colContact.push({
          key: 'directions',
          val: `
            <a target="_blank" id="getMyDirections" href="${this.getMyDirections(shopProps)}" >
              <button type="button" class="btn btn-outline-primary">${this.$t('pages.singleShop.directions')}</button>
            </a>
          `,
          isHtml: true
        })
      }
    },
    /**
     * Get the url for the direction buttton
     * @param {Object} shop
     * @returns {String}
     */
    getMyDirections (shop) {
      // create user position
      const user = {
        lat: (this.userPosition.latitude) ? this.userPosition.latitude : '',
        lon: (this.userPosition.longitude) ? this.userPosition.longitude : ''
      }

      // if the store / shop have lat/lon
      if (shop.lat && shop.long) {
        // create the link
        return `https://www.google.com/maps/dir/${user.lat},${user.lon}/${shop.lat},${shop.long}/@${shop.lat},${shop.long},12z`
      }

      return '#'
    },
    /**
     * Check the navigator permision for use the geoloc
     * @returns {Void}
     */
    checkUserPosition () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((userLocation) => {
          // update user position
          this.$store.dispatch('updateUserPosition', { position: userLocation.coords })
        })
      }
    },
    /**
     * Update the direction link when the user position change
     * @returns {Void}
     */
    updateDirectionLink () {
      // if the link existe
      if (document.getElementById('getMyDirections')) {
        // update the link
        document.getElementById('getMyDirections').href = this.getMyDirections(this.getShop())
      }
    }
  },
  mounted () {
    // set if the shop was found or not
    this.shopNotFound = !this.shop
    // if is not found
    if (this.shopNotFound) {
      // redirect to 404 page
      const path = (this.$i18n.locale === 'en-CA') ? '/en/404/' : '/fr/404/'
      this.$router.replace({
        path,
        query: {}
      })
    }

    // check the navigator position
    this.checkUserPosition()

    // Watcher when the user position change
    this.$store.watch(
      (state, getters) => getters.getMyPosition,
      (newValue, oldValue) => {
        if (oldValue !== newValue) {
          this.userPosition = newValue
        }
        // update the direction link
        this.updateDirectionLink()
      }
    )
  }
}
