<template>
  <section>
      <div class="alert-bar" :class="theme ? '__yellow-theme' : ''" v-if="displayAlert">
        <div class="content" v-html="text"></div>
        <button class="cross" @click="closeAlert()">
          <svg aria-hidden="true" data-prefix="fal" data-icon="times" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="icon icon__">
            <path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"/>
          </svg>
        </button>
      </div>
  </section>
</template>

<script>
export default {
  name: 'Alert',
  data () {
    return {
      displayAlert: this.show
    }
  },
  props: {
    variant: {
      type: String,
      default: 'danger'
    },
    text: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    },
    theme: {
      default: false
    }
  },
  methods: {
    /**
     * When the user close the alert message
     * @returns {Void}
     */
    closeAlert () {
      // hide alert
      this.displayAlert = false
      // Dispatch the new alert state on the store
      this.$store.dispatch('setDisplay', {
        isDisplay: false,
        lang: this.$i18n.locale
      })
    }
  }
}
</script>

<style scoped>
.alert-bar{
  background-color: #efefef;
  border: none;
  border-radius: none;
  color: currentColor;
  width: 100vw;
  min-height:4rem;
  padding: 1rem 4rem 0rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
  position: relative;

}
.__yellow-theme{
  background-color: #FFF000;
}
.content{
  display: flex;
  align-content: center;
  align-items: center;
  height: 57px;
  min-height: 6rem;
}
.content /deep/ p{
  margin: 0 !important;
  font-size: 1.4rem;
}
.cross{
  background:none;
  border:none;
  outline:none;
  cursor:pointer;
  opacity:0.6;
  margin:0;
  padding:0;

  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
}
.cross svg{
  height: 2.8rem;
}
@media only screen and (min-width:1025px) { 
  .alert-bar{
    padding: 1rem 5.5rem 0rem;
    /* min-height: 75px; */
  }
	.cross{
    right: 2rem;
  }
}
</style>
