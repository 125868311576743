const axios = require('axios')

export default {
  state: {
    ip: null,
    position: null,
    geolocationError: false,
    region: 'global',
    regionName: 'global',
    langIsDefined: false,
    lang: 'en-CA',
    country: 'Canada'
  },

  mutations: {
    SET_PUBLIC_IP: (state, { ip }) => {
      state.ip = ip
    },
    SET_POSITION: (state, { position }) => {
      state.position = position
    },
    SET_REGION: (state, { region }) => {
      state.region = region
    },
    SET_REGION_NAME: (state, { regionName }) => {
      state.regionName = regionName
    },
    SET_GEOLOCATION_ERROR: (state, { geolocationError }) => {
      state.geolocationError = geolocationError
    },
    SET_DEFINED_LANG: (state, { langIsDefined }) => {
      state.langIsDefined = langIsDefined
    },
    SET_LANG: (state, { lang }) => {
      state.lang = lang
    },
    SET_COUNTRY: (state, { country }) => {
      state.country = country
    }
  },

  getters: {
    getPublicIp (state) {
      return state.ip
    },
    getMyRegion (state) {
      return state.region
    },
    getMyRegionName (state) {
      return state.regionName
    },
    getMyPosition (state) {
      return state.position
    },
    getGeolocationError (state) {
      return state.geolocationError
    },
    myLangIsDefined (state) {
      return state.langIsDefined
    },
    getLang (state) {
      return state.lang
    },
    getCountry (state) {
      return state.country
    }
  },

  actions: {
    async setPublicIp ({ commit }) {
      axios.get(`https://pro.ip-api.com/json/?fields=61439&key=${global.env.decrypt('VUE_APP_GEOIP_API_KEY')}`)
        .then((response) => {
          const position = {
            latitude: response.data.lat,
            longitude: response.data.lon
          }

          commit('SET_PUBLIC_IP', { ip: response.data.query })
          commit('SET_POSITION', { position })
          commit('SET_GEOLOCATION_ERROR', { geolocationError: false })

          if (response.data.country === 'Canada') {
            commit('SET_REGION', { region: response.data.region })
          }
          if (response.data.regionName) {
            commit('SET_REGION_NAME', { regionName: response.data.regionName })
          }
        })
        .catch(() => {
          commit('SET_PUBLIC_IP', { ip: null })
          commit('SET_POSITION', { position: null })
          commit('SET_GEOLOCATION_ERROR', { geolocationError: true })
          commit('SET_REGION', { region: 'global' })
          commit('SET_REGION_NAME', { regionName: 'global' })
        })
    },
    setMyLangIsDefined ({ commit }, { langIsDefined }) {
      commit('SET_DEFINED_LANG', { langIsDefined })
    },
    setLang ({ commit }, { lang }) {
      commit('SET_LANG', { lang })
    },
    updateUserPosition ({ commit }, { position }) {
      commit('SET_POSITION', { position })
    }
  }
}

