<template>
<div class="input-container" v-if="displayGlobalAdvanceSearch" >
  <div class="row">
    <div class="col-sm-12 col-lg-auto" >
      <b-form-input
        id="location"
        v-model="inputModel"
        class="location-input"
        :placeholder="placeholder"
        @keyup.enter="inputCpChange"
      ></b-form-input>
      <div class="icon-search" @click="inputCpChange"><svg data-v-146e2983="" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="icon icon-search"><path data-v-146e2983="" fill="currentColor" d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z"></path></svg></div>
    </div>
    <div class="col-sm-12 col-lg-auto d-flex align-items-center or">
       - {{$t('pages.findShop.or')}} -
    </div>
    <div class="col-sm-12 col-lg" >
      <b-form-input
        v-model="inputModelName"
        class="location-input"
        :placeholder="$t('pages.findShop.placeHolderName')"
        @keyup.enter="inputNameChange"
      >
      </b-form-input>
      <div class="icon-search" @click="inputNameChange"><svg data-v-146e2983="" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="icon icon-search"><path data-v-146e2983="" fill="currentColor" d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z"></path></svg></div>
    </div>
  </div>
</div>
</template>

<script>
import EventBus from '../../utils/events'

export default {
  name: 'SearchCity',
  data () {
    return {
      displayGlobalAdvanceSearch: true,
      inputModel: null,
      inputModelName: null
    }
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    btnText: {
      type: String,
      default: ''
    }
  },
  computed: {
    setCtaLabel () {
      const getSlug = this.$route.params.pathMatch

      if (getSlug.includes('shop') || getSlug.includes('atelier')) {
        return this.$t('pages.findShop.findShopLabel')
      }

      return this.$t('pages.findShop.title')
    }
  },
  methods: {
    /**
     * If the search input is not null or empty
     * @returns {Boolean}
     */
    locationState () {
      return (this.inputModel !== null && this.inputModel !== 'undefined' && this.inputModel.replace(/ /g, '') !== '')
    },
    /**
     * When the input value change
     * @returns {Void}
     * OLD MEHTOD with new things comming we keep it here
     */

    // inputCpChange () {
    //   if (this.inputModel !== null && this.inputModel !== 'undefined') {
    //     EventBus.$emit('search-change', { value: this.inputModel })
    //   }
    // },
    // This method  is just for filtering result from cp or city.
    inputCpChange () {
      if (this.inputModel !== null && this.inputModel !== 'undefined') {
        EventBus.$emit('search-cp-change', { value: this.inputModel })
      }
    },
    /**
     * When the input value change
     * @returns {Void}
     */
    inputNameChange () {
      if (this.inputModelName !== null && this.inputModelName !== 'undefined') {
        EventBus.$emit('search-name-change', { value: this.inputModelName })
      }
    },
    /**
     * when the user click on the button
     * @returns {Void}
     */
    findShopCallback () {
      EventBus.$emit('search-click-callback')
    }
  },
  mounted () {
    if (this.$store.getters.getLocationAddress !== undefined) {
      this.inputModel = this.$store.getters.getLocationAddress
    }

    // When we get event for show hide the search input
    EventBus.$on('display-global-advanced-search', (params) => {
      this.displayGlobalAdvanceSearch = params.value
    })

    // When we update the search value
    EventBus.$on('update-search-change', (params) => {
      if (this.inputModel !== null && this.inputModel !== 'undefined') {
        this.inputModel = params.value
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.location-input {
  border: none;
  border-bottom: 1px solid #707070;
  font-size: 2.1rem;
  border-radius: 0;
  outline-style: none;
  box-shadow: none;
  padding-left: 0;
  padding-right: 30px;
  height: 4rem;
  &.form-control.is-valid{
    background-image: url('~@/assets/images/check-symbol.svg');
  }
}
.location-input:focus {
  border: none;
  border-bottom: 1px solid #707070;
  border-radius: 0;
  outline-style: none;
  box-shadow: none;
}
.input-container {
  margin-top: 5rem;
  .col-lg{
    position: relative;
  }
  .icon-search{
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    @include breakpoint('lg') { 
      right: 8px;
    }
  }
}
.or{
  margin-top: 10px;
    @include breakpoint('lg') { 
      margin-top: 0;
    }
}
@media (max-width: 991.98px) { 
  .btn-find-shop {
    margin: 2rem;
  }
 }
</style>