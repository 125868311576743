export default {
  state: {
    alert_message_fr: null,
    alert_message_en: null,
    isDisplay_fr: false,
    isDisplay_en: false,
    url: null,
    lang: 'en-CA',
    alert_theme: null,
    mailChimpId_fr: null,
    mailChimpId_en: null
  },

  mutations: {
    SET_SITE: (state, {
      alertMessage, alertTheme, url, isDisplay, lang
    }) => {
      if (lang === 'fr-CA') {
        state.alert_message_fr = alertMessage
        state.isDisplay_fr = isDisplay
      } else {
        state.alert_message_en = alertMessage
        state.isDisplay_en = isDisplay
      }

      state.alert_theme = alertTheme
      state.url = url
    },
    SET_DISPLAY: (state, { isDisplay, lang }) => {
      if (lang === 'fr-CA') {
        state.isDisplay_fr = isDisplay
      } else {
        state.isDisplay_en = isDisplay
      }
    },
    SET_MAILCHIMPID: (state, { mailChimpId, lang }) => {
      if (lang === 'fr-CA') {
        state.mailChimpId_fr = mailChimpId
      } else {
        state.mailChimpId_en = mailChimpId
      }
    }
  },

  getters: {
    // eslint-disable-next-line
    getAlertMessage: state => lang => {
      return (lang && lang === 'fr-CA') ? state.alert_message_fr : state.alert_message_en
    },
    // eslint-disable-next-line
    getAlertDisplay: state => lang => {
      return (lang && lang === 'fr-CA') ? state.isDisplay_fr : state.isDisplay_en
    },
    // eslint-disable-next-line
    getAlertTheme: state => {
      return state.alert_theme
    },
    // eslint-disable-next-line
    getMailChimpId: (state) => (lang) => {
      return lang && lang === 'fr-CA' ? state.mailChimpId_fr : state.mailChimpId_en
    }
  },

  actions: {
    createSite ({ commit }, {
      alertMessage, alertTheme, url, isDisplay, lang
    }) {
      commit('SET_SITE', {
        alertMessage, alertTheme, url, isDisplay, lang
      })
    },
    setDisplay ({ commit }, { isDisplay, lang }) {
      commit('SET_DISPLAY', { isDisplay, lang })
    },
    setMailChimpId ({ commit }, { mailChimpId, lang }) {
      commit('SET_MAILCHIMPID', { mailChimpId, lang })
    }
  }
}
