var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"hero",class:'hero__' + _vm.heroType + ' __' + _vm.heroTheme},[(_vm.heroBkgVideo)?_c('BackgroundVideo',{attrs:{"videoId":_vm.heroVideoId}}):_vm._e(),(_vm.heroBkg)?_c('figure',{staticClass:"hero__master-bkg __black-overlay",style:(("background-image:url('" + _vm.heroBkg + "')"))}):_vm._e(),(_vm.heroRotatedText)?_c('em',{staticClass:"o-rotated-text o-rotated-text__anticlockwise"},[_vm._v(" "+_vm._s(_vm.heroRotatedText)+" ")]):_vm._e(),_c('div',[_c('div',{staticClass:"hero__wrapper row no-gutters align-items-stretch",class:[
      _vm.vCentered ? 'align-center' : 'align-start',
      'justify-content-' + _vm.hAlignment,
      _vm.hAlignment === 'center' ? 'text-center' : '',
      _vm.hAlignment === 'end' ? 'text-right' : ''
    ]},[_c('div',{staticClass:"panel col-12",class:[
            'justify-content-' + _vm.hAlignment,
            _vm.hAlignment === 'center'|| _vm.hAlignment === 'end' ? 'col-lg-8 col-xl-5' : 'offset-lg-1',
            _vm.hAlignment === 'end' ? 'pull-lg-1' : '',
            _vm.heroImg ? 'col-lg-6' : 'col-lg-5'
          ]},[_c('div',{staticClass:"wrapper",class:_vm.heroImg ? '__more-pr' : ''},[(_vm.heroTitle)?_c('h1',{domProps:{"innerHTML":_vm._s(_vm.heroTitle)}}):_vm._e(),(_vm.heroSubtitle)?_c('h2',[_vm._v(_vm._s(_vm.heroSubtitle))]):_vm._e(),(_vm.heroContent)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.heroContent)}}):_vm._e(),_c('div',[_vm._t("default")],2),(_vm.heroCta && _vm.isExternal(_vm.heroCta.link))?_c('b-link',{class:("btn " + ((_vm.heroTheme === 'black') ? 'btn__inverted' : 'btn__white')),attrs:{"href":_vm.heroCta.link,"target":_vm.heroCta.target ? _vm.heroCta.target : '_self'}},[_vm._v(_vm._s(_vm.heroCta.title))]):_vm._e(),(_vm.heroCta && !_vm.isExternal(_vm.heroCta.link))?_c('b-link',{class:("btn " + ((_vm.heroTheme === 'black') ? 'btn__inverted' : 'btn__white')),attrs:{"to":_vm.heroCta.link,"target":_vm.heroCta.target ? _vm.heroCta.target : '_self'}},[_vm._v(_vm._s(_vm.heroCta.title))]):_vm._e(),(_vm.globalSearch)?_c('algolia-search-hero',{attrs:{"siteID":_vm.siteID}}):_vm._e()],1)]),(_vm.heroImg || _vm.heroBannerType)?_c('div',{staticClass:"hero__img panel col-12 col-lg"},[_c('figure',{},[(_vm.heroBannerType === 'uni_pro' )?_c('img',{staticClass:"unipro o-object-fit",attrs:{"loading":"lazy","src":_vm.uni_pro_bg}}):(_vm.heroBannerType === 'auto_select' )?_c('img',{staticClass:"autoselect o-object-fit",attrs:{"loading":"lazy","src":_vm.autoselect_bg}}):_c('img',{staticClass:"o-object-fit",attrs:{"loading":"lazy","src":_vm.heroImg,"alt":_vm.heroTitle}})])]):_vm._e()])]),_c('a',{staticClass:"o-scroll-to o-scroll-to__hero",attrs:{"href":"#"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }