export const GET_ALL_NAVIGATION = `
    navigationEn: navigations(locale: "en-CA", template_related: true, region: "$region") {
      entity_id
      navigation_id
      element
    }
    navigationFr: navigations(locale: "fr-CA", template_related: true, region: "$region") {
      entity_id
      navigation_id
      element
    }
`

export const GET_EN_NAVIGATION = `
    navigationEn: navigations(locale: "en-CA", site_id:"$site_id", template_related: true, region: "$region") {
      entity_id
      navigation_id
      element
    }
`

export const GET_FR_NAVIGATION = `
    navigationFr: navigations(locale: "fr-CA", site_id:"$site_id", template_related: true, region: "$region") {
      entity_id
      navigation_id
      element
    }
`
