<template>
  <section id="searchOptions" v-if="displayGlobalAdvanceSearch" >
    <div class="search-wrapper row no-gutters">

      <div class="spacer spacer__grey col-lg-1"></div>

      <div class="__nav-element col-lg-2">
        <b-link
          v-b-toggle.find-shop-filter
          class="icon-group-link"
          @click="displayAdvanceSearch = !displayAdvanceSearch"
        >
          <label>{{ $t('pages.findShop.btnAdvancedSearch') }}</label>
          <span class="icon-group">
              <svg
                class="icon icon__sm"
                :class="{ 'arrow-rotate': displayAdvanceSearch }"
                id="arrow-right"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 31.49 31.49"
              >
                <path
                  d="M21.205 5.007a1.112 1.112 0 00-1.587 0 1.12 1.12 0 000 1.571l8.047 8.047H1.111A1.106 1.106 0 000 15.737c0 .619.492 1.127 1.111 1.127h26.554l-8.047 8.032c-.429.444-.429 1.159 0 1.587a1.112 1.112 0 001.587 0l9.952-9.952a1.093 1.093 0 000-1.571l-9.952-9.953z"
                  fill="#9b9b9b"
                />
              </svg>
          </span>
        </b-link>
      </div>
    </div>
    <div class="row no-gutters search-inner">
      <div class="col-12">
        <b-collapse id="find-shop-filter" class="mt-2">
          <div class="row no-gutters">
            <div class="col-10 mx-auto">
              <div class="row mt-5 mb-5">
                <div
                  class="col-12 col-lg-6 col-xl-2" 
                  v-for="service in servicesList" 
                  :key="service.id"
                >
                  <label class="container-checkbox"> {{ displayService(service) }}
                    <input 
                      type="checkbox"
                      :id="`service-${service.id.toString()}`"
                      v-model="localFilter"
                      @change="updateFilters()"
                      :name="`service-${service.id.toString()}`"
                      :value="service.id"
                    >
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row no-gutters" >
            <div class="col-10 mx-auto">
              <a href="#" class="btn mb-5" @click="resetFilter($event)" >{{ $t('pages.findShop.resetFilter') }}</a>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
  </section>
</template>

<script>
import EventBus from '../../utils/events'

export default {
  name: 'AdvancedSearch',
  data () {
    return {
      localFilter: this.filterServices,
      displayAdvanceSearch: false,
      displayGlobalAdvanceSearch: true
    }
  },
  props: {
    servicesList: {
      type: Array,
      default: () => []
    },
    filterServices: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    /**
     * Display the service name by lang
     * @param {Object} item
     * @returns {String}
     */
    displayService (item) {
      switch (this.$i18n.locale) {
        case 'fr-CA':
          return item.label_fr
        default:
          return item.label_en
      }
    },
    /**
     * When we reset the filters
     * @param {Object} event
     * @returns {Void}
     */
    resetFilter (event) {
      // stop the click
      event.preventDefault()
      // reset filter and apply
      this.localFilter = []
      this.updateFilters()
    },
    /**
     * Update the filter list
     * @returns {Void}
     */
    updateFilters () {
      // update the new filter list
      EventBus.$emit('update-selected-services', { value: this.localFilter })
    }
  },
  mounted () {
    // Event for hide the search input
    EventBus.$on('display-global-advanced-search', (params) => {
      this.displayGlobalAdvanceSearch = params.value
    })
  }
}
</script>

<style lang="scss" scoped>
.search-wrapper{
  min-height: 7rem;
}
.search-text-col {
  background-color: $grey-b2b
}
.search-text {
  text-align: center;
  font: Regular 11px/19px Work Sans;
  letter-spacing: -0.22px;
  color: #9B9B9B;
  opacity: 1;
  text-transform: uppercase;
  font-size: 1.5rem;
}
.search-border {
  border: 1px solid $grey-light;
  opacity: 1;
  min-width: 32rem;
  min-height: 7rem;
}
.search-inner{
  border-bottom: 1px solid $grey-light;
  border-top: 1px solid $grey-light;
}
.custom-navigation {
  border-bottom: 1px solid $grey-light;
}
.icon-group-link{
  border-right: 1px solid $grey-light;
  border-left: 1px solid $grey-light;
}
.spacer {
  &__grey{
    background-color: $grey-b2b;
  }
  display: none;

  @include breakpoint("lg") {
    display: block;
  }
}
.icon{
    transition: all 0.3s ease;
}
.arrow-rotate {
  transform: rotate(450deg);
}
.container-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font: SemiBold 18px/21px Work Sans;
  font-size: 19px;
  letter-spacing: -0.36px;
  color: #1F1B1E;
  opacity: 1;
}
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 21px;
  height: 22px;
  border: 1px solid $black;
  opacity: 1;
}
.container-checkbox:hover input ~ .checkmark {
  @include breakpoint('lg'){
    background-color: #ccc;
  }
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}
.container-checkbox .checkmark:after {
  left: 10px;
  top: -15px;
  width: 11px;
  height: 31px;
  border: solid #69676f;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(36deg);
  transform: rotate(45deg);
}
</style>
