<template>
  <div class="loading">
    <div class="loading__wrapper">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>

<!--        Active logo loader-->
<!--        <svg-->
<!--          class="logo"-->
<!--          version="1.0"-->
<!--          xmlns="http://www.w3.org/2000/svg"-->
<!--          width="212"-->
<!--          height="361.33"-->
<!--          viewBox="0 0 159 271"-->
<!--        >-->
          <g fill="#202124">
            <path
              d="M25.5 20.4c-4.1 1.8-9.2 7.7-10.5 12.3-.8 2.7-1 34.6-.8 107.8.3 101.9.3 104.1 2.3 107.4 1.1 1.9 3.7 4.8 5.8 6.5l3.9 3.1h47.3v-30l-10-.5c-11.1-.6-13.7-1.6-16.5-6.3-1.9-3.1-2-5.7-2-82.2s.1-79.1 2-82.2c2.8-4.7 5.4-5.7 16.5-6.3l10-.5v-30L51 19.3c-16.9-.2-23.3.1-25.5 1.1zM88.6 20.6c-1 2.7-.7 25.4.4 27.5.9 1.7 2.2 1.9 10.3 1.9 7.4 0 9.8.4 12.4 2 5.7 3.4 6.3 5.9 6.3 25.3v17.4L108 115c-6.4 13-10 21.4-10 23.5 0 2.1 3.6 10.5 10 23.5l10 20.3v16.9c0 20-.7 22.5-7.4 25.8-3.3 1.5-6.2 2-12.8 2-6.9 0-8.7.3-9.2 1.6-1.2 3.1-.7 25.9.7 27.8 1.1 1.6 3.3 1.7 23.9 1.4l22.6-.3 4.3-3c2.3-1.7 5.2-4.8 6.3-7 2-3.8 2.1-5.5 2.1-38v-34l-8.2-16.4c-6.4-12.5-8.3-17.3-8.3-20.5 0-3.1 1.9-8.1 8.2-21.1l8.3-16.9.3-31.7c.4-34.9 0-37.8-5.1-43.6-5.2-5.9-7-6.3-32.1-6.3-19.5 0-22.5.2-23 1.6z"
            />
          </g>
<!--        </svg>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading'
}
</script>

<style lang="scss" scoped>
.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 9999;

  &__wrapper {
    position: relative;
    width: 160px;
    height: 160px;
  }
}
.logo {
  width: auto;
  height: 60px;
  animation: pulse 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.lds-ring {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;

  width: 160px;
  height: 160px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 8px solid $red;
  border-radius: 50%;
  animation: lds_ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $black transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds_ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}
</style>
