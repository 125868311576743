module.exports = {
  VUE_APP_ENC: 'U2FsdGVkX19aKDjOqojtcYuNkr0dwJ4J5QF339CIOLI=',
  VUE_APP_API_HOST: 'U2FsdGVkX19svvHpOHv/qYJZH9nHVfg9MP1rYZTR9y2MIVRq5WBcN5beT8xZAih0De3CyHMk6PZtPnSfAxG8wQ==',
  VUE_APP_GOOGLE_MAP_API_KEY: 'U2FsdGVkX19JmHTYpVld1pJZgyuTmFQu0CWts/RVPFMamhRN9wPDk/kp9iAxzfnj1DyoV0QNyc5cZJAGJFprcA==',
  VUE_APP_GEOIP_API_KEY: 'U2FsdGVkX1/IXXE9OHan3bkB+5bFwkKqqoY/c5QGzDQ=',
  VUE_APP_S3D_CLOUDFRONT_ID: 'U2FsdGVkX1/HerZAY+LeiYKRZqIpkUFqFGxuAeytbQk=',
  VUE_APP_S3D_BUCKET: 'U2FsdGVkX19oP7gzuybub5uN2hKEGJCpo5tIcPdCfd4e8ffqCqZTa/ky9e0V8HID',
  VUE_APP_SENDGRID_API_KEY: 'U2FsdGVkX19M5xpzNkffAUR5UhKNhAL37eXstFfQu4Gcs+Zsh3f0bh521rlPDCQMd8xRkBnx1lXKUbsWyeR3Rp2Nt6Vwg8TnBMypsyWdA3nMcIvOHAqUNafPJpiZccOg',
  VUE_APP_MAILCHIMP_API_KEY: 'U2FsdGVkX1+8yhw3nRk8YARj3qIuRYMlpw1xd/7VGghSUjiYGwgqRpLZfe6SfX4oHdoZAADxRUnPUjajMmGa4Q==',
  VUE_APP_GRAPHQL_AUTH: 'U2FsdGVkX19qdy3VvM2D/NZZ8QNkoYSsCydk+IjLIJEqgXTtpofAACS7mUcg4oSS'
}
