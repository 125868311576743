<template>
  <div
    class="col-lg single-card"
    :class="[
      type ? type : '',
      theme ? theme : '',
      colType === '4cols' && type.includes('__image-only') ? 'col-lg-2 no-borders' : '',
      iconCard == null && imageCard == null ? '__squarred' : '',
      iconCard ? 'icon-card' : '',
      isPromotion ? 'isPromotion' : '',
      noBorder ? '' : '__has-border-full',
      textAlignClass,
    ]"
  >
    <!-- Pour les catégories avec surface entierement clickable -->
    <div
        class="wrapper category-tile"
        :class="titleCard ? '' : '__logo-only'"
        v-if="isCategoryTile && !isPromotion"
    >
      <b-link v-if="btnCta"
        :to="btnCta.link"
        :target="btnCta.target ? btnCta.target : '_self'"
        class="__link-wrapper"
      >
        <figure v-if="imageCard">
          <img loading="lazy" :src="imageCard" alt="" />
        </figure>

        <div class="desc" v-if="titleCard || contentCard">
          <h3 v-if="titleCard"
              :class="[
                type.includes('__panel-inverted') ? 'white' : '',
                biggerTitle ? 'biggerTitle' : ''
                ]
              "
              v-html="titleCard">
          </h3>
          <p v-if="contentCard" v-html="contentCard"></p>
        </div>

      </b-link>
    </div>

    <!-- Pour les tiles avec un bkg video -->
    <div class="wrapper" v-else-if="videoId" >
        <IframeVideo
          :videoId='videoId'
        />
    </div>

    <!-- Pour les cards plus generales -->
    <div
      class="wrapper"
      :class="isPromotion ? 'isPromotion' : '' "
      v-else
    >
      <div v-if="iconCard" v-html="iconCard" class="icon icon__wrapper icon__lg"></div>
      <div class="content">
        <div v-if="imageCard" class="figure-wrapper">
          <div class="figure-inner">
            <figure>
              <img class="o-object-fit" loading="lazy" :src="imageCard" alt=""/>
            </figure>
          </div>
        </div>
        <div v-if="!type.includes('__image-only')" class="desc">
          <p v-if="date" class="date" v-html="date"></p>
          <p class="sup_title" v-if="supTitle">{{ supTitle }}</p>
          <h3 v-if="titleCard"
              :class="[
                type.includes('__panel-inverted') ? 'white' : '',
                biggerTitle ? 'biggerTitle' : ''
              ]"
              v-html="titleCard">
          </h3>
          <h4 class="sub-title" v-if="subTitleCard">
            {{ subTitleCard }}
          </h4>
          <p v-if="contentCard" v-html="contentCard"></p>
        </div>
      </div>

      <div v-if="btnCta && btnCta.is_modal" class="single-card__footer">
        <!-- on test ici si c'est pas un CTA pour Modal -->
        <b-link
          class="btn"
          :class="theme != 'black' ? 'btn__inverted' : 'btn__white' "
          @click="openPdfModal()"
        >{{ btnCta.title }}</b-link>
      </div>
      <!-- CTA avec la method open modal newsletter de bootstrap -->
      <div v-if="btnCta && btnCta !== null && !btnCta.is_modal" class="single-card__footer">
        <b-link
          v-if="btnCta && isExternal(btnCta.link)"
          :href="btnCta.link"
          class="btn"
          :class="theme != 'black' ? 'btn__inverted' : 'btn__white' "
          :target="btnCta.target ? btnCta.target : '_self'"
        >{{ btnCta.title }}</b-link>
        <b-link
          v-if="btnCta && !isExternal(btnCta.link)"
          :to="btnCta.link"
          class="btn"
          :class="theme != 'black' ? 'btn__inverted' : 'btn__white' "
          :target="btnCta.target ? btnCta.target : '_self'"
        >{{ btnCta.title }}</b-link>
      </div>
    </div>
    <ModalPdf :random-id="randomIndex" :pdfSrc="btnCta.link" />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import ModalPdf from './ModalPdf.vue'
import IframeVideo from './IframeVideo.vue'
import linkMixing from '../../mixins/link'
import Builder from '../../builder'

export default {
  name: 'Cards',
  mixins: [linkMixing],
  components: {
    IframeVideo,
    ModalPdf
  },
  data () {
    return {
      randomIndex: Math.floor(Math.random() * 100),
      btnCta: false
    }
  },
  props: {
    theme: {
      default: ''
    },
    type: {
      default: 'classic'
    },
    colType: {
      type: String,
      default: null
    },
    supTitle: {
      type: String,
      default: null
    },
    textAlignment: {
      type: String,
      default: 'start'
    },
    date: {
      type: String,
      default: null
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    videoId: {
      type: String,
      default: null
    },
    subTitleCard: {
      type: String,
      default: null
    },
    iconCard: {
      default: null
    },
    imageCard: {
      type: String,
      default: null
    },
    titleCard: {
      type: String,
      default: null
    },
    biggerTitle: {
      type: Boolean,
      default: false
    },
    hasBorder: {
      type: [Boolean, String],
      default: true
    },
    contentCard: {
      type: String,
      default: null
    },
    ctaCard: {
      type: Object,
      default: null
    },
    hasFullBorder: {
      type: Boolean,
      default: false
    },
    uKey: {
      type: [Number, String],
      default: null
    },
    isCategoryTile: {
      type: Boolean,
      default: false
    },
    isPromotion: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * Add text alignment class
     * @returns {String}
     */
    textAlignClass () {
      let tclass = ''

      if (this.textAlignment === 'center') { tclass = 'text-center' }
      if (this.textAlignment === 'end') { tclass = 'text-right' }

      return tclass
    }
  },
  methods: {
    /**
     * Open the modal
     * @returns {Void}
     */
    openModal () {
      this.$bvModal.show('b2b-modal-newsletter')
    },
    /**
     * Open the modal pdf modal with the random index number
     */
    openPdfModal () {
      this.$bvModal.show(`b2b-modal-pdfviewer-${this.randomIndex}`)
    },
    checkCta () {
      const localLang = (this.$i18n.locale) ? this.$i18n.locale : 'en-CA'
      if (this.ctaCard) {
      // if we have the entity id on the button, load he button
        if (this.ctaCard.entity_id && this.ctaCard.entity_id.replace(/ /g, '') !== '') {
        // graphQL request for get the button informations
          const query = gql`
          query {
            block_button: blockbutton(locale: "${localLang}",entity_id:"${this.ctaCard.entity_id}") {
              entity_id
              block_name
              button_text
              button_link
              button_target
              internal_link
              link_to_file
              is_modal
            }
          }
        `

          this.$apollo.query({ query }).then((resp) => {
            const button = resp.data.block_button
            // if we have an internal link
            if (button.internal_link) {
            // and is a string
              if (typeof button.internal_link === 'string') {
              // parse the json
                button.internal_link = this.$JSON5.parse(button.internal_link)
              }
            }
            // if we have an internal link
            if (button.link_to_file) {
            // and is a string
              if (typeof button.link_to_file === 'string') {
              // parse the json
                button.link_to_file = this.$JSON5.parse(button.link_to_file)
              }
            }

            // if we don't use the modal
            if (button.is_modal === null) {
              button.is_modal = false
            }
            // format the button on array
            const formatBuilder = [button]
            // Build the cta button
            // eslint-disable-next-line max-len
            this.btnCta = Builder.Common.cta.buildCta({ block_button: formatBuilder }, localLang)
          })
        } else {
        // use the button
          this.btnCta = this.ctaCard
        }
      } else {
        // no cta button put empty string to return false and avoiding warning during the renders
        this.btnCta = ''
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.checkCta()
    })
  }
}
</script>

<style lang="scss" >
.single-card {
  &.black {
    color: $white;
    background-color: $black;
    a {
      color: $white !important;
    }
    .btn__inverted{
      color: $white !important;
    }

  }
  &.red{
    color: $white;
    background-color: $red;

    ::v-deep .icon  {
      opacity: 0.9 !important;
      svg path {
        fill: $white !important;
      }
    }
    a {
      color: $white !important;
    }

  }
  &.grey{
    color: $black;
    background-color: $grey-light;
    a {
      color: $black !important;
    }
    .btn__inverted{
      color: $white !important;
    }

  }
  &.white{
    color: $black;
    background-color: $white;
    a {
      color: $black !important;
    }
    .btn__inverted{
      color: $white !important;
    }

  }
  .biggerTitle {
    font-size: 3rem;
  }
}
</style>

<style lang="scss" scoped>


.sup_title,.date {
  text-transform: uppercase;
  font-size: 1.1rem;
}
.single-card {
  &.black {
    color: $white !important;
    background-color: $black;
    a {
      color: $white !important;
    }
  }

  &.col-1{
    max-width:95%;
    margin: 0 auto;
    border:none !important;

  }
  &.isPromotion{
    @media only screen and (max-width:1025px) {
      flex: 0 1 100%;
      max-width: 100%;
    }
  }

  .icon {
    min-height: 2.5rem;
    @include breakpoint("md") {
      min-height: 7.5rem;
    }
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity:0.25;

    svg {
      width: 5rem !important;
    }
  }

  .figure-wrapper {
    margin: 3.5rem 3.5rem 0;

    @include breakpoint("md") {
      margin: 4rem 4rem 0;
    }
    @include breakpoint("lg") {
      margin: 4rem 4rem 0;
    }
  }

  figure {
    display: block;
    max-width: 100%;
    height: 0;
    padding-top: 50%;
    position: relative;

    img {
      display: block;
      position: absolute;
      max-width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-bottom: 4rem;

    @include breakpoint("lg") {
      padding-bottom: 8rem;
    }

    .content {
      flex: 1 1 auto;
    }
    .desc {
      padding: 4rem 3.5rem 0 3.5rem;
      word-break: break-word;

      @include breakpoint("lg") {
        padding: 4rem 0 0 0;
        width: 66.66%;
        margin: 0 auto;
      }

      h2 {
        margin-bottom: 2rem;
      }
      p {
        margin-bottom: 1.5rem;
        color: currentColor;

        p {
          color: currentColor;
        }
      }
    }

    &.isPromotion{
      padding-bottom: 4rem;

      @include breakpoint("lg") {
        padding-bottom: 4rem;
      }
      .desc {
        padding: 4rem 3.5rem 0 3.5rem;

        h2{
          font-size: 2.4rem;
        }

        @include breakpoint("lg") {
          padding: 4rem 0rem 0 0rem !important;
        }
      }

      .single-card__footer{
        padding: 0 4rem;
      }
    }
  }
  /*
  * Ajustement des padding du wrapper pour
  *le nouveau block 4 cards autoparts/services
  */
  &.p4r {
    .wrapper {
      padding-top: 3.5rem;
      padding-bottom: 3.5rem;
      .content .desc {
        padding-top: 0;
      }
    }
  }

  &__footer {
    padding: 0 3.5rem;
    margin-top: 1.5rem;
    @include breakpoint('lg') {
      padding: 0 0rem;
      width: 66.66%;
      margin: 1.5rem auto 0;
    }
  }

  &.__centered {
    text-align: center;

    .wrapper {
      padding-top: 4rem;
      @include breakpoint("lg") {
        padding-top: 5rem;
      }
    }

    .desc {
      padding: 2rem 3.5rem 0 3.5rem;

      @include breakpoint("lg") {
        padding: 2rem 0rem 0 0rem;
      }

      @include breakpoint(xl) {
        padding: 2rem 0rem 0 0rem;
      }
      p {
        margin-bottom: 1.5rem;
      }
    }
  }
  &.icon-card{
    .wrapper{
      padding-top: 4rem;
      @include breakpoint(lg) {
        padding-bottom: 8rem;
        padding-top: 6.5rem;
      }
    }
  }
  &.__has-border-full {
    & > .wrapper {
       border-bottom: 1px solid $grey-light;
      @include breakpoint(md) {
        border-right: 1px solid $grey-light;
      }
    }

  }
  &.no-borders{
    & > .wrapper {
       border: none;
    }
  }
  &.__squarred {
    .wrapper {
      .desc {
        padding-top: 4rem;
        @include breakpoint(lg) {
          padding-top: 8rem;
        }
      }
    }
  }
  &.__squarred-with-border {
    .wrapper {
      .content {
        border: 1px solid $grey-light !important;
      }
      .desc {
        padding-top: 4rem;
      }
    }
  }
  &.__image-only {
    .wrapper {
      padding-bottom: 0;

      .content {
        display: flex;
      }
    }

    .figure-wrapper {
      margin: 0;
      display: flex;
      flex: 1 1 auto;
    }

    .figure-inner {
      margin: 0;
      display: flex;
      flex: 1 1 auto;
    }

    figure {
      padding: 0;
      margin: 0;
      width: 100%;
      height: auto;
      max-height: 75vh; //hack ie11 object-fit img
      min-height: 22vh;

      @include breakpoint(lg) {
        min-height: 35vh;
      }

      img{
        display: flex;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: none;
      }
    }
  }
  &.__panel-inverted {
    background-color: $black;
    .desc {
      h2,
      p {
        color: $white;
      }
    }
  }
  &.__icon-top-right {
    .wrapper{
      padding-top: 0;
      padding-bottom: 8rem;
    }
    .icon {
      position: absolute;
      top: 1.5rem;
      right: 1rem;
      width: 2.5rem;
      height: 2.5rem;
      min-height: inherit;
      padding-top: 0;
      @include breakpoint(md) {
        height: 3.5rem;
        width: 3.5rem;
        top: 3.5rem;
        right: 3.5rem;
      }
    }
  }
  &.__padding-b:not(.pb){
    .wrapper{
      padding-bottom: 0;
    }
  }
  &.col-5ths{
    min-height: 50vw;
    @include breakpoint(md) {
      min-height: 50vw;
    }
    @include breakpoint(lg) {
      min-height: 20vw;
    }
  }
  .category-tile{
    padding: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;

    border-right: none !important;
    border-bottom: 1px solid $grey-light;
    border-left: 1px solid $grey-light;
    &:nth-of-type(odd) {
      @include breakpoint(lg) {
        border-right: 1px solid  $grey-light !important;
      }
   }
      @include breakpoint(lg) {
        border-left: none;
     }


    .__link-wrapper{
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      text-align: center;
      text-decoration: none;
      align-items: center;
    }

    .desc{
      width: 100%;
      padding: 0 2rem 1.5rem;

      @include breakpoint(lg) {
        padding: 0 4rem 1.5rem;
      }

      h2{
        font-size: 1.8rem;
      }
    }

    figure{
      margin: 1.5rem 0 2rem 0;
      width: 60%;
      height: 30vw;
      display: flex;
      align-items: flex-end;
      text-align: center;
      overflow: hidden;

      @include breakpoint(md) {
        height: 30vw;
      }

      @include breakpoint(lg) {
        height: 10vw;
      }


      img{
        width: 100%;
        height: auto;
        max-width: 300px;
        margin: 0 auto;
      }

    }

    &.__logo-only{
      a {
        justify-content: center;
        figure{
          align-items: center;
        }
      }
    }

    .single-card__footer{
      padding: 0;
    }
  }
}
</style>
