export default {
  state: {
    shops: [],
    stores: [],
    services: [],
    selectedShop: null,
    locationAddress: null
  },

  mutations: {
    SET_SHOPS: (state, { shops }) => {
      state.shops = shops
    },
    SET_STORES: (state, { stores }) => {
      state.stores = stores
    },
    SET_SERVICES: (state, { services }) => {
      state.services = services
    },
    SET_SELECTED_SHOP: (state, { shopId }) => {
      state.selectedShop = state.shops.find(item => item.id === shopId)
    },
    SET_LOCATION_ADDRESS: (state, { locationAddress }) => {
      state.locationAddress = locationAddress
    }
  },

  getters: {
    getShops (state) {
      return state.shops
    },
    getStores (state) {
      return state.stores
    },
    getServices (state) {
      return state.services
    },
    getLocationAddress (state) {
      return state.locationAddress
    },
    getShopById: state => shopId => state.shops.find(item => item.id === parseInt(shopId, 10)),
    getStoreById: state => storeId => state.stores.find(item => item.id === parseInt(storeId, 10)),
    getShopBySlug: state => slug => state.shops.find(item => item.slug === slug),
    getStoreBySlug: state => slug => state.stores.find(item => item.slug === slug),
    getSelectedShop (state) {
      return state.selectedShop
    }
  },

  actions: {
    setShops ({ commit }, { shops }) {
      commit('SET_SHOPS', { shops })
    },
    setStores ({ commit }, { stores }) {
      commit('SET_STORES', { stores })
    },
    setServices ({ commit }, { services }) {
      commit('SET_SERVICES', { services })
    },
    setSelectedShop ({ commit }, { shopId }) {
      commit('SET_SELECTED_SHOP', { shopId })
    },
    setLocationAddress ({ commit }, { locationAddress }) {
      commit('SET_LOCATION_ADDRESS', { locationAddress })
    }
  }
}

