var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg single-card-clickable __centered",class:[
    _vm.type ? _vm.type : '',
    _vm.theme ? _vm.theme : '',
    _vm.colType === '4cols' && _vm.type.includes('__image-only')
      ? 'col-lg-2 no-borders'
      : '',
    _vm.iconCard == null && _vm.imageCard == null ? '__squarred' : '',
    _vm.iconCard ? 'icon-card' : '',
    _vm.isPromotion ? 'isPromotion' : '',
    _vm.noBorder ? '' : '__has-border-full',
    _vm.textAlignClass ]},[_c('div',{staticClass:"wrapper"},[(_vm.btnCta && _vm.isExternal(_vm.btnCta.link))?_c('b-link',{staticClass:"h-100",attrs:{"href":_vm.btnCta.link,"target":_vm.btnCta.target ? _vm.btnCta.target : '_self'}},[_c('card-content',{staticClass:"h-100",attrs:{"imageCard":_vm.imageCard,"titleCard":_vm.titleCard,"ctaCard":_vm.ctaCard,"type":_vm.type,"theme":_vm.theme,"textAlignment":"center"}})],1):_vm._e(),(_vm.btnCta && !_vm.isExternal(_vm.btnCta.link))?_c('b-link',{staticClass:"h-100",attrs:{"to":_vm.btnCta.link,"target":_vm.btnCta.target ? _vm.btnCta.target : '_self'}},[_c('card-content',{staticClass:"h-100",attrs:{"imageCard":_vm.imageCard,"titleCard":_vm.titleCard,"ctaCard":_vm.ctaCard,"type":_vm.type,"theme":_vm.theme,"textAlignment":"center"}})],1):_c('card-content',{attrs:{"imageCard":_vm.imageCard,"titleCard":_vm.titleCard,"ctaCard":_vm.ctaCard,"type":_vm.type,"theme":_vm.theme,"textAlignment":"center"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }