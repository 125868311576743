import uuidv4 from '../../utils/uuidv4'
import Common from './common'

export default {
  /**
   * Builder for promotion
   * @param {Object} data
   * @param {Sring} lang
   * @returns {Object}
   */
  build (data, lang) {
    const props = []

    let addRow = false
    let firstRow = {}
    // if we have a title or sub title
    if (data.title || data.sub_title) {
      addRow = true
      firstRow = {
        title: (data.title) ? data.title : null,
        subTitleCard: (data.sub_title) ? data.sub_title : null
      }
    }
    // if we have promotions
    if (data.promotions) {
      data.promotions.forEach((promotion) => {
        props.push({
          title: Common.Title.buildTitle(promotion.title),
          content: Common.Content.buildContent(promotion.content),
          imageCard: Common.Image.buildImage(promotion.image),
          hasFullBorder: (promotion.hasFullBorder) ? promotion.hasFullBorder : false,
          hasBorder: (promotion.hasBorder) ? promotion.hasBorder : false,
          noBorder: (promotion.noBorder) || (data.type) ? promotion.noBorder : false,
          cta: Common.Cta.buildCta(promotion, lang)
        })
      })
    }

    return {
      id: `${data.entity_id}_${uuidv4()}`,
      component: 'SectionCards',
      props: {
        addRow,
        firstRow,
        elements: props,
        borderDisplay: data.border_display === 'true',
        bottomSpace: data.bottom_space === 'true'
      }
    }
  }
}
